import {get, post} from "../../util/request";

export interface TerminalIdRequest {
  terminalId: string
}

export interface ISaveEccRequest {
  terminalId: string;
  ecc: ET.IEcc;
}

export const getEcc = (params: TerminalIdRequest) => {
  return get<ISaveEccRequest>({url: "/api/terminal-prm/info/et/ecc", params})
}

export const saveEcc = (data: ISaveEccRequest) => {
  return post({url: "/api/terminal-prm/info/et/ecc", data})
}