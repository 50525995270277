import React, {FC, useEffect, useState} from "react";
import {Button, DatePicker, Form, Input, notification, Radio, Row} from "antd";
import {useTranslation} from "react-i18next";
import {ITerminalPRMRInfoBasic, saveTerminalPrmInfoBasic, terminalPrmInfoBasic} from "../../services/terminalPrm";
import moment from "moment";

interface IProps {
  terminalId: string;
}

export const TerminalPrmBasic: FC<IProps> = ({terminalId}) => {
  const {t} = useTranslation()
  const [form] = Form.useForm()
  const initialFromValues: ITerminalPRMRInfoBasic = {
    downloadTime: "",
    forceDownload: 0,
    name: "",
    packageName: "",
    terminalId: terminalId
  }
  const [formValues, setFormValues] = useState<ITerminalPRMRInfoBasic>(initialFromValues);
  const [btnLoading, setBtnLoading] = useState<boolean>(false)
  useEffect(function () {
    if (terminalId !== "") {
      terminalPrmInfoBasic({terminal_id: terminalId}).then(res => {
        if (res.status === 0) {
          const baseForm = res.data
          baseForm['cDownloadTime'] = moment(res.data.downloadTime, "YYYY-MM-DD HH:mm:ss")
          setFormValues(baseForm)
          form.setFieldsValue(baseForm)
        }
      })
    }
  }, [terminalId])
  const changeDatepicker = (v) => {
    let info = formValues
    if (info) {
      info.downloadTime = v.format("YYYY-MM-DD HH:mm:ss")
      setFormValues(info)
    }
  }
  const clickOkDatepicker = (v) => {
    let info = formValues
    if (info) {
      info.downloadTime = v.format("YYYY-MM-DD HH:mm:ss")
      setFormValues(info)
    }
  }
  const saveForm = (values) => {
    setBtnLoading(true)
    formValues.forceDownload = values['forceDownload']
    saveTerminalPrmInfoBasic(formValues).then(res => {
      if (res.status === 0) {
        notification.success({
          message: "save success"
        })
      }
    }).finally(() => {
      setBtnLoading(false)
    })
  }
  return (
    <Row gutter={10} style={{marginTop: "20px"}} hidden={terminalId === ""}>
      <div className='form-content-container-content'>
        <div>
          <Form layout='inline' form={form} initialValues={formValues} onFinish={saveForm}>
            <Form.Item name="name" label={t('form.name')}>
              <Input disabled/>
            </Form.Item>
            <Form.Item name="packageName" label={t('form.package_name')}>
              <Input disabled/>
            </Form.Item>
            <Form.Item name='cDownloadTime' label={t('form.download_time')}>
              <DatePicker showTime
                          format='YYYY-MM-DD HH:mm:ss'
                          onChange={changeDatepicker}
                          onOk={clickOkDatepicker}/>
            </Form.Item>
            <Form.Item label={t('form.force_download')} name="forceDownload">
              <Radio.Group>
                <Radio value={1}>True</Radio>
                <Radio value={0}>False</Radio>
              </Radio.Group>
            </Form.Item>
          </Form>
          <div style={{marginTop: "20px"}}>
            <Button type="primary" shape="round" style={{width: "200px", float: "right"}}
                    htmlType="submit" loading={btnLoading} onClick={() => form.submit()}>
              {t('button.save')}
            </Button>
          </div>
        </div>
      </div>
    </Row>
  )
}