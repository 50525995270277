import {del, get, post} from "../../util/request";

export interface IApplicationItem {
  name: string
  aid: string
  asi: string
}

export interface IAppConfigItem {
  name: string
  tag: string
  attribute: string
  text: string
}

export interface IApplicationInfo {
  name: string
  aid: string
  asi: string
  items: Array<IAppConfigItem>
}

export interface ITerminalId {
  terminalId: string
}

export interface ITerminalIdAid {
  terminalId: string
  aid: string
}

export interface IStoreApplication {
  terminalId: string
  name: string
  aid: string
  asi: string
  items: Array<IAppConfigItem>
}

export const appList = (params: ITerminalId) => {
  return get<Array<IApplicationItem>>({url: "/api/terminal-prm/info/cp/applications", params})
}

export const appInfo = (params: ITerminalIdAid) => {
  return get<IApplicationInfo>({url: "/api/terminal-prm/info/cp/applications/info", params})
}

export const storeApp = (data: IStoreApplication) => {
  return post({url: "/api/terminal-prm/info/cp/applications", data})
}

export const removeApp = (params: ITerminalIdAid) => {
  return del({url: "/api/terminal-prm/info/cp/applications", params})
}