import {notification} from 'antd';
import {FrownOutlined} from '@ant-design/icons';
import axios, {AxiosResponse, AxiosRequestConfig} from 'axios';
import {getTenantId, getToken, saveToken} from "../cookie";

interface MyResponse<T> {
    data: T,
    status: number,
    msg: string
}

const instance = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
    timeout: 30000
})

instance.interceptors.request.use((request: AxiosRequestConfig) => {
    if (request.headers === undefined) {
        request.headers = {}
    }
    const token = getToken()
    if (token !== "") {
        request.headers['Authorization'] = token
    }
    request.headers['c-tenant-id']=getTenantId()
    return request
})

instance.interceptors.response.use(
    (response: AxiosResponse<MyResponse<any>>) => {
        const contentType:string = response.headers['content-type']
        if (contentType.includes('application/json')){
            if (response.data.status === 100401) {
                window.location.href = "/auth/login"
            } else if (response.data.status !== 0) {
                notification.error({
                    message: 'Error',
                    description: response.data.msg,
                    icon: <FrownOutlined style={{color: '#dc3545'}}/>,
                })
            }
        }
        const authorization = response.headers.authorization || ""
        if (authorization !== "") {
            saveToken(authorization)
        }
        return response
    }, (error) => {
        const response = error.response;
        notification.open({
            message: 'HTTP Error',
            description: error.message,
            icon: <FrownOutlined style={{color: '#dc3545'}}/>,
        });
        return Promise.reject(response || {message: error.message});
    })


const request = <T extends any = any>(config: AxiosRequestConfig): Promise<T> => new Promise((resolve, reject) => {
    instance
        .request<any, AxiosResponse<T>>(config)
        .then((res) => {
            resolve(res.data);
        })
        .catch((err) => {
            reject(err);
        });
});

export const get = <T extends any = any>(config: AxiosRequestConfig): Promise<MyResponse<T>> => request({
    ...config,
    method: 'GET'
});

export const post = <T extends any = any>(config: AxiosRequestConfig): Promise<MyResponse<T>> => request({
    ...config,
    method: 'POST'
});

export const del = <T extends any = any>(config: AxiosRequestConfig): Promise<MyResponse<T>> => request({
    ...config,
    method: 'DELETE'
});

export const patch = <T extends any = any>(config: AxiosRequestConfig): Promise<MyResponse<T>> => request({
    ...config,
    method: 'PATCH'
});
