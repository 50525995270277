import {Layout, Menu, Row, Col, Avatar, Dropdown, Button, Select} from 'antd';
import {GlobalOutlined, ApartmentOutlined} from '@ant-design/icons';
import * as React from 'react';
import {FC, ReactElement, useEffect, useState} from "react";
import './layout.css'
import {Link, useNavigate, useLocation, Routes, Route} from "react-router-dom";
import {getTenantId, getToken, loginUser, logout, saveTenantId} from "../../util/cookie";
import i18n from "../../util/i18n";
import {useTranslation} from "react-i18next";
import {Home} from "../../pages/home";
import {TerminalPrm} from "../../pages/terminalPrm";
import {MerchantPrm} from "../../pages/merchantPrm";
import {SystemSetting} from '../../pages/systemSetting';
import {ITenantSimpleListResponse, tenantSimpleList} from "../../services/tenant/tenant";
import {UpdateNewDefaultPrm} from "../../pages/updateNewDefaultPrm";
// import {Error404} from "../../pages/error404";

const {Header, Content} = Layout;


export const BaseLayout: FC = ({children}) => {
  const navigate = useNavigate()
  const location = useLocation()
  const [selectKeys, setSelectKeys] = useState([""])
  const userInfo = loginUser()
  const [language, setLanguage] = useState("enUS")
  const [tenantList, setTenantList] = useState<ITenantSimpleListResponse[]>([])
  const [tenantId, setTenantId] = useState("")
  const [currentTenant, setCurrentTenant] = useState<ITenantSimpleListResponse>({tenant_id: "", name: ""})
  const languages = {
    "enUS": "English",
    "zhTW": "中文繁體"
  }
  const {t} = useTranslation()
  const changeLang = (value: string) => {
    i18n.changeLanguage(value).then()
    setLanguage(value)
    localStorage.setItem("Lang", value)
  }
  const languageMenu = (
    <Menu>
      <Menu.Item key="enUS" hidden={language === "enUS"}>
        <Button type="text" onClick={() => changeLang("enUS")}>English</Button>
      </Menu.Item>
      <Menu.Item key="zhTW" hidden={language === "zhTW"}>
        <Button type="text" onClick={() => changeLang("zhTW")}>中文繁體</Button>
      </Menu.Item>
    </Menu>
  );

  const changeTenant = (val: string) => {
    tenantList.forEach((item) => {
      if (item.tenant_id === val) {
        setCurrentTenant(item)
        setTenantId(item.tenant_id)
        saveTenantId(item.tenant_id)
        return
      }
    })
    window.location.reload()
  }

  const tenantMenu = () => {
    return (
      <Menu>
        {
          tenantList.map((item, index) => {
            return (
              <Menu.Item key={item.tenant_id} hidden={tenantId === item.tenant_id} style={{textAlign:"center"}}>
                <Button type="text" onClick={() => changeTenant(item.tenant_id)}>{item.name}</Button>
              </Menu.Item>
            )
          })
        }
      </Menu>
    )
  }
  const userMenu = (
    <Menu>
      <Menu.Item key="logout">
        <Button type="text" onClick={() => {
          logout()
          navigate('/auth/login')
        }}>{t('button.logout')}</Button>
      </Menu.Item>
    </Menu>
  );
  useEffect(function () {
    const selectedLang = localStorage.getItem("Lang") || "enUS"
    if (getToken() === "") {
      navigate('/auth/login')
      return
    }
    let pathName = location.pathname
    const arr = pathName.split("/")
    setSelectKeys(['/' + arr[1]])
    changeLang(selectedLang)
  }, [location.pathname, navigate])

  useEffect(function () {
    tenantSimpleList().then(res => {
      if (res.status === 0) {
        setTenantList(res.data)
        let currentTenantId = getTenantId()
        if (currentTenantId !== "") {
          res.data.forEach(item => {
            if (item.tenant_id === currentTenantId) {
              setCurrentTenant(item)
            }
          })
          setTenantId(currentTenantId)
          saveTenantId(currentTenantId)
        } else {
          setCurrentTenant(res.data[0])
          setTenantId(res.data[0].tenant_id)
          saveTenantId(res.data[0].tenant_id)
        }

      }
    })
  }, [])
  return (
    <Layout className="layout">
      <Header className="header-box">
        <Row>
          <Col flex="400px">
            <h2 className="logo">Global Payments App Manager</h2>
          </Col>
          <Col flex="auto">
            <Menu
              theme="dark"
              mode="horizontal"
              selectedKeys={selectKeys}
              className='menu-box'
            >
              <Menu.Item key="/">
                <Link to='/' className='primary-text-bold'>{t('title.home')}</Link>
              </Menu.Item>
              <Menu.Item key="/merchant-prm">
                <Link to='/merchant-prm' className='primary-text-bold'>{t('title.merchant_prm')}</Link>
              </Menu.Item>
              <Menu.Item key="/terminal-prm">
                <Link to='/terminal-prm' className='primary-text-bold'>{t('title.terminal_prm')}</Link>
              </Menu.Item>
              <Menu.Item key="/system-settings">
                <Link to='/system-settings' className='primary-text-bold'>{t('title.system_setting')}</Link>
              </Menu.Item>
              <Menu.Item key="/upgrades">
                <Link to='/upgrades' className='primary-text-bold'>{t('title.upgrades')}</Link>
              </Menu.Item>
            </Menu>
          </Col>
          <Col flex="auto">
            <div style={{float: "right"}}>
              <Dropdown overlay={tenantMenu} placement="bottom" arrow={{pointAtCenter: true}}>
                <Button style={{border: "2px solid #dc3545"}} type='text' icon={<ApartmentOutlined/>}
                        className='primary-text-bold'>{currentTenant.name}</Button>
              </Dropdown>
              <Dropdown overlay={languageMenu} placement="bottom" arrow={{pointAtCenter: true}}>
                <Button type='text' icon={<GlobalOutlined/>}
                        className='primary-text-bold'>{languages[language]}</Button>
              </Dropdown>
              <Dropdown overlay={userMenu} placement="bottom" arrow={{pointAtCenter: true}}>
                                <span style={{cursor: "pointer"}}>
                                    {userInfo.avatar && <Avatar src={userInfo.avatar}/>}
                                  {/*<Tooltip placement="left" title={userInfo.full_name}>*/}
                                  <span style={{marginLeft: "8px"}}
                                        className='primary-text-bold'>{userInfo.full_name}</span>
                                  {/*</Tooltip>*/}
                                </span>
              </Dropdown>
            </div>
          </Col>
        </Row>
      </Header>
      <Content style={{padding: '0 50px'}}>
        <div className="site-layout-content">
          <Routes>
            <Route path='/' element={<Home/>}/>
            <Route path='terminal-prm/*' element={<TerminalPrm/>}/>
            <Route path='merchant-prm/*' element={<MerchantPrm/>}/>
            <Route path='system-settings/*' element={<SystemSetting/>}/>
            <Route path='upgrades/*' element={<UpdateNewDefaultPrm/>}/>
            {/*<Route path='*' element={Navigate({to: '/'})}/>*/}
          </Routes>
        </div>
      </Content>
    </Layout>
  );
};