import {get, post} from "../../util/request";

export interface TerminalIdRequest {
  terminalId: string
}

export interface ISaveIceriniRequest {
  terminalId: string;
  icerini: ET.IIcerini;
}

export const getIcerini = (params: TerminalIdRequest) => {
  return get<ISaveIceriniRequest>({url: "/api/terminal-prm/info/et/icerini", params})
}

export const saveIcerini = (data: ISaveIceriniRequest) => {
  return post({url: "/api/terminal-prm/info/et/icerini", data})
}