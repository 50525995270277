import {get, post} from "../../util/request";
import {
  TerminalPrmInfoRequest
} from "../terminalPrm";

export interface ITerminalPRMRInfoQRCodeGateway {
  id: string;
  terminalId: string;
  qrcode: QRCodeGateway.IQRCode;
}

export interface ITerminalPRMRInfoQRCodeGatewayData {
  id: string;
  terminalId: string;
  data: string;
}

export interface ITerminalPRMRInfoDataResponse {
  url: string;
}

export const terminalPrmInfoQRCodeGateway = (params: TerminalPrmInfoRequest) => {
  return get<ITerminalPRMRInfoQRCodeGateway>({url: "/api/terminal-prm/info/qrcode/gateway", params})
}

export const saveTerminalPrmInfoQRCodeGateway = (data: ITerminalPRMRInfoQRCodeGateway) => {
  return post({url: "/api/terminal-prm/info/qrcode/gateway", data})
}

export const terminalPrmInfoQRCodeGatewayData = (params: TerminalPrmInfoRequest) => {
  return get<ITerminalPRMRInfoQRCodeGatewayData>({url: "/api/terminal-prm/info/qrcode/data", params})
}

export const saveTerminalPrmInfoQRCodeGatewayData = (data: ITerminalPRMRInfoQRCodeGatewayData) => {
  return post<ITerminalPRMRInfoDataResponse>({url: "/api/terminal-prm/info/qrcode/data", data})
}
