import { del, get, patch, post } from "../../util/request";

export interface TerminalIdRequest {
  terminalId: string;
}

export interface IIssuerEnableStatusResponse {
  enable: boolean;
}

export interface IItemsResponse {
  index: string;
  issuerName: string;
  startBinRange: string;
  endBinRange: string;
  minPanLength: number
  maxPanLength: number
}

export interface IItemEnableRequest {
  terminalId: string;
  enable: boolean;
}

export interface IItemRequest {
  terminalId: string;
  index: string;
}

export interface IItemResponse {
  terminalId: string;
  item: SmartyPay.IItem;
}

export interface ICreateItemRequest {
  terminalId: string;
  item: SmartyPay.IItem;
}

export interface IUpdateItemRequest {
  terminalId: string;
  item: SmartyPay.IItem;
  index: string;
  sync: number;
}

export interface IDeleteItemRequest {
  terminalId: string;
  index: string;
}

export const issuerEnableStatus = (params: TerminalIdRequest) => {
  return get<IIssuerEnableStatusResponse>({ url: "/api/terminal-prm/info/cp/smartypay", params });
};

export const enableIssuer = (data: IItemEnableRequest) => {
  return patch({ url: "/api/terminal-prm/info/cp/smartypay", data });
};

export const issuerItems = (params: TerminalIdRequest) => {
  return get<[IItemsResponse]>({ url: "/api/terminal-prm/info/cp/smartypay/issuer-items", params });
};

export const item = (params: IItemRequest) => {
  return get<IItemResponse>({ url: "/api/terminal-prm/info/cp/smartypay/item", params });
};

export const createItem = (data: ICreateItemRequest) => {
  return post({ url: "/api/terminal-prm/info/cp/smartypay/item", data });
};

export const updateItem = (data: IUpdateItemRequest) => {
  return patch({ url: "/api/terminal-prm/info/cp/smartypay/item", data });
};

export const deleteItem = (params: IDeleteItemRequest) => {
  return del({ url: "/api/terminal-prm/info/cp/smartypay/item", params });
};
