import {del, get, patch, post} from "../../util/request";

export interface TerminalIdRequest {
  terminalId: string
}

export interface IContactEnableStatusResponse {
  enable: boolean
}

export interface IContactEnableRequest {
  terminalId: string
  enable: boolean
}

export interface ITxnRequest {
  terminalId: string
  txnType: string
}

export interface IUpdateTxnRequest {
  terminalId: string
  amex: Emv.IContactLessAmex
  txnType: string
}


export const contactEnableStatus = (params: TerminalIdRequest) => {
  return get<IContactEnableStatusResponse>({url: "/api/terminal-prm/info/cp/contactless/amex/status", params})
}

export const enableContact = (data: IContactEnableRequest) => {
  return patch({url: "/api/terminal-prm/info/cp/contactless/amex/status", data})
}

export const txn = (params: ITxnRequest) => {
  return get<Emv.IContactLessAmex>({url: "/api/terminal-prm/info/cp/contactless/amex/txntype", params})
}

export const updateTxn = (data: IUpdateTxnRequest) => {
  return patch({url: "/api/terminal-prm/info/cp/contactless/amex/txntype", data})
}

export interface IIssuerEnableStatusResponse {
  enable: boolean
}

export interface IItemsResponse {
  index: string
  issuerName: string
  startBinRange: string
  endBinRange: string
  minPanLength: number
  maxPanLength: number
}

export interface IItemEnableRequest {
  terminalId: string
  enable: boolean
}

export interface IItemRequest {
  terminalId: string
  index: string
}

export interface IItemResponse {
  terminalId: string
  item: IssuerAmex.IItem
}

export interface ICreateItemRequest {
  terminalId: string
  item: IssuerAmex.IItem
}

export interface IUpdateItemRequest {
  terminalId: string
  item: IssuerAmex.IItem
  index: string
  sync: number
}

export interface IDeleteItemRequest {
  terminalId: string
  index: string
}

export const issuerEnableStatus = (params: TerminalIdRequest) => {
  return get<IIssuerEnableStatusResponse>({url: "/api/terminal-prm/info/cp/amex", params})
}

export const enableIssuer = (data: IItemEnableRequest) => {
  return patch({url: "/api/terminal-prm/info/cp/amex", data})
}

export const issuerItems = (params: TerminalIdRequest) => {
  return get<[IItemsResponse]>({url: "/api/terminal-prm/info/cp/amex/issuer-items", params})
}

export const item = (params: IItemRequest) => {
  return get<IItemResponse>({url: "/api/terminal-prm/info/cp/amex/item", params})
}

export const createItem = (data: ICreateItemRequest) => {
  return post({url: "/api/terminal-prm/info/cp/amex/item", data})
}

export const updateItem = (data: IUpdateItemRequest) => {
  return patch({url: "/api/terminal-prm/info/cp/amex/item", data})
}

export const deleteItem = (params: IDeleteItemRequest) => {
  return del({url: "/api/terminal-prm/info/cp/amex/item", params})
}
