import {get, post} from "../../util/request";
import {
    IExportParameterRequest,
    IExportParameterResponse,
    IExportParameterResultRequest,
    IExportParameterResultResponse
} from "./types";


export const exportParameter = (data: IExportParameterRequest) => {
    return post<IExportParameterResponse>({url: "/api/parameters/export", data})
}

export const exportParameterResult = (params:IExportParameterResultRequest) => {
    return get<IExportParameterResultResponse>({url: "/api/parameters/export",params:params})
}