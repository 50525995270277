import {Cookies} from "react-cookie";
import {LoginResponse} from "../../services/login";

const cookie = new Cookies()
export const saveToken = (token: string) => {
  cookie.set('Token', token, {path: "/"})
}

export const getToken = (): string => {
  return cookie.get("Token") || ""
}

export const saveTenantId = (tenantId: string) => {
  cookie.set('x-tenant-id', tenantId, {path: "/"})
}

export const getTenantId = (): string => {
  return cookie.get("x-tenant-id") || ""
}

export const onLogin = (user: LoginResponse) => {
  cookie.set("UserInfo", user, {path: "/"})
}

export const logout = () => {
  cookie.remove('Token', {path: "/"})
  cookie.remove('UserInfo', {path: "/"})
  cookie.remove('x-tenant-id', {path: "/"})
}

export const loginUser = (): LoginResponse => {
  return cookie.get("UserInfo") || {user_id: "", avatar: "", full_name: ""}
}