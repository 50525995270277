import React, { FC, useEffect, useState } from "react";
import { Button, Checkbox, Col, Divider, Form, Input, Modal, notification, Radio, Row } from "antd";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { deleteItem, IItemResponse, item, updateItem } from "../../../../services/cardpool/amex";

interface IProps {
  terminalId: string;
  index: string;
}

export const TerminalPrmCPAmexItem: FC<IProps> = ({ terminalId, index }) => {
  const { t } = useTranslation();
  const navigator = useNavigate();
  const [form] = Form.useForm();
  const [formValues, setFormValues] = useState<IItemResponse>();
  const [btnLoading, setBtnLoading] = useState<boolean>(false);
  const [syncData, setSyncData] = useState<boolean>(false);
  useEffect(
    function () {
      if (terminalId !== "") {
        item({ terminalId: terminalId, index: index }).then((res) => {
          if (res.status === 0) {
            setFormValues(res.data);
            form.setFieldsValue(res.data);
          }
        });
      }
    },
    [terminalId, index]
  );
  const saveForm = (values) => {
    if (formValues === undefined) {
      return;
    }
    setBtnLoading(true);
    updateItem({
      terminalId: terminalId,
      index: index,
      item: values["item"],
      sync: syncData ? 1 : 0,
    })
      .then((res) => {
        if (res.status === 0) {
          notification.success({
            message: "save success",
          });
        }
      })
      .finally(() => {
        setBtnLoading(false);
      });
  };
  const removeItem = () => {
    Modal.confirm({
      title: "重要警告",
      content: "您的操作將會使數據完全删除，請謹慎確認！",
      onOk: () => postDeleteItem(),
      okText: "Yes",
      maskClosable: false,
      closable: false,
    });
  };

  const postDeleteItem = () => {
    deleteItem({ terminalId: terminalId, index: index }).then((res) => {
      if (res.status === 0) {
        notification.success({
          message: "success",
        });
        navigator("/terminal-prm/acquirer/amex");
      }
    });
  };
  const cloneItem = () => {
    navigator('/terminal-prm/acquirer/amex/create-issuer', {state:formValues})
  }
  return (
    <>
      <Form layout="vertical" form={form} initialValues={formValues} onFinish={saveForm}>
        <Row gutter={30}>
          <Col flex="300px">
            <Form.Item label={t("form.IssuerName")} name={["item", "IssuerName"]}>
              <Input disabled />
            </Form.Item>
          </Col>
          <Col flex="300px">
            <Form.Item label={t("form.AcquirerName")} name={["item", "AcquirerName"]}>
              <Input disabled />
            </Form.Item>
          </Col>
          <Col flex="300px">
            <Form.Item
              label={t("form.CardNumMaskTypeMerchant")}
              name={["item", "CardNumMaskTypeMerchant"]}
              normalize={(value) => Number(value)}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col flex="300px">
            <Form.Item
              label={t("form.CardNumMaskTypeCustomer")}
              name={["item", "CardNumMaskTypeCustomer"]}
              normalize={(value) => Number(value)}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col flex="300px">
            <Form.Item label={t("form.StartBinRange")} name={["item", "StartBinRange"]}>
              <Input disabled />
            </Form.Item>
          </Col>
          <Col flex="300px">
            <Form.Item label={t("form.EndBinRange")} name={["item", "EndBinRange"]}>
              <Input disabled />
            </Form.Item>
          </Col>
          <Col flex="300px">
            <Form.Item
              label={t("form.AdjustPercent")}
              name={["item", "AdjustPercent"]}
              normalize={(value) => Number(value)}
            >
              <Input disabled />
            </Form.Item>
          </Col>
          <Col flex="300px">
            <Form.Item
              label={t("form.TipsPercent")}
              name={["item", "TipsPercent"]}
              normalize={(value) => Number(value)}
            >
              <Input disabled />
            </Form.Item>
          </Col>
          <Col flex="300px">
            <Form.Item
              label={t("form.MinPanLength")}
              name={["item", "MinPanLength"]}
              normalize={(value) => Number(value)}
            >
              <Input disabled />
            </Form.Item>
          </Col>
          <Col flex="300px">
            <Form.Item
              label={t("form.MaxPanLength")}
              name={["item", "MaxPanLength"]}
              normalize={(value) => Number(value)}
            >
              <Input disabled />
            </Form.Item>
          </Col>
          <Col flex="300px">
            <Form.Item label={t("form.AmexSaleFlag")} name={["item", "AmexSaleFlag"]}>
              <Radio.Group>
                <Radio value={true}>True</Radio>
                <Radio value={false}>False</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col flex="300px">
            <Form.Item label={t("form.AmexVoidFlag")} name={["item", "AmexVoidFlag"]}>
              <Radio.Group>
                <Radio value={true}>True</Radio>
                <Radio value={false}>False</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col flex="300px">
            <Form.Item label={t("form.AmexRefundFlag")} name={["item", "AmexRefundFlag"]}>
              <Radio.Group>
                <Radio value={true}>True</Radio>
                <Radio value={false}>False</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col flex="300px">
            <Form.Item label={t("form.AmexSettlementFlag")} name={["item", "AmexSettlementFlag"]}>
              <Radio.Group>
                <Radio value={true}>True</Radio>
                <Radio value={false}>False</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col flex="300px">
            <Form.Item label={t("form.AmexAdjustFlag")} name={["item", "AmexAdjustFlag"]}>
              <Radio.Group>
                <Radio value={true}>True</Radio>
                <Radio value={false}>False</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col flex="300px">
            <Form.Item label={t("form.AmexTipsFlag")} name={["item", "AmexTipsFlag"]}>
              <Radio.Group>
                <Radio value={true}>True</Radio>
                <Radio value={false}>False</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col flex="300px">
            <Form.Item label={t("form.AmexOfflineSaleFlag")} name={["item", "AmexOfflineSaleFlag"]}>
              <Radio.Group>
                <Radio value={true}>True</Radio>
                <Radio value={false}>False</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col flex="300px">
            <Form.Item label={t("form.AmexPreAuthFlag")} name={["item", "AmexPreAuthFlag"]}>
              <Radio.Group>
                <Radio value={true}>True</Radio>
                <Radio value={false}>False</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col flex="300px">
            <Form.Item label={t("form.CallBankFlag")} name={["item", "CallBankFlag"]}>
              <Radio.Group>
                <Radio value={true}>True</Radio>
                <Radio value={false}>False</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col flex="300px">
            <Form.Item label={t("form.ManualKeyinFlag")} name={["item", "ManualKeyinFlag"]}>
              <Radio.Group>
                <Radio value={true}>True</Radio>
                <Radio value={false}>False</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col flex="300px">
            <Form.Item label={t("form.CheckExpiredDateFlag")} name={["item", "CheckExpiredDateFlag"]}>
              <Radio.Group>
                <Radio value={true}>True</Radio>
                <Radio value={false}>False</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col flex="300px">
            <Form.Item label={t("form.ManualExpiryDateFlag")} name={["item", "ManualExpiryDateFlag"]}>
              <Radio.Group>
                <Radio value={true}>True</Radio>
                <Radio value={false}>False</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col flex="300px">
            <Form.Item label={t("form.ManualCheckSumFlag")} name={["item", "ManualCheckSumFlag"]}>
              <Radio.Group>
                <Radio value={true}>True</Radio>
                <Radio value={false}>False</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col flex="300px">
            <Form.Item label={t("form.CheckModule10Flag")} name={["item", "CheckModule10Flag"]}>
              <Radio.Group>
                <Radio value={true}>True</Radio>
                <Radio value={false}>False</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col flex="300px">
            <Form.Item label={t("form.RefundManualCheckSumFlag")} name={["item", "RefundManualCheckSumFlag"]}>
              <Radio.Group>
                <Radio value={true}>True</Radio>
                <Radio value={false}>False</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col flex="300px">
            <Form.Item label={t("form.Amex4DbcFlag")} name={["item", "Amex4DbcFlag"]}>
              <Radio.Group>
                <Radio value={true}>True</Radio>
                <Radio value={false}>False</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Divider />
      <div style={{ display: "flex", justifyContent: "flex-end", marginBottom: "5px" }}>
        <div style={{ width: "200px", display: "flex", justifyContent: "center" }} title="同步相同issuer name的可编辑数据">
          <Checkbox onChange={() => setSyncData(!syncData)}>{t("text.sync_data")}</Checkbox>
        </div>
      </div>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          type="primary"
          shape="round"
          style={{ width: "200px", marginRight: "20px" }}
          htmlType="button"
          onClick={cloneItem}
        >
          {t("button.clone")}
        </Button>
        <Button
          type="default"
          shape="round"
          style={{ width: "200px", marginRight: "20px" }}
          htmlType="button"
          loading={btnLoading}
          onClick={removeItem}
        >
          {t("button.delete")}
        </Button>
        <Button
          type="primary"
          shape="round"
          style={{ width: "200px" }}
          htmlType="submit"
          loading={btnLoading}
          onClick={() => form.submit()}
        >
          {t("button.save")}
        </Button>
      </div>
    </>
  );
};
