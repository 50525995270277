import * as React from 'react';
import {ReactElement} from "react";
import {loginUser} from "../../util/cookie";
import './home.css'
import {useTranslation} from "react-i18next";

type Props = {};
export const Home = (props: Props): ReactElement => {
    const userInfo = loginUser()
    const {t} = useTranslation()
    document.title = t('title.home') + ' - CardPool App Manager'
    return (
        <div className="home-container">
            <div className="home-container-bg"></div>
            <div className="home-container-content">
                <h3 className='welcome'>{t('text.hello')} {userInfo.full_name}</h3>
                <p className="lead">{t('text.home_welcome')}</p>
            </div>
        </div>
    );
};