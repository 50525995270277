import React from 'react';
import './App.less';
import {Route, Routes} from "react-router-dom";
import {BaseLayout} from "./components/layouts/baseLayout";
import {Login} from "./pages/login";
import enUs from "antd/lib/locale/en_US";
import zhTW from "antd/lib/locale/zh_TW";
import {ConfigProvider} from "antd";

function App() {
  const defaultLanguage = localStorage.getItem("Lang") || "enUS"
  return (
    <ConfigProvider locale={defaultLanguage === 'enUS' ? enUs : zhTW}>
      <Routes>
        <Route path='/*' element={<BaseLayout/>}/>
        <Route path='/auth/login' element={<Login/>}/>
      </Routes>
    </ConfigProvider>
  );
}

export default App;
