import React, {FC, useEffect, useState} from "react";
import {Button, Col, Divider, Form, notification, Row, Upload} from "antd";
import {useTranslation} from "react-i18next";
import {getToken} from "../../../util/cookie";
import {UploadFile} from "antd/lib/upload/interface";
import {
  IMerchantPRMRInfoAPData,
  merchantPrmInfoAPData,
  saveMerchantPrmInfoAPData
} from "../../../services/merchantPrm/ap";

interface IProps {
  merchantId: string;
}

export const MerchantPrmAPData: FC<IProps> = ({merchantId}) => {
  const {t} = useTranslation()
  const [form] = Form.useForm()
  const initialFromValues: IMerchantPRMRInfoAPData = {
    id: "",
    merchantId: merchantId,
    data: ""
  }
  const [formValues, setFormValues] = useState<IMerchantPRMRInfoAPData>(initialFromValues);
  const [btnLoading, setBtnLoading] = useState<boolean>(false)
  const [fileList, setFileList] = useState<Array<UploadFile>>([])
  const uploadUrl = "/api/upload"
  useEffect(function () {
    if (merchantId !== "") {
      merchantPrmInfoAPData({merchantId: merchantId}).then(res => {
        if (res.status === 0) {
          setFormValues(res.data)
          if (res.data.data !== "") {
            setFileList([{
              uid: (new Date()).getTime().toString(),
              name: "DATA.zip",
              status: "done",
              url: res.data.data
            }])
          }
          form.setFieldsValue(res.data)
        }
      })
    }
  }, [merchantId])
  const saveForm = (values) => {
    setBtnLoading(true)
    saveMerchantPrmInfoAPData({
      id: formValues['id'],
      merchantId: merchantId,
      data: fileList.length > 0 ? (fileList[0].url || "") : ""
    }).then(res => {
      if (res.status === 0) {
        notification.success({
          message: "save success"
        })
        if (res.data.url !== "") {
          setFileList([{
            uid: (new Date()).getTime().toString(),
            name: "DATA.zip",
            status: "done",
            url: res.data.url
          }])
        }
      }
    }).finally(() => {
      setBtnLoading(false)
    })
  }
  return (
    <Row gutter={10} style={{marginTop: "20px"}} hidden={merchantId === ""}>
      <div className='form-content-container-content'>
        <div>
          <Form layout='vertical' form={form} initialValues={formValues} onFinish={saveForm}>
            <Row gutter={30}>
              <Col flex="300px">
                <Form.Item label='AppData'>
                  {<Upload
                    action={uploadUrl}
                    headers={{'Authorization': getToken()}}
                    onChange={(info) => {
                      if (info.file.status === "removed") {
                        setFileList([])
                      } else {
                        setFileList([info.file])
                      }
                      if (info.file.status === "done") {
                        if (info.file.response.status === 0) {
                          info.file.url = info.file.response.data.url
                          setFileList([info.file])
                        } else {
                          notification.error({message: info.file.response.msg})
                        }
                      }
                      if (info.file.status === "error") {
                        notification.error({message: `${info.file.name} file upload failed.`})
                      }
                    }}
                    onRemove={() => {
                      setFileList([])
                    }}
                    accept={".zip"}
                    fileList={fileList}
                    maxCount={1}>
                    <Button>Upload</Button>
                  </Upload>}
                </Form.Item>
              </Col>
            </Row>
          </Form>
          <Divider/>
          <div>
            <Button type="primary" shape="round" style={{width: "200px", float: "right"}}
                    htmlType="submit" loading={btnLoading} onClick={() => form.submit()}>
              {t('button.save')}
            </Button>
          </div>
        </div>
      </div>
    </Row>
  )
}