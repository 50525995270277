import {get} from "../../util/request";

export interface TerminalIdRequest {
  terminalId: string
}

export interface IIssuerStatusResponse {
  gpapyb: boolean
  gpapfq: boolean
  gpapyl: boolean
  smartyPay: boolean
  gpapdcc: boolean
  gpapyg: boolean
  amex: boolean
}


export const issuerStatusList = (params: TerminalIdRequest) => {
  return get<IIssuerStatusResponse>({url: "/api/terminal-prm/info/cp/acquirer-items/status", params})
}
