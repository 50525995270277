import React, { FC, useEffect, useState } from "react";
import { Button, Col, Divider, Row } from "antd";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { ContactLess } from "./emvcl/contactless";

interface IProps {
  terminalId: string;
}

export const TerminalPrmEmvcl: FC<IProps> = ({ terminalId }) => {
  const navigator = useNavigate();
  const location = useLocation();
  const [buttonKey, setButtonKey] = useState<string>("");
  const clickModule = (v: string) => {
    setButtonKey(v);
    navigator(v);
  };
  useEffect(
    function () {
      setButtonKey(location.pathname);
    },
    [location.pathname]
  );
  return (
    <Row gutter={10} style={{ marginTop: "20px" }} hidden={terminalId === ""}>
      <div className="form-content-container-content">
        <Row gutter={[20, 10]}>
          <Col style={{ minWidth: "380px" }}>
            <Button
              type={buttonKey.endsWith("/A0000000031010") ? "primary" : "default"}
              block
              onClick={() => clickModule("A0000000031010")}
            >
              VISA Debit/Credit (Classic) - A0000000031010
            </Button>
          </Col>
          <Col style={{ minWidth: "380px" }}>
            <Button
              type={buttonKey.endsWith("/A0000000032010") ? "primary" : "default"}
              block
              onClick={() => clickModule("A0000000032010")}
            >
              VISA Electron - A0000000032010
            </Button>
          </Col>
          <Col style={{ minWidth: "380px" }}>
            <Button
              type={buttonKey.endsWith("/A0000000033010") ? "primary" : "default"}
              block
              onClick={() => clickModule("A0000000033010")}
            >
              VISA Interlink - A0000000033010
            </Button>
          </Col>
          <Col style={{ minWidth: "380px" }}>
            <Button
              type={buttonKey.endsWith("/A0000000041010") ? "primary" : "default"}
              block
              onClick={() => clickModule("A0000000041010")}
            >
              MasterCard Credit/Debit (Global) - A0000000041010
            </Button>
          </Col>
          <Col style={{ minWidth: "380px" }}>
            <Button
              type={buttonKey.endsWith("/A0000000651010") ? "primary" : "default"}
              block
              onClick={() => clickModule("A0000000651010")}
            >
              JCB J Smart Credit - A0000000651010
            </Button>
          </Col>
          <Col style={{ minWidth: "380px" }}>
            <Button
              type={buttonKey.endsWith("/A000000333010101") ? "primary" : "default"}
              block
              onClick={() => clickModule("A000000333010101")}
            >
              UnionPay Debit - A000000333010101
            </Button>
          </Col>
          <Col style={{ minWidth: "380px" }}>
            <Button
              type={buttonKey.endsWith("/A000000333010102") ? "primary" : "default"}
              block
              onClick={() => clickModule("A000000333010102")}
            >
              UnionPay Credit - A000000333010102
            </Button>
          </Col>
          <Col style={{ minWidth: "380px" }}>
            <Button
              type={buttonKey.endsWith("/A000000333010103") ? "primary" : "default"}
              block
              onClick={() => clickModule("A000000333010103")}
            >
              UnionPay Quasi Credit - A000000333010103
            </Button>
          </Col>
          {/* <Col style={{ minWidth: "380px" }}>
            <Button
              type={buttonKey.endsWith("/A0000001523010") ? "primary" : "default"}
              block
              onClick={() => clickModule("A0000001523010")}
            >
              Discover, Pulse D Pas - A0000001523010
            </Button>
          </Col> */}
          <Col style={{ minWidth: "380px" }}>
            <Button
              type={buttonKey.endsWith("/A0000001523088") ? "primary" : "default"}
              block
              onClick={() => clickModule("A0000001523088")}
            >
              Discover TW - A0000001523088
            </Button>
          </Col>
          <Col style={{ minWidth: "380px" }}>
            <Button
              type={buttonKey.endsWith("/A00000015230") ? "primary" : "default"}
              block
              onClick={() => clickModule("A00000015230")}
            >
              Discover - A00000015230
            </Button>
          </Col>
          {/*<Col style={{ minWidth: "380px" }}>*/}
          {/*  <Button*/}
          {/*    type={buttonKey.endsWith("/A00000002501") ? "primary" : "default"}*/}
          {/*    block*/}
          {/*    onClick={() => clickModule("A00000002501")}*/}
          {/*  >*/}
          {/*    American Express - A00000002501*/}
          {/*  </Button>*/}
          {/*</Col>*/}
          <Col style={{ minWidth: "380px" }}>
            <Button
              type={buttonKey.endsWith("/A000000025") ? "primary" : "default"}
              block
              onClick={() => clickModule("A000000025")}
            >
              Amex - A000000025
            </Button>
          </Col>
        </Row>
        <Divider />
        <Routes>
          <Route path="A0000000031010" element={<ContactLess terminalId={terminalId} aid="A0000000031010" />} />
          <Route path="A0000000032010" element={<ContactLess terminalId={terminalId} aid="A0000000032010" />} />
          <Route path="A0000000033010" element={<ContactLess terminalId={terminalId} aid="A0000000033010" />} />
          <Route path="A0000000041010" element={<ContactLess terminalId={terminalId} aid="A0000000041010" />} />
          <Route path="A0000000651010" element={<ContactLess terminalId={terminalId} aid="A0000000651010" />} />
          <Route path="A000000333010101" element={<ContactLess terminalId={terminalId} aid="A000000333010101" />} />
          <Route path="A000000333010102" element={<ContactLess terminalId={terminalId} aid="A000000333010102" />} />
          <Route path="A000000333010103" element={<ContactLess terminalId={terminalId} aid="A000000333010103" />} />
          {/* <Route path="A0000001523010" element={<ContactLess terminalId={terminalId} aid="A0000001523010" />} /> */}
          <Route path="A0000001523088" element={<ContactLess terminalId={terminalId} aid="A0000001523088" />} />
          <Route path="A00000015230" element={<ContactLess terminalId={terminalId} aid="A00000015230" />} />
          <Route path="A00000002501" element={<ContactLess terminalId={terminalId} aid="A00000002501" />} />
          <Route path="A000000025" element={<ContactLess terminalId={terminalId} aid="A000000025" />} />
        </Routes>
      </div>
    </Row>
  );
};
