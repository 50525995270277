import React, {FC, useEffect, useState} from "react";
import {Button, Col, Divider, Form, Input, notification, Row} from "antd";
import {useTranslation} from "react-i18next";
import {getETicket, ISaveETicketRequest, saveETicket} from "../../../services/eticket/eticket";

interface IProps {
  terminalId: string;
}

export const TerminalPrmETEticket: FC<IProps> = ({terminalId}) => {
  const {t} = useTranslation()
  const [form] = Form.useForm()
  const [formValues, setFormValues] = useState<ISaveETicketRequest>();
  const [btnLoading, setBtnLoading] = useState<boolean>(false)
  useEffect(function () {
    if (terminalId !== "") {
      getETicket({terminalId: terminalId}).then(res => {
        if (res.status === 0) {
          setFormValues(res.data)
          form.setFieldsValue(res.data)
        }
      })
    }
  }, [terminalId])
  const saveForm = (values) => {
    if (formValues === undefined) {
      return
    }
    setBtnLoading(true)
    saveETicket({terminalId: terminalId, eticket: values["eticket"]}).then(res => {
      if (res.status === 0) {
        notification.success({
          message: "save success"
        })
      }
    }).finally(() => {
      setBtnLoading(false)
    })
  }
  return (
    <Row gutter={10} style={{marginTop: "20px"}} hidden={terminalId === ""}>
      <div className='form-content-container-content'>
        <Form layout='vertical' form={form} initialValues={formValues} onFinish={saveForm}>
          <Row gutter={30}>
            <Col flex="300px">
              <Form.Item label={t('form.EccFlag')} name={["eticket", "EccFlag"]}>
                <Input/>
              </Form.Item>
            </Col>
            <Col flex="300px">
              <Form.Item label={t('form.IpassFlag')} name={["eticket", "IpassFlag"]}>
                <Input/>
              </Form.Item>
            </Col>
            <Col flex="300px">
              <Form.Item label={t('form.PollCardTimeout')} name={["eticket", "PollCardTimeout"]}>
                <Input/>
              </Form.Item>
            </Col>
            <Col flex="300px">
              <Form.Item label={t('form.DebugLogFlag')} name={["eticket", "DebugLogFlag"]}>
                <Input/>
              </Form.Item>
            </Col>
            <Col flex="300px">
              <Form.Item label={t('form.DebugLogMaxSize')} name={["eticket", "DebugLogMaxSize"]}>
                <Input/>
              </Form.Item>
            </Col>
            <Col flex="300px">
              <Form.Item label={t('form.DebugLogMaxDays')} name={["eticket", "DebugLogMaxDays"]}>
                <Input/>
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <Divider/>
        <div>
          <Button type="primary" shape="round" style={{width: "200px", float: "right"}}
                  htmlType="submit" loading={btnLoading} onClick={() => form.submit()}>
            {t('button.save')}
          </Button>
        </div>
      </div>
    </Row>
  )
}