import * as React from "react";
import {useTranslation} from "react-i18next";
import {Button, Col, Form, Modal, notification, Row, Select} from "antd";
import {FC, useEffect, useState} from "react";
import {merchantSimpleList} from "../../services/merchantPrm";
import {resetTerminalPrm} from "../../services/terminalPrm";
import {Route, Routes, useLocation, useNavigate} from "react-router-dom";
import "./style.css";
import {MerchantPrmBasic} from "./basic";
import {MerchantPrmAPData} from "./ap/apData";
import {getTenantId} from "../../util/cookie";
interface IMerchantOption{
  label:string
  value:string
}
export const MerchantPrm: FC = () => {
  const {t} = useTranslation();
  const navigator = useNavigate();
  const location = useLocation();
  const merchantInit:Array<IMerchantOption>=new Array<IMerchantOption>()
  const [merchants, setMerchants] = useState<Array<IMerchantOption>>(merchantInit);
  const [merchantId, setMerchantId] = useState("");
  const [loading, setLoading] = useState(true);
  const [buttonKey, setButtonKey] = useState<String>("");
  const [btnResetLoading, setBtnResetLoading] = useState<boolean>(false);
  const getMerchantSimpleList = (tenantId:string) => {
    setLoading(true);
    merchantSimpleList({tenantId:tenantId})
      .then((res) => {
        if (res.status === 0) {
          let arr: Array<IMerchantOption> = new Array<IMerchantOption>();
          res.data.map((item) => {
            const obj = {
              label: item.name,
              value: item.merchant_id,
            };
            arr.push(obj);
            return item;
          });
          setMerchants(arr);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };
  document.title = t("title.merchant_prm") + " - CardPool App Manager";
  useEffect(function () {
    getMerchantSimpleList(getTenantId());
  }, []);
  useEffect(
    function () {
      setButtonKey(location.pathname);
    },
    [location.pathname]
  );

  const selectMerchant = (value: string) => {
    setMerchantId(value);
  };

  const clickModule = (v: string) => {
    setButtonKey(v);
    navigator(v);
  };

  const clickReset = () => {
    Modal.confirm({
      title: "重要警告",
      content: "您的操作將會使修改的數據完全删除，数据將還原為默認數據，請謹慎確認！",
      onOk: () => postReset(),
      okText: "Yes",
      maskClosable: false,
      closable: false,
    });
  };

  const postReset = () => {
    if (merchantId !== "") {
      setBtnResetLoading(true);
      resetTerminalPrm({terminalId: merchantId}).then((res) => {
        if (res.status === 0) {
          notification.success({
            message: "reset success",
          });
          navigator("/merchant-prm")
        }
      }).finally(() => {
        setBtnResetLoading(false);
      })
    }
  }
  const filterMerchant = (inputValue:string, option?: { label: string; value: string }):boolean => {
    return (option?.label ?? '').toLowerCase().includes(inputValue.toLowerCase());
  }
  return (
    <div style={{height: "100%"}}>
      <Row gutter={10}>
        <div className="form-content-container-content">
          <Form layout="inline" style={{width: "100%"}}>
            <Form.Item label={t("form.merchant")}>
              <Select
                style={{minWidth: "280px"}}
                showSearch
                placeholder={t("placeholder.select_merchant")}
                options={merchants}
                onChange={selectMerchant}
                loading={loading}
                filterOption={filterMerchant}
              />
            </Form.Item>
            <Form.Item style={{flex: 1}}>
              <div style={{float: "right"}}>
                <Button type="primary" shape="round" onClick={clickReset} loading={btnResetLoading}
                        style={{width: "200px"}}>
                  Reset
                </Button>
              </div>
            </Form.Item>
          </Form>
          <Form
            layout="inline"
            hidden={merchantId === ""}
            style={{marginTop: "20px"}}
            labelAlign="right"
            labelCol={{flex: "130px"}}
          >
            <Form.Item label="Basic Information" style={{width: "100%"}}>
              <Button
                type={buttonKey.indexOf("/basic") !== -1 ? "primary" : "default"}
                onClick={() => clickModule("basic")}
              >
                Basic
              </Button>
            </Form.Item>
          </Form>
          <Form
            layout="inline"
            hidden={merchantId === ""}
            style={{marginTop: "20px"}}
            labelAlign="right"
            labelCol={{flex: "130px"}}
          >
            <Form.Item label="AP" style={{width: "100%"}}>
              <Row gutter={20}>
                <Col>
                  <Button
                    type={buttonKey.indexOf("/apdata") !== -1 ? "primary" : "default"}
                    onClick={() => clickModule("apdata")}
                  >
                    DATA.zip
                  </Button>
                </Col>
              </Row>
            </Form.Item>
          </Form>
        </div>
      </Row>
      <Routes>
        <Route path="basic" element={<MerchantPrmBasic merchantId={merchantId}/>}/>
        <Route path="apdata" element={<MerchantPrmAPData merchantId={merchantId}/>}/>
      </Routes>
    </div>
  );
};
