import {TerminalPrmInfoRequest} from "../terminalPrm";
import {get, post} from "../../util/request";

export interface ITerminalPRMRInfoETicketData {
  id: string;
  terminalId: string;
  data: string;
}
export interface ITerminalPRMRInfoDataResponse {
  url: string;
}

export const terminalPrmInfoETicketData = (params: TerminalPrmInfoRequest) => {
  return get<ITerminalPRMRInfoETicketData>({url: "/api/terminal-prm/info/et/data", params})
}

export const saveTerminalPrmInfoETicketData = (data: ITerminalPRMRInfoETicketData) => {
  return post<ITerminalPRMRInfoDataResponse>({url: "/api/terminal-prm/info/et/data", data})
}
