import React, {FC, useEffect, useState} from "react";
import {Button, Col, Divider, Form, Input, notification, Radio, Row} from "antd";
import {useTranslation} from "react-i18next";
import {
  ITerminalPRMRInfoQRCodeGateway,
  saveTerminalPrmInfoQRCodeGateway,
  terminalPrmInfoQRCodeGateway
} from "../../../services/qrcode/qrcode";

interface IProps {
  terminalId: string;
}

export const TerminalPrmQRCode: FC<IProps> = ({terminalId}) => {
  const {t} = useTranslation()
  const [form] = Form.useForm()
  const [formValues, setFormValues] = useState<ITerminalPRMRInfoQRCodeGateway>();
  const [btnLoading, setBtnLoading] = useState<boolean>(false)
  useEffect(function () {
    if (terminalId !== "") {
      terminalPrmInfoQRCodeGateway({terminal_id: terminalId}).then(res => {
        if (res.status === 0) {
          setFormValues(res.data)
          form.setFieldsValue(res.data)
        }
      })
    }
  }, [terminalId])
  const saveForm = (values) => {
    if (formValues === undefined) {
      return
    }
    setBtnLoading(true)
    saveTerminalPrmInfoQRCodeGateway({
      id: formValues['id'],
      terminalId: terminalId,
      qrcode: values['qrcode']
    }).then(res => {
      if (res.status === 0) {
        notification.success({
          message: "save success"
        })
      }
    }).finally(() => {
      setBtnLoading(false)
    })
  }
  return (
    <Row gutter={10} style={{marginTop: "20px"}} hidden={terminalId === ""}>
      <div className='form-content-container-content'>
        <Form layout='vertical' form={form} initialValues={formValues} onFinish={saveForm}>
          <Row gutter={30}>
            <Col flex="500px">
              <Form.Item label={t('form.SaleUrl')} name={['qrcode', 'SaleUrl']}>
                <Input/>
              </Form.Item>
            </Col>
            <Col flex="500px">
              <Form.Item label={t('form.RefundUrl')} name={['qrcode', 'RefundUrl']}>
                <Input/>
              </Form.Item>
            </Col>
            <Col flex="500px">
              <Form.Item label={t('form.QueryUrl')} name={['qrcode', 'QueryUrl']}>
                <Input/>
              </Form.Item>
            </Col>
            <Col flex="500px">
              <Form.Item label={t('form.LogonUrl')} name={['qrcode', 'LogonUrl']}>
                <Input/>
              </Form.Item>
            </Col>
            <Col flex="500px">
              <Form.Item label={t('form.CancelUrl')} name={['qrcode', 'CancelUrl']}>
                <Input/>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={30}>
            <Col flex="300px">
              <Form.Item label={t('form.CurrencyCode')} name={['qrcode', 'CurrencyCode']}>
                <Input/>
              </Form.Item>
            </Col>
            <Col flex="300px">
              <Form.Item label={t('form.CurrencyNumber')} name={['qrcode', 'CurrencyNumber']}>
                <Input/>
              </Form.Item>
            </Col>
            <Col flex="300px">
              <Form.Item label={t('form.TxnTimeout')} name={['qrcode', 'TxnTimeout']}>
                <Input/>
              </Form.Item>
            </Col>
            <Col flex="300px">
              <Form.Item label={t('form.ProdDesc')} name={['qrcode', 'ProdDesc']}>
                <Input/>
              </Form.Item>
            </Col>
            <Col flex="300px">
              <Form.Item label={t('form.acquirerId')} name={['qrcode', 'acquirerId']}>
                <Input/>
              </Form.Item>
            </Col>
            <Col flex="300px">
              <Form.Item label={t('form.acquirerTid')} name={['qrcode', 'acquirerTid']}>
                <Input/>
              </Form.Item>
            </Col>
            <Col flex="300px">
              <Form.Item label={t('form.acquirerMid')} name={['qrcode', 'acquirerMid']}>
                <Input/>
              </Form.Item>
            </Col>
            <Col flex="300px">
              <Form.Item label={t("form.RequestNetwork")} name={["qrcode" ,"RequestNetwork"]}>
                <Radio.Group>
                  <Radio value={true}>True</Radio>
                  <Radio value={false}>False</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col flex="300px">
              <Form.Item label={t('form.RequestNetworkType')} name={['qrcode', 'RequestNetworkType']}>
                <Input/>
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <Divider/>
        <div>
          <Button type="primary" shape="round" style={{width: "200px", float: "right"}}
                  htmlType="submit" loading={btnLoading} onClick={() => form.submit()}>
            {t('button.save')}
          </Button>
        </div>
      </div>
    </Row>
  )
}