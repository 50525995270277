import React, { FC, useEffect, useState } from "react";
import { Checkbox, Col, Divider, Modal, Row } from "antd";
import { useTranslation } from "react-i18next";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import { TerminalPrmCPGPAPybIndex } from "./gpapyb/gpapyb";
import { TerminalPrmCPGPAPfqIndex } from "./gpapfq/gpapfq";
import { TerminalPrmCPGPAPylIndex } from "./gpapyl/gpapyl";
import { TerminalPrmCPGPAPygIndex } from "./gpapyg/gpapyg";
import { TerminalPrmCPSmartyPayIndex } from "./smartyPay/smartyPay";
import { TerminalPrmCPGPAPdccIndex } from "./gpapdcc/gpapdcc";
import { TerminalPrmCPAmexIndex } from "./amex/amex";
import { TerminalPrmCPAcquirerDef } from "./acquirer/acquirer";
import { IIssuerStatusResponse, issuerStatusList } from "../../../services/cardpool/acquirer";
import { enableIssuer as enableGPAPyb } from "../../../services/cardpool/gpapyb";
import { enableIssuer as enableGPAPfq } from "../../../services/cardpool/gpapfq";
import { enableIssuer as enableGPAPyl } from "../../../services/cardpool/gpapyl";
import { enableIssuer as enableGPAPdcc } from "../../../services/cardpool/gpapdcc";
import { enableIssuer as enableSmartyPay } from "../../../services/cardpool/smartyPay";
import { enableIssuer as enableAmex } from "../../../services/cardpool/amex";
import { enableIssuer as enableGPAPyg } from "../../../services/cardpool/gpapyg";

interface IProps {
  terminalId: string;
}

export const TerminalPrmCPAcquirer: FC<IProps> = ({ terminalId }) => {
  const { t } = useTranslation();
  const navigator = useNavigate();
  const location = useLocation();
  const statueInitial = {
    gpapdcc: false,
    gpapfq: false,
    gpapyb: false,
    gpapyl: false,
    gpapyg: false,
    smartyPay: false,
    amex: false,
  };
  const [formValues, setFormValues] = useState<IIssuerStatusResponse>(statueInitial);
  const [checkBoxDisabled, setCheckBoxDisabled] = useState<boolean>(false);
  const [refreshValue, setRefreshValue] = useState<number>(0);
  useEffect(
    function () {
      if (terminalId !== "") {
        issuerStatusList({ terminalId: terminalId }).then((res) => {
          if (res.status === 0) {
            setFormValues(res.data);
          }
        });
      }
    },
    [terminalId]
  );

  const toIssuer = (path: string) => {
    navigator(path);
  };

  const changeGPAPyb = (value) => {
    const flag: boolean = value.target.checked;
    if (flag) {
      postGPAPyb(flag);
    } else {
      Modal.confirm({
        title: "重要警告",
        content: "您的操作將會使修改的數據完全删除，再次啟用將還原為默認數據，請謹慎確認！",
        onOk: () => postGPAPyb(flag),
        okText: "Yes",
        maskClosable: false,
        closable: false,
      });
    }
  };

  const postGPAPyb = (value: boolean) => {
    setCheckBoxDisabled(true);
    enableGPAPyb({ terminalId: terminalId, enable: value })
      .then((res) => {
        if (res.status === 0) {
          const newValues: IIssuerStatusResponse = {
            gpapdcc: formValues.gpapdcc,
            gpapfq: formValues.gpapfq,
            gpapyb: value,
            gpapyl: formValues.gpapyl,
            smartyPay: formValues.smartyPay,
            amex: formValues.amex,
            gpapyg: formValues.gpapyg,
          };
          setFormValues(newValues);
          const nv: number = refreshValue + 1;
          setRefreshValue(nv);
        }
      })
      .finally(() => {
        setCheckBoxDisabled(false);
      });
  };

  const changeGPAPfq = (value) => {
    const flag: boolean = value.target.checked;
    if (flag) {
      postGPAPfq(flag);
    } else {
      Modal.confirm({
        title: "重要警告",
        content: "您的操作將會使修改的數據完全删除，再次啟用將還原為默認數據，請謹慎確認！",
        onOk: () => postGPAPfq(flag),
        okText: "Yes",
        maskClosable: false,
        closable: false,
      });
    }
  };

  const postGPAPfq = (value: boolean) => {
    setCheckBoxDisabled(true);
    enableGPAPfq({ terminalId: terminalId, enable: value })
      .then((res) => {
        if (res.status === 0) {
          const newValues: IIssuerStatusResponse = {
            gpapdcc: formValues.gpapdcc,
            gpapfq: value,
            gpapyb: formValues.gpapyb,
            gpapyl: formValues.gpapyl,
            smartyPay: formValues.smartyPay,
            amex: formValues.amex,
            gpapyg: formValues.gpapyg,
          };
          setFormValues(newValues);
          const nv: number = refreshValue + 1;
          setRefreshValue(nv);
        }
      })
      .finally(() => {
        setCheckBoxDisabled(false);
      });
  };

  const changeGPAPyl = (value) => {
    const flag: boolean = value.target.checked;
    if (flag) {
      postGPAPyl(flag);
    } else {
      Modal.confirm({
        title: "重要警告",
        content: "您的操作將會使修改的數據完全删除，再次啟用將還原為默認數據，請謹慎確認！",
        onOk: () => postGPAPyl(flag),
        okText: "Yes",
        maskClosable: false,
        closable: false,
      });
    }
  };

  const postGPAPyl = (value: boolean) => {
    setCheckBoxDisabled(true);
    enableGPAPyl({ terminalId: terminalId, enable: value })
      .then((res) => {
        if (res.status === 0) {
          const newValues: IIssuerStatusResponse = {
            gpapdcc: formValues.gpapdcc,
            gpapfq: formValues.gpapfq,
            gpapyb: formValues.gpapyb,
            gpapyl: value,
            smartyPay: formValues.smartyPay,
            amex: formValues.amex,
            gpapyg: formValues.gpapyg,
          };
          setFormValues(newValues);
          const nv: number = refreshValue + 1;
          setRefreshValue(nv);
        }
      })
      .finally(() => {
        setCheckBoxDisabled(false);
      });
  };

  const changeGPAPyg = (value) => {
    const flag: boolean = value.target.checked;
    if (flag) {
      postGPAPyg(flag);
    } else {
      Modal.confirm({
        title: "重要警告",
        content: "您的操作將會使修改的數據完全删除，再次啟用將還原為默認數據，請謹慎確認！",
        onOk: () => postGPAPyg(flag),
        okText: "Yes",
        maskClosable: false,
        closable: false,
      });
    }
  };

  const postGPAPyg = (value: boolean) => {
    setCheckBoxDisabled(true);
    enableGPAPyg({ terminalId: terminalId, enable: value })
      .then((res) => {
        if (res.status === 0) {
          const newValues: IIssuerStatusResponse = {
            gpapdcc: formValues.gpapdcc,
            gpapfq: formValues.gpapfq,
            gpapyb: formValues.gpapyb,
            gpapyl: formValues.gpapyl,
            smartyPay: formValues.smartyPay,
            amex: formValues.amex,
            gpapyg: value,
          };
          setFormValues(newValues);
          const nv: number = refreshValue + 1;
          setRefreshValue(nv);
        }
      })
      .finally(() => {
        setCheckBoxDisabled(false);
      });
  };

  const changeSmartyPay = (value) => {
    const flag: boolean = value.target.checked;
    if (flag) {
      postSmartyPay(flag);
    } else {
      Modal.confirm({
        title: "重要警告",
        content: "您的操作將會使修改的數據完全删除，再次啟用將還原為默認數據，請謹慎確認！",
        onOk: () => postSmartyPay(flag),
        okText: "Yes",
        maskClosable: false,
        closable: false,
      });
    }
  };

  const postSmartyPay = (value: boolean) => {
    setCheckBoxDisabled(true);
    enableSmartyPay({ terminalId: terminalId, enable: value })
      .then((res) => {
        if (res.status === 0) {
          const newValues: IIssuerStatusResponse = {
            gpapdcc: formValues.gpapdcc,
            gpapfq: formValues.gpapfq,
            gpapyb: formValues.gpapyb,
            gpapyl: formValues.gpapyl,
            smartyPay: value,
            amex: formValues.amex,
            gpapyg: formValues.gpapyg,
          };
          setFormValues(newValues);
          const nv: number = refreshValue + 1;
          setRefreshValue(nv);
        }
      })
      .finally(() => {
        setCheckBoxDisabled(false);
      });
  };

  const changeGPAPdcc = (value) => {
    const flag: boolean = value.target.checked;
    if (flag) {
      postGPAPdcc(flag);
    } else {
      Modal.confirm({
        title: "重要警告",
        content: "您的操作將會使修改的數據完全删除，再次啟用將還原為默認數據，請謹慎確認！",
        onOk: () => postGPAPdcc(flag),
        okText: "Yes",
        maskClosable: false,
        closable: false,
      });
    }
  };

  const postGPAPdcc = (value: boolean) => {
    setCheckBoxDisabled(true);
    enableGPAPdcc({ terminalId: terminalId, enable: value })
      .then((res) => {
        if (res.status === 0) {
          const newValues: IIssuerStatusResponse = {
            gpapdcc: value,
            gpapfq: formValues.gpapfq,
            gpapyb: formValues.gpapyb,
            gpapyl: formValues.gpapyl,
            smartyPay: formValues.smartyPay,
            amex: formValues.amex,
            gpapyg: formValues.gpapyg,
          };
          setFormValues(newValues);
          const nv: number = refreshValue + 1;
          setRefreshValue(nv);
        }
      })
      .finally(() => {
        setCheckBoxDisabled(false);
      });
  };
  const changeAmex = (value) => {
    const flag: boolean = value.target.checked;
    if (flag) {
      postAmex(flag);
    } else {
      Modal.confirm({
        title: "重要警告",
        content: "您的操作將會使修改的數據完全删除，再次啟用將還原為默認數據，請謹慎確認！",
        onOk: () => postAmex(flag),
        okText: "Yes",
        maskClosable: false,
        closable: false,
      });
    }
  };

  const postAmex = (value: boolean) => {
    setCheckBoxDisabled(true);
    enableAmex({ terminalId: terminalId, enable: value })
      .then((res) => {
        if (res.status === 0) {
          const newValues: IIssuerStatusResponse = {
            gpapdcc: formValues.gpapdcc,
            gpapfq: formValues.gpapfq,
            gpapyb: formValues.gpapyb,
            gpapyl: formValues.gpapyl,
            smartyPay: formValues.smartyPay,
            amex: value,
            gpapyg: formValues.gpapyg,
          };
          setFormValues(newValues);
          const nv: number = refreshValue + 1;
          setRefreshValue(nv);
        }
      })
      .finally(() => {
        setCheckBoxDisabled(false);
      });
  };
  return (
    <Row gutter={10} style={{ marginTop: "20px" }} hidden={terminalId === ""}>
      <div className="form-content-container-content">
        <Row gutter={[20, 10]}>
          <Col>
            <Checkbox disabled={checkBoxDisabled} checked={formValues.gpapyb} onChange={changeGPAPyb}>
              GPAP一般
            </Checkbox>
          </Col>
          <Col>
            <Checkbox disabled={checkBoxDisabled} checked={formValues.gpapfq} onChange={changeGPAPfq}>
              GPAP分期
            </Checkbox>
          </Col>
          <Col>
            <Checkbox disabled={checkBoxDisabled} checked={formValues.gpapyl} onChange={changeGPAPyl}>
              GPAP銀聯
            </Checkbox>
          </Col>
          <Col>
            <Checkbox disabled={checkBoxDisabled} checked={formValues.smartyPay} onChange={changeSmartyPay}>
              SMARTPAY交易
            </Checkbox>
          </Col>
          <Col>
            <Checkbox disabled={checkBoxDisabled} checked={formValues.gpapdcc} onChange={changeGPAPdcc}>
              GPAP DCC
            </Checkbox>
          </Col>
          <Col>
            <Checkbox disabled={checkBoxDisabled} checked={formValues.gpapyg} onChange={changeGPAPyg}>
              GPAP郵購
            </Checkbox>
          </Col>
          <Col>
            <Checkbox disabled={checkBoxDisabled} checked={formValues.amex} onChange={changeAmex}>
              AMEX
            </Checkbox>
          </Col>
        </Row>
        <Divider />
        <div className="custom-tabs">
          <div className="custom-tabs-left">
            <div
              className={
                location.pathname.indexOf("acquirer/acquirerdef") !== -1
                  ? "custom-tabs-left-items-selected"
                  : "custom-tabs-left-items"
              }
            >
              <div onClick={() => toIssuer("acquirerdef")}>{t("title.acquirer_def")}</div>
            </div>
            <div
              className={
                formValues.gpapyb
                  ? location.pathname.indexOf("acquirer/gpapyb") !== -1
                    ? "custom-tabs-left-items-selected"
                    : "custom-tabs-left-items"
                  : "custom-tabs-left-items-disabled"
              }
            >
              <div onClick={() => (formValues.gpapyb ? toIssuer("gpapyb") : null)}>GPAP一般</div>
            </div>
            <div
              className={
                formValues.gpapfq
                  ? location.pathname.indexOf("acquirer/gpapfq") !== -1
                    ? "custom-tabs-left-items-selected"
                    : "custom-tabs-left-items"
                  : "custom-tabs-left-items-disabled"
              }
            >
              <div onClick={() => (formValues.gpapfq ? toIssuer("gpapfq") : null)}>GPAP分期</div>
            </div>
            <div
              className={
                formValues.gpapyl
                  ? location.pathname.indexOf("acquirer/gpapyl") !== -1
                    ? "custom-tabs-left-items-selected"
                    : "custom-tabs-left-items"
                  : "custom-tabs-left-items-disabled"
              }
            >
              <div onClick={() => (formValues.gpapyl ? toIssuer("gpapyl") : null)}>GPAP銀聯</div>
            </div>
            <div
              className={
                formValues.smartyPay
                  ? location.pathname.indexOf("acquirer/smartypay") !== -1
                    ? "custom-tabs-left-items-selected"
                    : "custom-tabs-left-items"
                  : "custom-tabs-left-items-disabled"
              }
            >
              <div onClick={() => (formValues.smartyPay ? toIssuer("smartypay") : null)}>SMARTPAY交易</div>
            </div>
            <div
              className={
                formValues.gpapdcc
                  ? location.pathname.indexOf("acquirer/gpapdcc") !== -1
                    ? "custom-tabs-left-items-selected"
                    : "custom-tabs-left-items"
                  : "custom-tabs-left-items-disabled"
              }
            >
              <div onClick={() => (formValues.gpapdcc ? toIssuer("gpapdcc") : null)}>GPAP DCC</div>
            </div>
            <div
              className={
                formValues.gpapyg
                  ? location.pathname.indexOf("acquirer/gpapyg") !== -1
                    ? "custom-tabs-left-items-selected"
                    : "custom-tabs-left-items"
                  : "custom-tabs-left-items-disabled"
              }
            >
              <div onClick={() => (formValues.gpapyg ? toIssuer("gpapyg") : null)}>GPAP郵購</div>
            </div>
            <div
              className={
                formValues.amex
                  ? location.pathname.indexOf("acquirer/amex") !== -1
                    ? "custom-tabs-left-items-selected"
                    : "custom-tabs-left-items"
                  : "custom-tabs-left-items-disabled"
              }
            >
              <div onClick={() => (formValues.amex ? toIssuer("amex") : null)}>AMEX</div>
            </div>
          </div>
          <div className="custom-tabs-right">
            <Routes>
              <Route
                path="acquirerdef"
                element={<TerminalPrmCPAcquirerDef terminalId={terminalId} refresh={refreshValue} />}
              />
              <Route path="gpapyb/*" element={<TerminalPrmCPGPAPybIndex terminalId={terminalId} />} />
              <Route path="gpapfq/*" element={<TerminalPrmCPGPAPfqIndex terminalId={terminalId} />} />
              <Route path="gpapyl/*" element={<TerminalPrmCPGPAPylIndex terminalId={terminalId} />} />
              <Route path="gpapyg/*" element={<TerminalPrmCPGPAPygIndex terminalId={terminalId} />} />
              <Route path="smartypay/*" element={<TerminalPrmCPSmartyPayIndex terminalId={terminalId} />} />
              <Route path="gpapdcc/*" element={<TerminalPrmCPGPAPdccIndex terminalId={terminalId} />} />
              <Route path="amex/*" element={<TerminalPrmCPAmexIndex terminalId={terminalId} />} />
            </Routes>
          </div>
        </div>
      </div>
    </Row>
  );
};
