import React, { FC, useEffect, useState } from "react";
import { Button, Card, Col, Divider, Form, Input, notification, Row, Switch } from "antd";
import { useTranslation } from "react-i18next";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { capk, updateCapk } from "../../../../services/cardpool/capk";

interface IProps {
  terminalId: string;
  aid: string;
}

export const TerminalPrmContactCAPK: FC<IProps> = ({ terminalId, aid }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [btnLoading, setBtnLoading] = useState<boolean>(false);
  const [capkList, setCapkList] = useState<Array<Emv.ICAPKItem>>([]);

  useEffect(
    function () {
      if (terminalId !== "" && aid !== "") {
        getCapkList();
      }
    },
    [terminalId, aid]
  );

  const saveForm = (values) => {
    let postCapk: Array<Emv.ICAPKItem> = [];
    for (const valuesKey in values) {
      postCapk.push(values[valuesKey]);
    }
    setBtnLoading(true);
    updateCapk({ terminalId: terminalId, aid: aid, capk: postCapk })
      .then((res) => {
        if (res.status === 0) {
          notification.success({
            message: "save success",
          });
        }
      })
      .finally(() => {
        setBtnLoading(false);
      });
  };

  const getCapkList = () => {
    capk({ terminalId: terminalId, aid: aid }).then((res) => {
      if (res.status === 0) {
        setCapkList(res.data);
        form.resetFields();
      }
    });
  };

  return (
    <>
      <Form layout="vertical" form={form} initialValues={capkList} onFinish={saveForm}>
        <Row gutter={[30, 10]}>
          {capkList.map((item, index) => {
            return (
              <Col flex="300px" key={item.itemIndex}>
                <Card bordered={false}>
                  <Form.Item label="Enable" name={[index, "enable"]}>
                    <Switch
                      defaultChecked={item.enable}
                      checkedChildren={<CheckOutlined />}
                      unCheckedChildren={<CloseOutlined />}
                    />
                  </Form.Item>
                  <Form.Item label={item.isTest ? "ItemIndex(test)" : "ItemIndex"} name={[index, "itemIndex"]}>
                    <Input disabled />
                  </Form.Item>
                  <Form.Item label="Modules" name={[index, "modules"]}>
                    <Input disabled />
                  </Form.Item>
                  <Form.Item label="Exponent" name={[index, "exponent"]}>
                    <Input disabled />
                  </Form.Item>
                  <Form.Item label="Hash" name={[index, "hash"]}>
                    <Input disabled />
                  </Form.Item>
                </Card>
              </Col>
            );
          })}
        </Row>
      </Form>
      <Divider />
      <div>
        <Button
          type="primary"
          shape="round"
          style={{ width: "200px", float: "right",marginRight:"20px" }}
          htmlType="submit"
          loading={btnLoading}
          onClick={() => form.submit()}
        >
          {t("button.save")}
        </Button>
      </div>
    </>
  );
};
