import {get, post} from "../util/request";

interface MerchantSimpleListRequest {
  tenantId: string
}


interface MerchantSimpleListResponse {
  merchant_id: string
  name: string
}

export interface MerchantPrmRequest {
  merchant_id: string,
}

export interface IMerchantPRMRInfo {
  id: string;
  currentMerchantId: string;
  name: string;
  packageName: string;
  downloadTime: string;
  forceDownload: number;
  AppUI: AP.IAppUI;
  Receipts: AP.IReceipts;
  AppUtil: AP.IAppUtil;
  AppButtons: AP.IAppButtons;
  Cashier: AP.ICashier;
  Debuglog: AP.IDebuglog;
  MerchantPortal: AP.IMerchantPortal;
  PosService: AP.IPosService;
  Wifi: AP.IWifi;
  PaymentModules: AP.IPaymentModules;
  APAppData: string;
}

export interface IMerchantPRMPostResponse {
  id: string
}

export const merchantSimpleList = (params:MerchantSimpleListRequest) => {
  return get<[MerchantSimpleListResponse]>({url: "/api/merchants/simple-list",params})
}
export const merchantPrmInfo = (params: MerchantPrmRequest) => {
  return get<IMerchantPRMRInfo>({url: "/api/merchant-prm/info", params})
}

export const saveMerchantPRM = (data: IMerchantPRMRInfo) => {
  return post<IMerchantPRMPostResponse>({url: "/api/merchant-prm", data})
}