import { FC, useEffect, useState } from "react";
import { Button, Col, Divider, Form, Input, notification, Row } from "antd";
import { useTranslation } from "react-i18next";
import {
  ITerminalPRMRInfoTerminalConfig,
  saveTerminalPrmInfoTerminalConfig,
  terminalPrmInfoTerminalConfig,
} from "../../../../services/terminalPrm";

interface IProps {
  terminalId: string;
}

export const TerminalPrmTerminalConfig: FC<IProps> = ({ terminalId }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [formValues, setFormValues] = useState<ITerminalPRMRInfoTerminalConfig>();
  const [btnLoading, setBtnLoading] = useState<boolean>(false);
  useEffect(
    function () {
      if (terminalId !== "") {
        terminalPrmInfoTerminalConfig({ terminal_id: terminalId }).then((res) => {
          if (res.status === 0) {
            setFormValues(res.data);
            form.setFieldsValue(res.data);
          }
        });
      }
    },
    [terminalId]
  );
  const saveForm = (values) => {
    if (formValues === undefined) {
      return;
    }
    setBtnLoading(true);
    saveTerminalPrmInfoTerminalConfig({
      id: formValues["id"],
      terminalId: terminalId,
      terminalConfig: values["terminalConfig"],
    })
      .then((res) => {
        if (res.status === 0) {
          notification.success({
            message: "save success",
          });
        }
      })
      .finally(() => {
        setBtnLoading(false);
      });
  };
  return (
    <Row gutter={10} style={{ marginTop: "20px" }} hidden={terminalId === ""}>
      <Form layout="vertical" form={form} initialValues={formValues} onFinish={saveForm}>
        <Row gutter={30} style={{ marginLeft: "0", marginRight: "0" }}>
          {formValues?.terminalConfig.map((item, index) => {
            return (
              <Col flex="400px" key={index}>
                <Form.Item label={item.name + " / " + item.tag} name={["terminalConfig", index, "text"]}>
                  <Input />
                </Form.Item>
                <Form.Item hidden name={["terminalConfig", index, "name"]}>
                  <Input />
                </Form.Item>
                <Form.Item hidden name={["terminalConfig", index, "tag"]}>
                  <Input />
                </Form.Item>
                <Form.Item hidden name={["terminalConfig", index, "attribute"]}>
                  <Input />
                </Form.Item>
              </Col>
            );
          })}
        </Row>
      </Form>
      <Divider />
      <div style={{ width: "100%" }}>
        <Button
          type="primary"
          shape="round"
          style={{ width: "200px", float: "right",marginRight:"20px" }}
          htmlType="submit"
          loading={btnLoading}
          onClick={() => form.submit()}
        >
          {t("button.save")}
        </Button>
      </div>
    </Row>
  );
};
