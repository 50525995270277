import {get, post} from "../../util/request";
import {IExportUpgradeTerminalsResponse, IRunUpgradeRequest, IUpgradeRecordResponse} from "./types";


export const runUpgrade = (data: IRunUpgradeRequest) => {
    return post({url: "/api/upgrades", data})
}

export const upgradeRecord = () => {
    return get<IUpgradeRecordResponse>({url: "/api/upgrades"})
}

export const exportUpgradeTerminals = (id: number) => {
    return get<IExportUpgradeTerminalsResponse>({url: "/api/upgrades/" + id + "/terminals"})
}