import {get, post} from "../../util/request";

export interface TerminalIdRequest {
  terminalId: string
}

export interface ISaveETicketRequest {
  terminalId: string;
  eticket: ET.IETicket;
}

export const getETicket = (params: TerminalIdRequest) => {
  return get<ISaveETicketRequest>({url: "/api/terminal-prm/info/et/et", params})
}

export const saveETicket = (data: ISaveETicketRequest) => {
  return post({url: "/api/terminal-prm/info/et/et", data})
}