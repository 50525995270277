import React, { FC, useEffect, useState } from "react";
import { Alert, Button, Col, Divider, Form, Input, notification, Row } from "antd";
import { useTranslation } from "react-i18next";
import { txn, updateTxn } from "../../../../services/cardpool/contactless";

interface IProps {
  terminalId: string;
  aid: string;
  txnType: string;
}

export const TxnType: FC<IProps> = ({ terminalId, aid, txnType }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [formValues, setFormValues] = useState<Emv.IContactLess>();
  const [btnLoading, setBtnLoading] = useState<boolean>(false);
  const initialShowTags = { visa: false, master: false, amex: false, jcb: false, discover: false, cup: false };
  const [showTags, setShowTags] = useState<Record<string, boolean>>(initialShowTags);
  useEffect(
    function () {
      txn({ terminalId: terminalId, txnType: txnType, aid: aid }).then((res) => {
        if (res.status === 0) {
          setFormValues(res.data);
          form.setFieldsValue(res.data);
          let newShowTags = Object.assign({}, initialShowTags);
          if (res.data.base.kernelId === "03") {
            newShowTags["visa"] = true;
          } else if (res.data.base.kernelId === "02") {
            newShowTags["master"] = true;
          } else if (res.data.base.kernelId === "05") {
            newShowTags["jcb"] = true;
            newShowTags["visa"] = true;
          } else if (res.data.base.kernelId === "07") {
            newShowTags["visa"] = true;
            newShowTags["cup"] = true;
          } else if (res.data.base.kernelId === "06") {
            newShowTags["discover"] = true;
            newShowTags["visa"] = true;
            newShowTags["jcb"] = true;
          } else if (res.data.base.kernelId === "04") {
            newShowTags["amex"] = true;
            newShowTags["visa"] = true;
            newShowTags["master"] = true;
          }
          setShowTags(newShowTags);
        }
      });
    },
    [terminalId, txnType, aid]
  );

  const saveForm = (values) => {
    setBtnLoading(true);
    updateTxn({ terminalId: terminalId, aid: aid, txnType: txnType, contactLessValue: values })
      .then((res) => {
        if (res.status === 0) {
          notification.success({
            message: "save success",
          });
        }
      })
      .finally(() => {
        setBtnLoading(false);
      });
  };

  const customAlert = (text: string) => {
    return (
      <>
        <Alert
          style={{ marginBottom: "20px" }}
          message={
            <div style={{ height: "40px", lineHeight: "40px", fontSize: "18px", fontWeight: "bold" }}>{text}</div>
          }
          type="info"
        />
      </>
    );
  };

  return (
    <>
      <Form layout="vertical" form={form} initialValues={formValues} onFinish={saveForm}>
        {customAlert(t("title.contactless_aid"))}
        <Row gutter={30}>
          <Col flex="300px">
            <Form.Item label="AppId" name={["base", "appId"]}>
              <Input disabled />
            </Form.Item>
          </Col>
          <Col flex="300px">
            <Form.Item label="AppName" name={["base", "appName"]}>
              <Input disabled />
            </Form.Item>
          </Col>
          <Col flex="300px">
            <Form.Item label="KernelId" name={["base", "kernelId"]}>
              <Input disabled />
            </Form.Item>
          </Col>
          <Col flex="300px">
            <Form.Item label="TransactionType" name={["base", "transactionType"]}>
              <Input disabled />
            </Form.Item>
          </Col>
        </Row>
        {customAlert(t("title.terminal_tag"))}
        <Row gutter={30}>
          <Col flex="300px">
            <Form.Item label="TerminalCountryCode" name={["terminalTag", "9F1A"]}>
              <Input />
            </Form.Item>
          </Col>
          <Col flex="300px">
            <Form.Item label="TerminalFloorLimit" name={["terminalTag", "9F1B"]}>
              <Input />
            </Form.Item>
          </Col>
          <Col flex="300px">
            <Form.Item label="InterfaceDeviceSerialNumber" name={["terminalTag", "9F1E"]}>
              <Input />
            </Form.Item>
          </Col>
          <Col flex="300px">
            <Form.Item label="MerchantCategoryCode" name={["terminalTag", "9F15"]}>
              <Input />
            </Form.Item>
          </Col>
          <Col flex="300px">
            <Form.Item label="MerchantIdentifier" name={["terminalTag", "9F16"]}>
              <Input />
            </Form.Item>
          </Col>
          <Col flex="300px">
            <Form.Item label="MerchantNameandLocation" name={["terminalTag", "9F4E"]}>
              <Input />
            </Form.Item>
          </Col>
          <Col flex="300px">
            <Form.Item label="TerminalIdentification" name={["terminalTag", "9F1C"]}>
              <Input />
            </Form.Item>
          </Col>
          <Col flex="300px">
            <Form.Item label="TerminalType" name={["terminalTag", "9F35"]}>
              <Input />
            </Form.Item>
          </Col>
          <Col flex="300px">
            <Form.Item label="TransactionCurrencyCode" name={["terminalTag", "5F2A"]}>
              <Input />
            </Form.Item>
          </Col>
          <Col flex="300px">
            <Form.Item label="TransactionType" name={["terminalTag", "9C"]}>
              <Input />
            </Form.Item>
          </Col>
          <Col flex="300px">
            <Form.Item label="TerminalCapabilities" name={["terminalTag", "9F33"]}>
              <Input />
            </Form.Item>
          </Col>
          <Col flex="300px">
            <Form.Item label="AdditionalTerminalCapability" name={["terminalTag", "9F40"]}>
              <Input />
            </Form.Item>
          </Col>
          <Col flex="300px">
            <Form.Item label="AccountType" name={["terminalTag", "5F57"]}>
              <Input />
            </Form.Item>
          </Col>
          <Col flex="300px">
            <Form.Item label="AcquirerIdentifier" name={["terminalTag", "9F01"]}>
              <Input />
            </Form.Item>
          </Col>
          <Col flex="300px">
            <Form.Item label="ApplicationVersionNumber" name={["terminalTag", "9F09"]}>
              <Input />
            </Form.Item>
          </Col>
          <Col flex="300px">
            <Form.Item label="VlpTerminalSupportIndicator" name={["terminalTag", "9F7A"]}>
              <Input />
            </Form.Item>
          </Col>
          <Col flex="300px">
            <Form.Item label="ProductsPermitted" name={["terminalTag", "DF06"]}>
              <Input />
            </Form.Item>
          </Col>
        </Row>
        {customAlert(t("title.castles_proprietary_tags"))}
        <Row gutter={30}>
          <Col flex="300px">
            <Form.Item label="UploadDol" name={["castlesTag", "DF8F49"]}>
              <Input />
            </Form.Item>
          </Col>
          <Col flex="300px">
            <Form.Item label="VisaCryptogramVersion10UploadDol" name={["castlesTag", "DF8F45"]}>
              <Input />
            </Form.Item>
          </Col>
          <Col flex="300px">
            <Form.Item label="VisaCryptogramVersion17UploadDol" name={["castlesTag", "DF8F4C"]}>
              <Input />
            </Form.Item>
          </Col>
          <Col flex="300px">
            <Form.Item label="AdditionalTlvResponseInAdditionalData" name={["castlesTag", "DF8F4E"]}>
              <Input />
            </Form.Item>
          </Col>
          <Col flex="300px">
            <Form.Item label="ExpresspayUploadDol" name={["castlesTag", "DF5A"]}>
              <Input />
            </Form.Item>
          </Col>
          <Col flex="300px">
            <Form.Item label="ForcedTransactionOnline" name={["castlesTag", "DF9F01"]}>
              <Input />
            </Form.Item>
          </Col>
          <Col flex="300px">
            <Form.Item label="ApplicationPreferOrder" name={["castlesTag", "DF9F02"]}>
              <Input />
            </Form.Item>
          </Col>
          <Col flex="300px">
            <Form.Item label="GenAC1RequestAndResponse" name={["castlesTag", "FF41"]}>
              <Input />
            </Form.Item>
          </Col>
          <Col flex="300px">
            <Form.Item label="ExtendedSelectionSupportFlag" name={["castlesTag", "DF9F03"]}>
              <Input />
            </Form.Item>
          </Col>
          <Col flex="300px">
            <Form.Item label="ApplicationSelectionIndicator" name={["castlesTag", "DF9F16"]}>
              <Input />
            </Form.Item>
          </Col>
          <Col flex="300px">
            <Form.Item label="Track2OutputFormatForMsdRelatedPayments" name={["castlesTag", "DF9F04"]}>
              <Input />
            </Form.Item>
          </Col>
          <Col flex="300px">
            <Form.Item label="Track2OutputFormatForEmvRelatedPayments" name={["castlesTag", "DF9F05"]}>
              <Input />
            </Form.Item>
          </Col>
          <Col flex="300px">
            <Form.Item label="EncryptedPan" name={["castlesTag", "DF30"]}>
              <Input />
            </Form.Item>
          </Col>
          <Col flex="300px">
            <Form.Item label="PanChecksum" name={["castlesTag", "DF31"]}>
              <Input />
            </Form.Item>
          </Col>
          <Col flex="300px">
            <Form.Item label="MaskedPan" name={["castlesTag", "DF32"]}>
              <Input />
            </Form.Item>
          </Col>
          <Col flex="300px">
            <Form.Item label="EncryptedTrack2" name={["castlesTag", "DF33"]}>
              <Input />
            </Form.Item>
          </Col>
          <Col flex="300px">
            <Form.Item label="Track2Checksum" name={["castlesTag", "DF34"]}>
              <Input />
            </Form.Item>
          </Col>
          <Col flex="300px">
            <Form.Item label="MaskedTrack2" name={["castlesTag", "DF35"]}>
              <Input />
            </Form.Item>
          </Col>
          <Col flex="300px">
            <Form.Item label="EncryptedTrack1" name={["castlesTag", "DF36"]}>
              <Input />
            </Form.Item>
          </Col>
          <Col flex="300px">
            <Form.Item label="Track1Checksum" name={["castlesTag", "DF37"]}>
              <Input />
            </Form.Item>
          </Col>
          <Col flex="300px">
            <Form.Item label="MaskedTrack1" name={["castlesTag", "DF38"]}>
              <Input />
            </Form.Item>
          </Col>
          <Col flex="300px">
            <Form.Item label="Bin" name={["castlesTag", "DF39"]}>
              <Input />
            </Form.Item>
          </Col>
          <Col flex="300px">
            <Form.Item label="BinIndicator" name={["castlesTag", "DF40"]}>
              <Input />
            </Form.Item>
          </Col>
          <Col flex="300px">
            <Form.Item label="DynamicLimitsForCurrency" name={["castlesTag", "DF9F18"]}>
              <Input />
            </Form.Item>
          </Col>
          <Col flex="300px">
            <Form.Item label="SelectNextIndicator" name={["castlesTag", "DF9F1A"]}>
              <Input />
            </Form.Item>
          </Col>
          <Col flex="300px">
            <Form.Item label="MobilePaymentIndicator" name={["castlesTag", "DF9F1C"]}>
              <Input />
            </Form.Item>
          </Col>
          <Col flex="300px">
            <Form.Item label="CarteBancaireProceduresIndicator" name={["castlesTag", "DF9F1D"]}>
              <Input />
            </Form.Item>
          </Col>
          <Col flex="300px">
            <Form.Item label="CarteBancaireProductCode" name={["castlesTag", "DF9F1E"]}>
              <Input />
            </Form.Item>
          </Col>
          <Col flex="300px">
            <Form.Item label="CarteBancairePriorityLevel" name={["castlesTag", "DF9F1F"]}>
              <Input />
            </Form.Item>
          </Col>
          <Col flex="300px">
            <Form.Item label="CarteBancaireMultipleProductCodes" name={["castlesTag", "DF9F20"]}>
              <Input />
            </Form.Item>
          </Col>
          <Col flex="300px">
            <Form.Item label="DefaultTdol" name={["castlesTag", "DF8F40"]}>
              <Input />
            </Form.Item>
          </Col>
          <Col flex="300px">
            <Form.Item label="DefaultDdol" name={["castlesTag", "DF8F41"]}>
              <Input />
            </Form.Item>
          </Col>
        </Row>
        {showTags["visa"] ? (
          <>
            {customAlert(t("title.visa_tags"))}
            <Row gutter={30}>
              <Col flex="300px" hidden={["02", "05", "07", "06", "04"].includes(formValues?.base.kernelId || "")}>
                <Form.Item label="TerminalTransactionQualifier" name={["visaTag", "9F66"]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px" hidden={["02", "05", "07", "06", "04"].includes(formValues?.base.kernelId || "")}>
                <Form.Item label="ReaderContactlessTransactionLimit" name={["visaTag", "DF00"]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px" hidden={["02", "05", "07", "06", "04"].includes(formValues?.base.kernelId || "")}>
                <Form.Item label="ReaderCvmRequiredLimit" name={["visaTag", "DF01"]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px" hidden={["02", "05", "07", "06", "04"].includes(formValues?.base.kernelId || "")}>
                <Form.Item label="ReaderContactlessFloorLimit" name={["visaTag", "DF02"]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px" hidden={["02", "07"].includes(formValues?.base.kernelId || "")}>
                <Form.Item label="DisplayOfflineFundsIndicator" name={["visaTag", "DF05"]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px" hidden={["02", "04"].includes(formValues?.base.kernelId || "")}>
                <Form.Item label="StatusCheck" name={["visaTag", "DF25"]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px" hidden={["02", "04"].includes(formValues?.base.kernelId || "")}>
                <Form.Item label="AmountZeroCheck" name={["visaTag", "DF8F4B"]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px" hidden={["02", "05", "07", "06", "04"].includes(formValues?.base.kernelId || "")}>
                <Form.Item label="TerminalEntryCapability" name={["visaTag", "DF20"]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px" hidden={["02", "05", "07", "06", "04"].includes(formValues?.base.kernelId || "")}>
                <Form.Item label="VisaMsdTrack1EnableIndicator" name={["visaTag", "DF21"]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px" hidden={["02", "05", "07", "06", "04"].includes(formValues?.base.kernelId || "")}>
                <Form.Item label="VisaMsdTrack2EnableIndicator" name={["visaTag", "DF22"]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px" hidden={["02", "05", "07", "06", "04"].includes(formValues?.base.kernelId || "")}>
                <Form.Item label="VisaMsdCvn17EnableIndicator" name={["visaTag", "DF29"]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px" hidden={["02", "05", "07", "06", "04"].includes(formValues?.base.kernelId || "")}>
                <Form.Item label="DynamicReaderLimitEnableIndicator" name={["visaTag", "DF24"]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px" hidden={["02", "05", "07", "06", "04"].includes(formValues?.base.kernelId || "")}>
                <Form.Item label="DynamicReaderLimitDrlOverwriteTag" name={["visaTag", "DF23"]}>
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col flex="300px" hidden={["02", "05", "06", "04"].includes(formValues?.base.kernelId || "")}>
                <Form.Item label="ExceptionFileCheckEnableIndicator" name={["visaTag", "DF27"]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px" hidden={["02", "05", "07", "06", "04"].includes(formValues?.base.kernelId || "")}>
                <Form.Item label="VisaIssuerScriptResults" name={["visaTag", "9F5B"]}>
                  <Input />
                </Form.Item>
              </Col>
            </Row>
          </>
        ) : null}
        {showTags["master"] ? (
          <>
            {customAlert(t("title.master_card_tags"))}
            <Row gutter={30}>
              <Col flex="300px" hidden={["04", "05", "06", "07"].includes(formValues?.base.kernelId || "")}>
                <Form.Item label="CardDataInputCapability" name={["masterTag", "DF8117"]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px" hidden={["04", "05", "06", "07"].includes(formValues?.base.kernelId || "")}>
                <Form.Item label="CvmCapabilityCvmRequired" name={["masterTag", "DF8118"]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px" hidden={["04", "05", "06", "07"].includes(formValues?.base.kernelId || "")}>
                <Form.Item label="CvmCapabilityNoCvmRequired" name={["masterTag", "DF8119"]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px" hidden={["04", "05", "06", "07"].includes(formValues?.base.kernelId || "")}>
                <Form.Item label="DefaultUdol" name={["masterTag", "DF811A"]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px" hidden={["04", "05", "06", "07"].includes(formValues?.base.kernelId || "")}>
                <Form.Item label="KernelConfiguration" name={["masterTag", "DF811B"]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px" hidden={["04", "05", "06", "07"].includes(formValues?.base.kernelId || "")}>
                <Form.Item label="KernelId" name={["masterTag", "DF810C"]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px" hidden={["04", "05", "06", "07"].includes(formValues?.base.kernelId || "")}>
                <Form.Item label="MagStripeApplicationVersionNumber" name={["masterTag", "9F6D"]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px" hidden={["04", "05", "06", "07"].includes(formValues?.base.kernelId || "")}>
                <Form.Item label="MagStripeCvmCapabilityCvmRequired" name={["masterTag", "DF811E"]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px" hidden={["04", "05", "06", "07"].includes(formValues?.base.kernelId || "")}>
                <Form.Item label="MagStripeCvmCapabilityNoCvmRequired" name={["masterTag", "DF812C"]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px" hidden={["04", "05", "06", "07"].includes(formValues?.base.kernelId || "")}>
                <Form.Item label="MaxLifetimeOfTornTransactionLogRecord" name={["masterTag", "DF811C"]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px" hidden={["04", "05", "06", "07"].includes(formValues?.base.kernelId || "")}>
                <Form.Item label="MaxNumberOfTornTransactionLogRecord" name={["masterTag", "DF811D"]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px" hidden={["04", "05", "06", "07"].includes(formValues?.base.kernelId || "")}>
                <Form.Item label="ReaderContactlessFloorLimit" name={["masterTag", "DF8123"]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px" hidden={["05", "06", "07"].includes(formValues?.base.kernelId || "")}>
                <Form.Item label="ReaderContactlessTransactionLimitNoOnDeviceCvm" name={["masterTag", "DF8124"]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px" hidden={["05", "06", "07"].includes(formValues?.base.kernelId || "")}>
                <Form.Item label="ReaderContactlessTransactionLimitOnDeviceCvm" name={["masterTag", "DF8125"]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px" hidden={["04", "05", "06", "07"].includes(formValues?.base.kernelId || "")}>
                <Form.Item label="ReaderCvmRequiredLimit" name={["masterTag", "DF8126"]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px" hidden={["04", "05", "06", "07"].includes(formValues?.base.kernelId || "")}>
                <Form.Item label="SecurityCapability" name={["masterTag", "DF811F"]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px" hidden={["04", "05", "06", "07"].includes(formValues?.base.kernelId || "")}>
                <Form.Item label="TerminalActionCodeDefault" name={["masterTag", "DF8120"]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px" hidden={["04", "05", "06", "07"].includes(formValues?.base.kernelId || "")}>
                <Form.Item label="TerminalActionCodeDenial" name={["masterTag", "DF8121"]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px" hidden={["04", "05", "06", "07"].includes(formValues?.base.kernelId || "")}>
                <Form.Item label="TerminalActionCodeOnline" name={["masterTag", "DF8122"]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px" hidden={["04", "05", "06", "07"].includes(formValues?.base.kernelId || "")}>
                <Form.Item label="TerminalCapabilities">
                  <Input disabled/>
                </Form.Item>
              </Col>
              <Col flex="300px" hidden={["04", "05", "06", "07"].includes(formValues?.base.kernelId || "")}>
                <Form.Item label="TerminalRiskManagementData" name={["masterTag", "9F1D"]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px" hidden={["04", "05", "06", "07"].includes(formValues?.base.kernelId || "")}>
                <Form.Item label="MinimumRelayResistanceGracePeriod" name={["masterTag", "DF8132"]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px" hidden={["04", "05", "06", "07"].includes(formValues?.base.kernelId || "")}>
                <Form.Item label="MaximumRelayResistanceGracePeriod" name={["masterTag", "DF8133"]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px" hidden={["04", "05", "06", "07"].includes(formValues?.base.kernelId || "")}>
                <Form.Item
                  label="TerminalExpectedTransmissionTimeForRelayResistanceCApdu"
                  name={["masterTag", "DF8134"]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px" hidden={["04", "05", "06", "07"].includes(formValues?.base.kernelId || "")}>
                <Form.Item
                  label="TerminalExpectedTransmissionTimeForRelayResistanceRApdu"
                  name={["masterTag", "DF8135"]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px" hidden={["04", "05", "06", "07"].includes(formValues?.base.kernelId || "")}>
                <Form.Item label="RelayResistanceAccuracyThreshold" name={["masterTag", "DF8136"]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px" hidden={["04", "05", "06", "07"].includes(formValues?.base.kernelId || "")}>
                <Form.Item label="RelayResistanceTransmissionTimeMismatchThreshold" name={["masterTag", "DF8137"]}>
                  <Input />
                </Form.Item>
              </Col>
            </Row>
          </>
        ) : null}
        {showTags["amex"] ? (
          <>
            {customAlert(t("title.amex_tags"))}
            <Row gutter={30}>
              <Col flex="300px" hidden={["02", "05", "06", "07"].includes(formValues?.base.kernelId || "")}>
                <Form.Item label="AmexMagstripeUnpredictableNumberRange" name={["AMEXTag", "DF51"]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px" hidden={["02", "05", "06", "07"].includes(formValues?.base.kernelId || "")}>
                <Form.Item label="AmexTerminalCapabilities" name={["AMEXTag", "DF57"]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px" hidden={["02", "05", "06", "07"].includes(formValues?.base.kernelId || "")}>
                <Form.Item label="AmexTerminalConfiguration" name={["AMEXTag", "DF58"]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px" hidden={["02", "05", "06", "07"].includes(formValues?.base.kernelId || "")}>
                <Form.Item label="AmexFullOnlineRemovalTimer" name={["AMEXTag", "DF59"]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px" hidden={["02", "05", "06", "07"].includes(formValues?.base.kernelId || "")}>
                <Form.Item label="AmexVersionNumber" name={["AMEXTag", "DF5B"]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px" hidden={["02", "05", "06", "07"].includes(formValues?.base.kernelId || "")}>
                <Form.Item label="AmexContactlessTransactionLimit" name={["AMEXTag", "DF50"]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px" hidden={["02", "05", "06", "07"].includes(formValues?.base.kernelId || "")}>
                <Form.Item label="AmexCvmRequiredLimit" name={["AMEXTag", "DF53"]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px" hidden={["02", "05", "06", "07"].includes(formValues?.base.kernelId || "")}>
                <Form.Item label="AmexContactlessFloorLimit" name={["AMEXTag", "DF52"]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px" hidden={["02", "05", "06", "07"].includes(formValues?.base.kernelId || "")}>
                <Form.Item label="TerminalActionCodeDefault" name={["AMEXTag", "DF8120"]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px" hidden={["02", "05", "06", "07"].includes(formValues?.base.kernelId || "")}>
                <Form.Item label="TerminalActionCodeDenial" name={["AMEXTag", "DF8121"]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px" hidden={["02", "05", "06", "07"].includes(formValues?.base.kernelId || "")}>
                <Form.Item label="TerminalActionCodeOnline" name={["AMEXTag", "DF8122"]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px" hidden={["02", "05", "06", "07"].includes(formValues?.base.kernelId || "")}>
                <Form.Item label="AmexOnlineCapableDisconnected" name={["AMEXTag", "DF9F50"]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px" hidden={["02", "05", "06", "07"].includes(formValues?.base.kernelId || "")}>
                <Form.Item label="AmexContactInterfaceSupported" name={["AMEXTag", "DF9F51"]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px" hidden={["02", "05", "06", "07"].includes(formValues?.base.kernelId || "")}>
                <Form.Item label="AmexDynamicReaderLimitDefault" name={["AMEXTag", "DF9F53"]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px" hidden={["02", "05", "06", "07"].includes(formValues?.base.kernelId || "")}>
                <Form.Item label="AmexDynamicReaderLimits" name={["AMEXTag", "DF9F54"]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px" hidden={["02", "05", "06", "07"].includes(formValues?.base.kernelId || "")}>
                <Form.Item label="AmexMobileCvmSupported" name={["AMEXTag", "DF9F57"]}>
                  <Input />
                </Form.Item>
              </Col>
            </Row>
          </>
        ) : null}
        {showTags["jcb"] ? (
          <>
            {customAlert(t("title.jcb_tags"))}
            <Row gutter={30}>
              <Col flex="300px" hidden={["02", "04", "06", "07"].includes(formValues?.base.kernelId || "")}>
                <Form.Item label="ReaderContactlessTransactionLimit" name={["JCBTag", "DF00"]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px" hidden={["02", "04", "06", "07"].includes(formValues?.base.kernelId || "")}>
                <Form.Item label="ReaderCvmRequiredLimit" name={["JCBTag", "DF01"]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px" hidden={["02", "04", "06", "07"].includes(formValues?.base.kernelId || "")}>
                <Form.Item label="ReaderContactlessFloorLimit" name={["JCBTag", "DF02"]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px" hidden={["02", "04", "07"].includes(formValues?.base.kernelId || "")}>
                <Form.Item label="CombinationOptions" name={["JCBTag", "DF68"]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px" hidden={["02", "04", "07"].includes(formValues?.base.kernelId || "")}>
                <Form.Item label="TerminalCompatibilityIndicator" name={["JCBTag", "9F52"]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px" hidden={["02", "04", "07"].includes(formValues?.base.kernelId || "")}>
                <Form.Item label="TerminalInterchangeProfile" name={["JCBTag", "9F53"]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px" hidden={["02", "04", "06", "07"].includes(formValues?.base.kernelId || "")}>
                <Form.Item label="TargetPercent" name={["JCBTag", "DF8F42"]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px" hidden={["02", "04", "06", "07"].includes(formValues?.base.kernelId || "")}>
                <Form.Item label="MaxTargetPercent" name={["JCBTag", "DF8F43"]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px" hidden={["02", "04", "06", "07"].includes(formValues?.base.kernelId || "")}>
                <Form.Item label="ThresholdValue" name={["JCBTag", "DF8F44"]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px" hidden={["02", "04", "06", "07"].includes(formValues?.base.kernelId || "")}>
                <Form.Item label="TerminalActionCodeDefault" name={["JCBTag", "DF8120"]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px" hidden={["02", "04", "06", "07"].includes(formValues?.base.kernelId || "")}>
                <Form.Item label="TerminalActionCodeDenial" name={["JCBTag", "DF8121"]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px" hidden={["02", "04", "06", "07"].includes(formValues?.base.kernelId || "")}>
                <Form.Item label="TerminalActionCodeOnline" name={["JCBTag", "DF8122"]}>
                  <Input />
                </Form.Item>
              </Col>
            </Row>
          </>
        ) : null}
        {showTags["discover"] ? (
          <>
            {customAlert(t("title.discover_tags"))}
            <Row gutter={30}>
              <Col flex="300px" hidden={["02", "04", "05", "07"].includes(formValues?.base.kernelId || "")}>
                <Form.Item label="TerminalTransactionQualifier" name={["discoverTag", "9F66"]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px" hidden={["02", "04", "05", "07"].includes(formValues?.base.kernelId || "")}>
                <Form.Item label="DPasStatusCheckSupportFlag" name={["discoverTag", "DFAF32"]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px" hidden={["02", "04", "05", "07"].includes(formValues?.base.kernelId || "")}>
                <Form.Item label="DPasZeroAmountAllowedFlag" name={["discoverTag", "DFAF33"]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px" hidden={["02", "04", "05", "07"].includes(formValues?.base.kernelId || "")}>
                <Form.Item label="DPasReaderContactlessTransactionLimit" name={["discoverTag", "DFAF34"]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px" hidden={["02", "04", "05", "07"].includes(formValues?.base.kernelId || "")}>
                <Form.Item label="DPasReaderContactlessFloorLimit" name={["discoverTag", "DFAF35"]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px" hidden={["02", "04", "05", "07"].includes(formValues?.base.kernelId || "")}>
                <Form.Item label="DPasReaderCvmRequiredLimit" name={["discoverTag", "DFAF36"]}>
                  <Input />
                </Form.Item>
              </Col>
            </Row>
          </>
        ) : null}
        {showTags["cup"] ? (
          <>
            {customAlert(t("title.china_tags"))}
            <Row gutter={30}>
              <Col flex="300px" hidden={["02", "04", "05", "06"].includes(formValues?.base.kernelId || "")}>
                <Form.Item label="TerminalTransactionQualifiers" name={["CUPTag", "9F66"]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px" hidden={["02", "04", "05", "06"].includes(formValues?.base.kernelId || "")}>
                <Form.Item label="ReaderContactlessTransactionLimit" name={["CUPTag", "DF00"]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px" hidden={["02", "04", "05", "06"].includes(formValues?.base.kernelId || "")}>
                <Form.Item label="ReaderCvmRequiredLimit" name={["CUPTag", "DF01"]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px" hidden={["02", "04", "05", "06"].includes(formValues?.base.kernelId || "")}>
                <Form.Item label="ReaderContactlessFloorLimit" name={["CUPTag", "DF02"]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px" hidden={["02", "04", "05", "06"].includes(formValues?.base.kernelId || "")}>
                <Form.Item label="OnlineOdaParameter" name={["CUPTag", "DF9F0B"]}>
                  <Input />
                </Form.Item>
              </Col>
            </Row>
          </>
        ) : null}
      </Form>
      <Divider />
      <div>
        <Button
          type="primary"
          shape="round"
          style={{ width: "200px", float: "right", marginRight: "20px" }}
          htmlType="submit"
          loading={btnLoading}
          onClick={() => form.submit()}
        >
          {t("button.save")}
        </Button>
      </div>
    </>
  );
};
