import i18n from "i18next";
import {initReactI18next} from "react-i18next";

import enUS from '../../locales/en-us.json'
import zhTW from '../../locales/zh-tw.json'

const resources = {
    enUS: {
        translation: enUS
    },
    zhTW: {
        translation: zhTW
    }
}

i18n.use(initReactI18next)
    .init({
        resources,
        fallbackLng: "enUS",

        interpolation: {
            escapeValue: false // react already safes from xss
        },
        detection: {
            caches: ['localStorage', 'sessionStorage', 'cookie'],
        }
    });

export default i18n;