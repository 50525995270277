import React, { FC, useEffect, useState } from "react";
import {Alert, Button, Row, Upload, notification, Modal} from "antd";
import { getToken } from "../../util/cookie";
import { UploadFile } from "antd/lib/upload/interface";
import { lastLoadDateTime,deleteDefaultFile } from "../../services/systemSetting/request";
export const SystemSetting: FC = () => {
  const [fileList, setFileList] = useState<Array<UploadFile>>([]);
  const [lastUploadDate, setLastUploadDate] = useState<string>("");
  const uploadUrl = "/api/upload/default";

  useEffect(function () {
    getLastLoadDateTime();
  }, []);

  const getLastLoadDateTime = () => {
    lastLoadDateTime().then((res) => {
      if (res.status === 0) {
        if (res.data.date===""){
          setLastUploadDate("--");
        }else{
          setLastUploadDate(res.data.date);
        }
      }
    });
  };

  const clickDeleteDefaultFile = () => {
    Modal.confirm({
      title: "重要警告",
      content: "您的操作將會删除保存的默認檔案配寘，數據將使用程式碼中默認數據，請謹慎確認！",
      onOk: () => postDeleteDefaultFile(),
      okText: "Yes",
      maskClosable: false,
      closable: false,
    });
  }
  const postDeleteDefaultFile = () => {
    deleteDefaultFile().then(res=>{
      if (res.status===0){
        notification.success({message:res.msg})
        getLastLoadDateTime()
      }
    })
  }
  return (
    <div style={{ height: "100%" }}>
      <Row gutter={10} style={{ justifyContent: "center" }}>
        <div className="form-content-container-content" style={{ maxWidth: "960px" }}>
          <Alert
            style={{ marginBottom: "20px" }}
            message={
              <div style={{ height: "40px", lineHeight: "40px", fontSize: "18px", fontWeight: "bold" }}>
                Default Data <span style={{ fontSize: "14px", color: "red" }}>Updated At: {lastUploadDate}</span>
              </div>
            }
            type="info"
          />
          <div style={{display:"flex",gap:"20px"}}>
            <Upload
              action={uploadUrl}
              headers={{ Authorization: getToken() }}
              onChange={(info) => {
                if (info.file.status === "removed") {
                  setFileList([]);
                } else {
                  setFileList([info.file]);
                }
                if (info.file.status === "done") {
                  if (info.file.response.status === 0) {
                    info.file.url = info.file.response.data.url;
                    setFileList([info.file]);
                    notification.success({message:info.file.response.msg})
                    getLastLoadDateTime()
                  } else {
                    notification.error({ message: info.file.response.msg });
                  }
                }
                if (info.file.status === "error") {
                  notification.error({ message: `${info.file.name} file upload failed.` });
                }
              }}
              onRemove={() => {
                setFileList([]);
              }}
              accept={".zip"}
              fileList={fileList}
              maxCount={1}
              showUploadList={false}
            >
              <Button>Upload</Button>
            </Upload>
            <Button type={"primary"} onClick={clickDeleteDefaultFile}>Delete</Button>
          </div>
        </div>
      </Row>
    </div>
  );
};
