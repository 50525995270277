export const dict: Record<string, number> = {
  "9F09": 4,
  "9F33": 6,
  "9C": 2,
  "9F40": 10,
  "9F1A": 4,
  "5F2A": 4,
  "DFC8": 10,
  "DFC7": 10,
  "DFC6": 10,
  "DFC0": 6,
  "DFC1": 6,
  "9F1B": 8,
  "DFC4": 8,
  "DFC2": 2,
  "DFC3": 2,
  "9F15": 4,
  "9F1E": 16,
  "9F16": 30,
  "9F1C": 16,
  "9F35": 2,
  "5F57": 2,
  "9F01": 12,
  "DF9F01": 2,
  "FF41": 4,
  "DF9F03": 2,
  "DF9F16": 2,
  "DF9F04": 4,
  "DF9F05": 4,
  "DF9F1A": 2,
  "DF9F1C": 2,
  "DF9F1D": 2,
  "DF9F1E": 10,
  "DF9F1F": 2,
  "DF9F20": 2,
  "9F66": 8,
  "DF00": 12,
  "DF01": 12,
  "DF02": 12,
  "DF05": 2,
  "DF25": 2,
  "DF8F4B": 2,
  "DF20": 2,
  "DF21": 2,
  "DF22": 2,
  "DF29": 2,
  "DF24": 2,
  "DF27": 2,
  "DF8117": 2,
  "DF8118": 2,
  "DF8119": 2,
  "DF811A": 6,
  "DF811B": 2,
  "DF810C": 2,
  "9F6D": 4,
  "DF811E": 2,
  "DF812C": 2,
  "DF811C": 4,
  "DF811D": 2,
  "DF8123": 12,
  "DF8124": 12,
  "DF8125": 12,
  "DF8126": 12,
  "DF811F": 2,
  "DF8120": 10,
  "DF8121": 10,
  "DF8122": 10,
  "9F1D": 16,
  "DF8132": 4,
  "DF8133": 4,
  "DF8134": 4,
  "DF8135": 4,
  "DF8136": 4,
  "DF8137": 2,
  "DF51": 12,
  "DF57": 2,
  "DF58": 2,
  "DF59": 4,
  "DF50": 12,
  "DF53": 12,
  "DF52": 12,
  "DF9F50": 2,
  "DF9F51": 2,
  "DF9F53": 36,
  "DF9F57": 2,
  "DF68": 4,
  "9F52": 2,
  "9F53": 6,
  "DF8F42": 2,
  "DF8F43": 2,
  "DF8F44": 8,
  "DFAF32": 2,
  "DFAF33": 2,
  "DFAF34": 12,
  "DFAF35": 12,
  "DFAF36": 12,
  "DF9F0B": 8
}