import React, { FC, useState } from "react";
import { Button, Col, Divider, Form, Input, notification, Radio, Row } from "antd";
import { useTranslation } from "react-i18next";
import {useLocation, useNavigate} from "react-router-dom";
import { createItem, IItemResponse } from "../../../../services/cardpool/amex";

interface IProps {
  terminalId: string;
}

export const TerminalPrmCPAmexCreateItem: FC<IProps> = ({ terminalId }) => {
  const { t } = useTranslation();
  const navigator = useNavigate();
  const location = useLocation()
  const [form] = Form.useForm();
  let initialValues: IItemResponse = {
    item: {
      IssuerName: "",
      AcquirerName: "",
      CardNumMaskTypeMerchant: 0,
      CardNumMaskTypeCustomer: 0,
      StartBinRange: "",
      EndBinRange: "",
      AdjustPercent: 0,
      TipsPercent: 0,
      MinPanLength: 0,
      MaxPanLength: 0,
      AmexSaleFlag: true,
      AmexVoidFlag: true,
      AmexRefundFlag: true,
      AmexSettlementFlag: true,
      AmexAdjustFlag: true,
      AmexTipsFlag: true,
      AmexOfflineSaleFlag: true,
      AmexPreAuthFlag: true,
      CallBankFlag: true,
      ManualKeyinFlag: true,
      CheckExpiredDateFlag: true,
      ManualExpiryDateFlag: true,
      ManualCheckSumFlag: true,
      CheckModule10Flag: true,
      RefundManualCheckSumFlag: true,
      Amex4DbcFlag: false,
    },
    terminalId: "",
  };
  if (location.state){
    initialValues=location.state as IItemResponse
  }
  const [formValues] = useState<IItemResponse>(initialValues);
  const [btnLoading, setBtnLoading] = useState<boolean>(false);
  const saveForm = (values) => {
    setBtnLoading(true);
    createItem({
      terminalId: terminalId,
      item: values["item"],
    })
      .then((res) => {
        if (res.status === 0) {
          notification.success({
            message: "save success",
          });
          navigator("/terminal-prm/acquirer/amex");
        }
      })
      .finally(() => {
        setBtnLoading(false);
      });
  };
  return (
    <>
      <Form layout="vertical" form={form} initialValues={formValues} onFinish={saveForm}>
        <Row gutter={30}>
          <Col flex="300px">
            <Form.Item label={t("form.IssuerName")} name={["item", "IssuerName"]}>
              <Input />
            </Form.Item>
          </Col>
          <Col flex="300px">
            <Form.Item label={t("form.AcquirerName")} name={["item", "AcquirerName"]}>
              <Input />
            </Form.Item>
          </Col>
          <Col flex="300px">
            <Form.Item
              label={t("form.CardNumMaskTypeMerchant")}
              name={["item", "CardNumMaskTypeMerchant"]}
              normalize={(value) => Number(value)}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col flex="300px">
            <Form.Item
              label={t("form.CardNumMaskTypeCustomer")}
              name={["item", "CardNumMaskTypeCustomer"]}
              normalize={(value) => Number(value)}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col flex="300px">
            <Form.Item label={t("form.StartBinRange")} name={["item", "StartBinRange"]}>
              <Input />
            </Form.Item>
          </Col>
          <Col flex="300px">
            <Form.Item label={t("form.EndBinRange")} name={["item", "EndBinRange"]}>
              <Input />
            </Form.Item>
          </Col>
          <Col flex="300px">
            <Form.Item
              label={t("form.AdjustPercent")}
              name={["item", "AdjustPercent"]}
              normalize={(value) => Number(value)}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col flex="300px">
            <Form.Item
              label={t("form.TipsPercent")}
              name={["item", "TipsPercent"]}
              normalize={(value) => Number(value)}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col flex="300px">
            <Form.Item
              label={t("form.MinPanLength")}
              name={["item", "MinPanLength"]}
              normalize={(value) => Number(value)}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col flex="300px">
            <Form.Item
              label={t("form.MaxPanLength")}
              name={["item", "MaxPanLength"]}
              normalize={(value) => Number(value)}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col flex="300px">
            <Form.Item label={t("form.AmexSaleFlag")} name={["item", "AmexSaleFlag"]}>
              <Radio.Group>
                <Radio value={true}>True</Radio>
                <Radio value={false}>False</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col flex="300px">
            <Form.Item label={t("form.AmexVoidFlag")} name={["item", "AmexVoidFlag"]}>
              <Radio.Group>
                <Radio value={true}>True</Radio>
                <Radio value={false}>False</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col flex="300px">
            <Form.Item label={t("form.AmexRefundFlag")} name={["item", "AmexRefundFlag"]}>
              <Radio.Group>
                <Radio value={true}>True</Radio>
                <Radio value={false}>False</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col flex="300px">
            <Form.Item label={t("form.AmexSettlementFlag")} name={["item", "AmexSettlementFlag"]}>
              <Radio.Group>
                <Radio value={true}>True</Radio>
                <Radio value={false}>False</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col flex="300px">
            <Form.Item label={t("form.AmexAdjustFlag")} name={["item", "AmexAdjustFlag"]}>
              <Radio.Group>
                <Radio value={true}>True</Radio>
                <Radio value={false}>False</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col flex="300px">
            <Form.Item label={t("form.AmexTipsFlag")} name={["item", "AmexTipsFlag"]}>
              <Radio.Group>
                <Radio value={true}>True</Radio>
                <Radio value={false}>False</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col flex="300px">
            <Form.Item label={t("form.AmexOfflineSaleFlag")} name={["item", "AmexOfflineSaleFlag"]}>
              <Radio.Group>
                <Radio value={true}>True</Radio>
                <Radio value={false}>False</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col flex="300px">
            <Form.Item label={t("form.AmexPreAuthFlag")} name={["item", "AmexPreAuthFlag"]}>
              <Radio.Group>
                <Radio value={true}>True</Radio>
                <Radio value={false}>False</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col flex="300px">
            <Form.Item label={t("form.CallBankFlag")} name={["item", "CallBankFlag"]}>
              <Radio.Group>
                <Radio value={true}>True</Radio>
                <Radio value={false}>False</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col flex="300px">
            <Form.Item label={t("form.ManualKeyinFlag")} name={["item", "ManualKeyinFlag"]}>
              <Radio.Group>
                <Radio value={true}>True</Radio>
                <Radio value={false}>False</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col flex="300px">
            <Form.Item label={t("form.CheckExpiredDateFlag")} name={["item", "CheckExpiredDateFlag"]}>
              <Radio.Group>
                <Radio value={true}>True</Radio>
                <Radio value={false}>False</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col flex="300px">
            <Form.Item label={t("form.ManualExpiryDateFlag")} name={["item", "ManualExpiryDateFlag"]}>
              <Radio.Group>
                <Radio value={true}>True</Radio>
                <Radio value={false}>False</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col flex="300px">
            <Form.Item label={t("form.ManualCheckSumFlag")} name={["item", "ManualCheckSumFlag"]}>
              <Radio.Group>
                <Radio value={true}>True</Radio>
                <Radio value={false}>False</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col flex="300px">
            <Form.Item label={t("form.CheckModule10Flag")} name={["item", "CheckModule10Flag"]}>
              <Radio.Group>
                <Radio value={true}>True</Radio>
                <Radio value={false}>False</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col flex="300px">
            <Form.Item label={t("form.RefundManualCheckSumFlag")} name={["item", "RefundManualCheckSumFlag"]}>
              <Radio.Group>
                <Radio value={true}>True</Radio>
                <Radio value={false}>False</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col flex="300px">
            <Form.Item label={t("form.Amex4DbcFlag")} name={["item", "Amex4DbcFlag"]}>
              <Radio.Group>
                <Radio value={true}>True</Radio>
                <Radio value={false}>False</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Divider />
      <div>
        <Button
          type="primary"
          shape="round"
          style={{ width: "200px", float: "right", marginRight:"20px" }}
          htmlType="submit"
          loading={btnLoading}
          onClick={() => form.submit()}
        >
          {t("button.save")}
        </Button>
      </div>
    </>
  );
};
