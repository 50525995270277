import React, {FC, useEffect, useState} from "react";
import {Button, Col, Divider, Form, Input, InputNumber, notification, Radio, Row, Select} from "antd";
import {useTranslation} from "react-i18next";
import {
    ITerminalPRMRInfoGPCustomization,
    saveTerminalPrmInfoGPCustomization,
    terminalPrmInfoGPCustomization,
} from "../../../services/ap/gpcustomization";

interface IProps {
    terminalId: string;
}

export const TerminalPrmAPGPCustomization: FC<IProps> = ({terminalId}) => {
    const {t} = useTranslation();
    const [form] = Form.useForm();
    const [formValues, setFormValues] = useState<ITerminalPRMRInfoGPCustomization>();
    const [btnLoading, setBtnLoading] = useState<boolean>(false);
    const [currentId, setCurrentId] = useState<number>(0)
    useEffect(
        function () {
            if (terminalId !== "") {
                terminalPrmInfoGPCustomization({terminal_id: terminalId}).then((res) => {
                    if (res.status === 0) {
                        setCurrentId(res.data.GPCustomization.gp_CustomizedId)
                        setFormValues(res.data);
                        form.setFieldsValue(res.data);
                    }
                });
            }
        },
        [terminalId]
    );
    const saveForm = (values) => {
        if (formValues === undefined) {
            return;
        }
        setBtnLoading(true);
        saveTerminalPrmInfoGPCustomization({terminalId: terminalId, GPCustomization: values["GPCustomization"]})
            .then((res) => {
                if (res.status === 0) {
                    notification.success({
                        message: "save success",
                    });
                }
            })
            .finally(() => {
                setBtnLoading(false);
            });
    };
    const changeId = (id: number) => {
        setCurrentId(id)
    }

    return (
        <Row gutter={10} style={{marginTop: "20px"}} hidden={terminalId === ""}>
            <div className="form-content-container-content">
                <Form layout="vertical" form={form} initialValues={formValues} onFinish={saveForm}>
                    <Row gutter={30}>
                        <Col flex="300px">
                            <Form.Item label={t('form.gp_CustomizedId')} name={["GPCustomization", "gp_CustomizedId"]}>
                                <Select onChange={changeId}>
                                    <Select.Option value={0}>{t('text.default')}</Select.Option>
                                    <Select.Option value={1}>01 {t('text.metro_taxi')} (#51973 單筆交易額度上限
                                        5000)</Select.Option>
                                    <Select.Option value={2}>02 {t('text.sisley')} (#51885
                                        不開結帳，要清機)</Select.Option>
                                    <Select.Option value={3}>03 {t('text.shopline')} (#52302 ECR)</Select.Option>
                                    <Select.Option value={4}>04 POS Manual</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    {
                        currentId === 1 ? <Row gutter={30}>
                            <Col flex="300px">
                                <Form.Item label={t("form.gp_MetroTaxi_MaxAmount")}
                                           name={["GPCustomization", "1", "gp_MetroTaxi_MaxAmount"]}
                                           normalize={(value) => Number(value)}
                                           rules={[{
                                               required: true,
                                               type: 'number',
                                               min: 1,
                                               max: 99999999,
                                               message: "1-99999999"
                                           }]}>
                                    <InputNumber min={1} max={99999999} controls={false} precision={0}
                                                 style={{width: '100%'}}/>
                                </Form.Item>
                            </Col>
                        </Row> : null
                    }
                    {
                        currentId === 2 ? <Row gutter={30}>
                            <Col flex="300px">
                                <Form.Item label={t("form.gp_Sisley_MaxTxnCount")}
                                           name={["GPCustomization", "2", "gp_Sisley_MaxTxnCount"]}
                                           normalize={(value) => Number(value)}
                                           rules={[{
                                               required: true,
                                               type: 'number',
                                               min: 1000,
                                               max: 9999,
                                               message: "1000-9999"
                                           }]}>
                                    <InputNumber min={1000} max={9999} precision={0} controls={false}
                                                 style={{width: "100%"}}/>
                                </Form.Item>
                            </Col>
                            <Col flex="300px">
                                <Form.Item label={t("form.gp_Sisley_DeleteBatchEnableLevel")}
                                           name={["GPCustomization", "2", "gp_Sisley_DeleteBatchEnableLevel"]}
                                >
                                    <Radio.Group>
                                        <Radio value={true}>Yes</Radio>
                                        <Radio value={false}>No</Radio>
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                            <Col flex="300px">
                                <Form.Item label={t("form.gp_Sisley_DeleteBatchBroadcast")}
                                           name={["GPCustomization", "2", "gp_Sisley_DeleteBatchBroadcast"]}
                                >
                                    <Radio.Group>
                                        <Radio value={true}>Yes</Radio>
                                        <Radio value={false}>No</Radio>
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                            <Col flex="300px">
                                <Form.Item label={t("form.gp_Sisley_NumberOfDetails")}
                                           name={["GPCustomization", "2", "gp_Sisley_NumberOfDetails"]}
                                >
                                    <Select>
                                        <Select.Option value={25}>25</Select.Option>
                                        <Select.Option value={50}>50</Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col flex="300px">
                                <Form.Item label={t("form.gp_Sisley_OfflineTxn")}
                                           name={["GPCustomization", "2", "gp_Sisley_OfflineTxn"]}
                                >
                                    <Radio.Group>
                                        <Radio value={true}>Yes</Radio>
                                        <Radio value={false}>No</Radio>
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                        </Row> : null
                    }
                    {
                        currentId === 3 ? <Row gutter={30}>
                            <Col flex="300px">
                                <Form.Item label={t("form.gp_Shopline_TxnResultEvent")}
                                           name={["GPCustomization", "3", "gp_Shopline_TxnResultEvent"]}
                                >
                                    <Radio.Group>
                                        <Radio value={true}>Yes</Radio>
                                        <Radio value={false}>No</Radio>
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                            <Col flex="300px">
                                <Form.Item label={t("form.gp_Shopline_SettlementPassword")}
                                           name={["GPCustomization", "3", "gp_Shopline_SettlementPassword"]}
                                           rules={[{
                                               required: true,
                                               pattern: new RegExp(/^[0-9]{6}$/),
                                               message: "000000-999999"
                                           }]}>
                                    <Input/>
                                </Form.Item>
                            </Col>
                            <Col flex="300px">
                                <Form.Item label={t("form.gp_Shopline_CheckData")}
                                           name={["GPCustomization", "3", "gp_Shopline_CheckData"]}
                                >
                                    <Radio.Group>
                                        <Radio value={true}>Yes</Radio>
                                        <Radio value={false}>No</Radio>
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                        </Row> : null
                    }
                    {
                        currentId === 4 ? <Row gutter={30}></Row>:null
                    }
                </Form>
                <Divider/>
                <div>
                    <Button
                        type="primary"
                        shape="round"
                        style={{width: "200px", float: "right"}}
                        htmlType="submit"
                        loading={btnLoading}
                        onClick={() => form.submit()}
                    >
                        {t("button.save")}
                    </Button>
                </div>
            </div>
        </Row>
    );
};
