import {get, post} from "../../util/request";
import {
  TerminalPrmInfoRequest
} from "../terminalPrm";

export interface ITerminalPRMRInfoOnusCfg {
  id: string;
  terminalId: string;
  onus: Onus.IOnus;
}

export interface ITerminalPRMRInfoOnusData {
  id: string;
  terminalId: string;
  data: string;
}

export interface ITerminalPRMRInfoDataResponse {
  url: string;
}

export const terminalPrmInfoOnusCfg = (params: TerminalPrmInfoRequest) => {
  return get<ITerminalPRMRInfoOnusCfg>({url: "/api/terminal-prm/info/onus/cfg", params})
}

export const saveTerminalPrmInfoOnusCfg = (data: ITerminalPRMRInfoOnusCfg) => {
  return post({url: "/api/terminal-prm/info/onus/cfg", data})
}

export const terminalPrmInfoOnusData = (params: TerminalPrmInfoRequest) => {
  return get<ITerminalPRMRInfoOnusData>({url: "/api/terminal-prm/info/onus/data", params})
}

export const saveTerminalPrmInfoOnusData = (data: ITerminalPRMRInfoOnusData) => {
  return post<ITerminalPRMRInfoDataResponse>({url: "/api/terminal-prm/info/onus/data", data})
}
