import React, {FC, useEffect, useState} from "react";
import {Button, Col, Divider, Form, Input, notification, Row} from "antd";
import {useTranslation} from "react-i18next";
import {
  ITerminalPRMRInfoCyberSource,
  saveTerminalPrmInfoCyberSource,
  terminalPrmInfoCyberSource
} from "../../../services/terminalPrm";

interface IProps {
  terminalId: string;
}

export const TerminalPrmCyberSource: FC<IProps> = ({terminalId}) => {
  const {t} = useTranslation()
  const [form] = Form.useForm()
  const [formValues, setFormValues] = useState<ITerminalPRMRInfoCyberSource>();
  const [btnLoading, setBtnLoading] = useState<boolean>(false)
  useEffect(function () {
    if (terminalId !== "") {
      terminalPrmInfoCyberSource({terminal_id: terminalId}).then(res => {
        if (res.status === 0) {
          setFormValues(res.data)
          form.setFieldsValue(res.data)
        }
      })
    }
  }, [terminalId])
  const saveForm = (values) => {
    if (formValues === undefined) {
      return
    }
    setBtnLoading(true)
    saveTerminalPrmInfoCyberSource({
      id: formValues['id'],
      terminalId: terminalId,
      cybersource: values['cybersource']
    }).then(res => {
      if (res.status === 0) {
        notification.success({
          message: "save success"
        })
      }
    }).finally(() => {
      setBtnLoading(false)
    })
  }
  return (
    <Row gutter={10} style={{marginTop: "20px"}} hidden={terminalId === ""}>
      <div className='form-content-container-content'>
        <Form layout='vertical' form={form} initialValues={formValues} onFinish={saveForm}>
          <Row gutter={30}>
            <Col flex="300px">
              <Form.Item label={t('form.TerminalId')} name={['cybersource', 'TerminalId']}>
                <Input/>
              </Form.Item>
            </Col>
            <Col flex="300px">
              <Form.Item label={t('form.CardNumMaskCnt')} name={['cybersource', 'CardNumMaskCnt']}>
                <Input/>
              </Form.Item>
            </Col>
            <Col flex="300px">
              <Form.Item label='CardNumMaskOffset' name={['cybersource', 'CardNumMaskOffset']}>
                <Input/>
              </Form.Item>
            </Col>
            <Col flex="300px">
              <Form.Item label='AuthURL' name={['cybersource', 'AuthURL']}>
                <Input/>
              </Form.Item>
            </Col>
            <Col flex="300px">
              <Form.Item label='CaptureURL' name={['cybersource', 'CaptureURL']}>
                <Input/>
              </Form.Item>
            </Col>
            <Col flex="300px">
              <Form.Item label='ReversalURL' name={['cybersource', 'ReversalURL']}>
                <Input/>
              </Form.Item>
            </Col>
            <Col flex="300px">
              <Form.Item label='SaleURL' name={['cybersource', 'SaleURL']}>
                <Input/>
              </Form.Item>
            </Col>
            <Col flex="300px">
              <Form.Item label='RefundAuthURL' name={['cybersource', 'RefundAuthURL']}>
                <Input/>
              </Form.Item>
            </Col>
            <Col flex="300px">
              <Form.Item label='RefundCaptureURL' name={['cybersource', 'RefundCaptureURL']}>
                <Input/>
              </Form.Item>
            </Col>
            <Col flex="300px">
              <Form.Item label='VoidAuthURL' name={['cybersource', 'VoidAuthURL']}>
                <Input/>
              </Form.Item>
            </Col>
            <Col flex="300px">
              <Form.Item label='VoidCaptureURL' name={['cybersource', 'VoidCaptureURL']}>
                <Input/>
              </Form.Item>
            </Col>
            <Col flex="300px">
              <Form.Item label='VoidRefundURL' name={['cybersource', 'VoidRefundURL']}>
                <Input/>
              </Form.Item>
            </Col>
            <Col flex="300px">
              <Form.Item label='TxnSearchURL' name={['cybersource', 'TxnSearchURL']}>
                <Input/>
              </Form.Item>
            </Col>
            <Col flex="300px">
              <Form.Item label='Host' name={['cybersource', 'Host']}>
                <Input/>
              </Form.Item>
            </Col>
            <Col flex="300px">
              <Form.Item label='Content-Type' name={['cybersource', 'Content-Type']}>
                <Input/>
              </Form.Item>
            </Col>
            <Col flex="300px">
              <Form.Item label={t('form.MerchantId')} name={['cybersource', 'MerchantId']}>
                <Input/>
              </Form.Item>
            </Col>
            <Col flex="300px">
              <Form.Item label='MerchantDeviceType' name={['cybersource', 'MerchantDeviceType']}>
                <Input/>
              </Form.Item>
            </Col>
            <Col flex="300px">
              <Form.Item label='Key' name={['cybersource', 'Key']}>
                <Input/>
              </Form.Item>
            </Col>
            <Col flex="300px">
              <Form.Item label='ShareSecretKey' name={['cybersource', 'ShareSecretKey']}>
                <Input/>
              </Form.Item>
            </Col>
            <Col flex="300px">
              <Form.Item label='EdcTimeZone' name={['cybersource', 'EdcTimeZone']}>
                <Input/>
              </Form.Item>
            </Col>
            <Col flex="300px">
              <Form.Item label='PollCardTimeout' name={['cybersource', 'PollCardTimeout']}>
                <Input/>
              </Form.Item>
            </Col>
            <Col flex="300px">
              <Form.Item label='ServerTxTimeout' name={['cybersource', 'ServerTxTimeout']}>
                <Input/>
              </Form.Item>
            </Col>
            <Col flex="300px">
              <Form.Item label='ServerRxTimeout' name={['cybersource', 'ServerRxTimeout']}>
                <Input/>
              </Form.Item>
            </Col>
            <Col flex="300px">
              <Form.Item label='DebugLogFlag' name={['cybersource', 'DebugLogFlag']}>
                <Input/>
              </Form.Item>
            </Col>
            <Col flex="300px">
              <Form.Item label='DebugLogMaxSize' name={['cybersource', 'DebugLogMaxSize']}>
                <Input/>
              </Form.Item>
            </Col>
            <Col flex="300px">
              <Form.Item label='DebugLogMaxDays' name={['cybersource', 'DebugLogMaxDays']}>
                <Input/>
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <Divider/>
        <div>
          <Button type="primary" shape="round" style={{width: "200px", float: "right"}}
                  htmlType="submit" loading={btnLoading} onClick={() => form.submit()}>
            {t('button.save')}
          </Button>
        </div>
      </div>
    </Row>
  )
}