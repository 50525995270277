import React, {FC, useState} from "react";
import {Button, Col, Divider, Form, Input, notification, Radio, Row} from "antd";
import {useTranslation} from "react-i18next";
import {useLocation, useNavigate} from "react-router-dom";
import {createItem, IItemResponse} from "../../../../services/cardpool/smartyPay";

interface IProps {
  terminalId: string;
}

export const TerminalPrmCPSmartyPayCreateItem: FC<IProps> = ({terminalId}) => {
  const {t} = useTranslation()
  const navigator = useNavigate()
  const location = useLocation()
  const [form] = Form.useForm()
  let initialValues: IItemResponse = {
    item: {
      IssuerName: "",
      AcquirerName: "",
      CardNumMaskTypeMerchant: 0,
      CardNumMaskTypeCustomer: 0,
      SmartPaySCEdcType: "",
      SmartPayCLEdcType: "",
      StartBinRange: "",
      EndBinRange: "",
      SmartPaySaleRateMode: 0,
      MinPanLength: 0,
      MaxPanLength: 0,
      SmartPaySaleFlag: true,
      SmartPayVoidFlag: true,
      SmartPayRefundFlag: true,
      SmartPaySettlementFlag: true
    }, terminalId: ""
  }
  if (location.state){
    initialValues=location.state as IItemResponse
  }
  const [formValues] = useState<IItemResponse>(initialValues);
  const [btnLoading, setBtnLoading] = useState<boolean>(false)
  const saveForm = (values) => {
    setBtnLoading(true)
    createItem({
      terminalId: terminalId,
      item: values['item'],
    }).then(res => {
      if (res.status === 0) {
        notification.success({
          message: "save success"
        })
        navigator('/terminal-prm/acquirer/smartypay')
      }
    }).finally(() => {
      setBtnLoading(false)
    })
  }
  return (
    <>
      <Form layout='vertical' form={form} initialValues={formValues} onFinish={saveForm}>
        <Row gutter={30}>
          <Col flex="300px">
            <Form.Item label={t('form.IssuerName')} name={['item', 'IssuerName']}>
              <Input/>
            </Form.Item>
          </Col>
          <Col flex="300px">
            <Form.Item label={t('form.AcquirerName')} name={['item', 'AcquirerName']}>
              <Input/>
            </Form.Item>
          </Col>
          <Col flex="300px">
            <Form.Item label={t('form.CardNumMaskTypeMerchant')} name={['item', 'CardNumMaskTypeMerchant']}
                       normalize={(value) => Number(value)}>
              <Input/>
            </Form.Item>
          </Col>
          <Col flex="300px">
            <Form.Item label={t('form.CardNumMaskTypeCustomer')} name={['item', 'CardNumMaskTypeCustomer']}
                       normalize={(value) => Number(value)}>
              <Input/>
            </Form.Item>
          </Col>
          <Col flex="300px">
            <Form.Item label={t('form.SmartPaySCEdcType')} name={['item', 'SmartPaySCEdcType']}>
              <Input/>
            </Form.Item>
          </Col>
          <Col flex="300px">
            <Form.Item label={t('form.SmartPayCLEdcType')} name={['item', 'SmartPayCLEdcType']}>
              <Input/>
            </Form.Item>
          </Col>
          <Col flex="300px">
            <Form.Item label={t('form.StartBinRange')} name={['item', 'StartBinRange']}>
              <Input/>
            </Form.Item>
          </Col>
          <Col flex="300px">
            <Form.Item label={t('form.EndBinRange')} name={['item', 'EndBinRange']}>
              <Input/>
            </Form.Item>
          </Col>
          <Col flex="300px">
            <Form.Item label={t('form.SmartPaySaleRateMode')} name={['item', 'SmartPaySaleRateMode']}
                       normalize={(value) => Number(value)}>
              <Input/>
            </Form.Item>
          </Col>
          <Col flex="300px">
            <Form.Item label={t('form.MinPanLength')} name={['item', 'MinPanLength']}
                       normalize={(value) => Number(value)}>
              <Input disabled/>
            </Form.Item>
          </Col>
          <Col flex="300px">
            <Form.Item label={t('form.MaxPanLength')} name={['item', 'MaxPanLength']}
                       normalize={(value) => Number(value)}>
              <Input/>
            </Form.Item>
          </Col>
          <Col flex="300px">
            <Form.Item label={t('form.SmartPaySaleFlag')} name={['item', 'SmartPaySaleFlag']}>
              <Radio.Group>
                <Radio value={true}>True</Radio>
                <Radio value={false}>False</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col flex="300px">
            <Form.Item label={t('form.SmartPayVoidFlag')} name={['item', 'SmartPayVoidFlag']}>
              <Radio.Group>
                <Radio value={true}>True</Radio>
                <Radio value={false}>False</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col flex="300px">
            <Form.Item label={t('form.SmartPayRefundFlag')} name={['item', 'SmartPayRefundFlag']}>
              <Radio.Group>
                <Radio value={true}>True</Radio>
                <Radio value={false}>False</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col flex="300px">
            <Form.Item label={t('form.SmartPaySettlementFlag')}
                       name={['item', 'SmartPaySettlementFlag']}>
              <Radio.Group>
                <Radio value={true}>True</Radio>
                <Radio value={false}>False</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Divider/>
      <div>
        <Button type="primary" shape="round" style={{width: "200px", float: "right"}}
                htmlType="submit" loading={btnLoading} onClick={() => form.submit()}>
          {t('button.save')}
        </Button>
      </div>
    </>
  )
}