import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {Form, Input, Button, Select} from 'antd';
import {MailOutlined, LockOutlined} from '@ant-design/icons';
import i18n from "../../util/i18n";
import './login.css'
import {login, verifyEmail} from "../../services/login";
import {useNavigate} from "react-router-dom";
import {onLogin} from "../../util/cookie";

const {Option} = Select;

type Props = {};
export const Login = (props: Props) => {
    const navigator = useNavigate()
    const {t} = useTranslation()
    const [defaultLang, setDefaultLang] = useState("")
    const [loginStep, setLoginStep] = useState(1)
    const [needMfa, setNeedMfa] = useState(0)
    const [email, setEmail] = useState("")
    const [btnLoading, setBtnLoading] = useState(false)
    useEffect(function () {
        const savedLang = localStorage.getItem("Lang") || "enUS"
        localStorage.setItem("Lang", savedLang)
        document.title = t('title.login') + ' - CardPool App Manager'
        setDefaultLang(savedLang)
        i18n.changeLanguage(savedLang).then()
    }, [])
    const onFinish = (values: any) => {
        setBtnLoading(true)
        login(values).then(res => {
            if (res.status === 0) {
                onLogin(res.data)
                navigator('/')
            }
        }).finally(() => {
            setBtnLoading(false)
        })
    };
    const loginFirst = () => {
        setBtnLoading(true)
        verifyEmail({email: email}).then(res => {
            if (res.status === 0) {
                setNeedMfa(res.data.mfa)
                setLoginStep(2)
            }
        }).finally(() => {
            setBtnLoading(false)
        })
    }

    const changeLang = (value: string) => {
        i18n.changeLanguage(value).then()
        localStorage.setItem("Lang", value)
        setDefaultLang(value)
        document.title = t('title.login') + ' - CardPool App Manager'
    }
    return (
        <div className='login-box'>
            <div className="login-box-bg"/>
            <div className="login-box-content">
                <h1 className="form-login-title">{t('title.login')}</h1>
                <div className='form-login-language'>
                    <Select value={defaultLang} size="large" onChange={changeLang}>
                        <Option value="zhTW">中文繁体</Option>
                        <Option value="enUS">English</Option>
                    </Select>
                </div>
                <Form
                    name="normal_login"
                    className="login-form"
                    initialValues={{remember: true}}
                    onFinish={onFinish}
                    autoComplete="off"
                >

                    <Form.Item
                        name="email"
                        rules={[{required: true, message: t('rules.required')},
                            {type: "email", message: t('rules.email')}]}
                        hidden={loginStep === 2}
                    >
                        <Input prefix={<MailOutlined/>} size='large' value={email}
                               placeholder={t('placeholder.email')} onChange={(e) => {
                            setEmail(e.target.value)
                        }}/>
                    </Form.Item>
                    <Form.Item
                        name="password"
                        rules={[{required: true, message: t('rules.required')}]}
                        hidden={loginStep === 1}
                    >
                        <Input
                            prefix={<LockOutlined className="site-form-item-icon"/>}
                            type="password"
                            placeholder={t('placeholder.password')}
                            maxLength={32}
                            size='large'
                        />
                    </Form.Item>
                    <Form.Item
                        name="code"
                        hidden={needMfa === 0}
                        rules={[{len: 6, message: t('rules.len', {'length': 6})}]}
                    >
                        <Input
                            prefix={<LockOutlined className="site-form-item-icon"/>}
                            type="text"
                            placeholder={t('placeholder.code')}
                            maxLength={6}
                            size='large'
                        />
                    </Form.Item>

                    <Form.Item hidden={loginStep === 2}>
                        <Button type="primary" htmlType="button" className="login-form-button" size='large'
                                onClick={loginFirst} loading={btnLoading}>
                            {t('button.next')}
                        </Button>
                    </Form.Item>
                    <Form.Item hidden={loginStep === 1}>
                        <Button type="primary" htmlType="submit" className="login-form-button" size='large'
                                loading={btnLoading}>
                            {t('button.login')}
                        </Button>
                    </Form.Item>
                </Form>
                <div className="version-context">Version: {process.env.REACT_APP_VERSION}</div>
            </div>
        </div>
    );
};