import {get, post} from "../util/request";

export interface VerifyEmailRequest {
    email: string,
}

interface VerifyEmailResponse {
    mfa: number
}

export interface LoginRequest {
    email: string,
    password: string,
    code: string
}

export interface LoginResponse {
    user_id: string,
    full_name: string,
    avatar: string
}

export const verifyEmail = (params: VerifyEmailRequest) => {
    return get<VerifyEmailResponse>({url: "/api/verify-email", params})
}
export const login = (data: LoginRequest) => {
    return post<LoginResponse>({url: "/api/login", data})
}
