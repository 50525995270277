import React, { FC, useEffect, useState } from "react";
import { Button, Col, Divider, Form, Modal, Radio, Row } from "antd";
import { TxnType } from "./txn";
import { TerminalPrmContactCAPK } from "./contactCapk";
import { contactEnableStatus, enableContact } from "../../../../services/cardpool/contactless";

interface IProps {
  terminalId: string;
  aid: string;
}

export const ContactLess: FC<IProps> = ({ terminalId, aid }) => {
  const [enable, setEnable] = useState<boolean>(false);
  const [radioDisabled, setRadioDisabled] = useState<boolean>(false);
  const [pageType, setPageType] = useState<string>("");
  const clickModule = (v: string) => {
    setPageType(v);
  };
  useEffect(
    function () {
      if (terminalId !== "") {
        contactEnableStatus({ terminalId: terminalId, aid: aid }).then((res) => {
          if (res.status === 0) {
            setPageType("00");
            setEnable(res.data.enable);
          }
        });
      }
    },
    [terminalId, aid]
  );

  const changeEnable = (value) => {
    const flag: boolean = value.target.value;
    if (flag) {
      postEnable(flag);
    } else {
      Modal.confirm({
        title: "重要警告",
        content: "您的操作將會使修改的數據完全删除，再次啟用將還原為默認數據，請謹慎確認！",
        onOk: () => postEnable(flag),
        okText: "Yes",
        maskClosable: false,
        closable: false,
      });
    }
  };

  const postEnable = (value: boolean) => {
    setRadioDisabled(true);
    enableContact({ terminalId: terminalId, enable: value, aid: aid })
      .then((res) => {
        if (res.status === 0) {
          setEnable(value);
        }
      })
      .finally(() => {
        setRadioDisabled(false);
      });
  };

  return (
    <>
      <Row>
        <Form layout="inline">
          <Form.Item label="Enable">
            <Radio.Group value={enable} onChange={changeEnable} disabled={radioDisabled}>
              <Radio value={true}>True</Radio>
              <Radio value={false}>False</Radio>
            </Radio.Group>
          </Form.Item>
        </Form>
        <Row gutter={[20, 10]} hidden={!enable}>
          <Col>
            <Button type={pageType === "00" ? "primary" : "default"} onClick={() => clickModule("00")}>
              Sale
            </Button>
          </Col>
          <Col>
            <Button type={pageType === "20" ? "primary" : "default"} onClick={() => clickModule("20")}>
              Refund
            </Button>
          </Col>
          <Col>
            <Button type={pageType === "09" ? "primary" : "default"} onClick={() => clickModule("09")}>
              CashBack
            </Button>
          </Col>
          <Col>
            <Button type={pageType === "capk" ? "primary" : "default"} onClick={() => clickModule("capk")}>
              CAPK
            </Button>
          </Col>
        </Row>
      </Row>
      <Divider />
      {enable && pageType === "00" ? <TxnType terminalId={terminalId} aid={aid} txnType={"00"} /> : null}
      {enable && pageType === "09" ? <TxnType terminalId={terminalId} aid={aid} txnType={"09"} /> : null}
      {enable && pageType === "20" ? <TxnType terminalId={terminalId} aid={aid} txnType={"20"} /> : null}
      {enable && pageType === "capk" ? <TerminalPrmContactCAPK terminalId={terminalId} aid={aid} /> : null}
    </>
  );
};
