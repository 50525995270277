import React, { FC, useEffect, useState } from "react";
import { Button, Card, Col, Divider, Form, Input, Modal, notification, Radio, Row, Select } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import {
  deleteTerminalPrmCapkDefault,
  ICapkDefaultListResponse,
  IStoreCapkDefaultRequest,
  resetTerminalPrmCapkDefault,
  saveTerminalPrmCapkDefault,
  terminalPrmCapkDefaultList,
} from "../../../../services/terminalPrm";

const { confirm } = Modal;

interface IProps {
  terminalId: string;
}

export const Capk: FC<IProps> = ({ terminalId }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [modalForm] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [capkList, setCapkList] = useState<Array<ICapkDefaultListResponse>>([]);
  const [modalFormValues, setModalFormValues] = useState<ICapkDefaultListResponse>();
  const [groupId, setGroupId] = useState("");
  const groupList = [
    {
      label: "VSDC-A000000003",
      value: "A000000003",
    },
    {
      label: "MCHIP-A000000004",
      value: "A000000004",
    },
    {
      label: "JSMART-A000000065",
      value: "A000000065",
    },
    {
      label: "CUP-A000000333",
      value: "A000000333",
    },
    {
      label: "DPAS-A000000152",
      value: "A000000152",
    },
    {
      label: "AMEX-A000000025",
      value: "A000000025",
    },
  ];

  useEffect(
    function () {
      if (groupId !== "") {
        terminalPrmCapkDefaultList({ groupId: groupId }).then((res) => {
          if (res.status === 0) {
            setCapkList(res.data);
            form.setFieldsValue(res.data);
          }
        });
      }
    },
    [groupId]
  );
  useEffect(
    function () {
      modalForm.resetFields();
    },
    [modalFormValues]
  );

  const removeCapk = (index) => {
    confirm({
      title: "Do you Want to delete these items?",
      icon: <ExclamationCircleOutlined />,
      onOk() {
        deleteTerminalPrmCapkDefault({ groupId: groupId, itemIndex: index }).then((res) => {
          if (res.status === 0) {
            terminalPrmCapkDefaultList({ groupId: groupId }).then((res) => {
              if (res.status === 0) {
                setCapkList(res.data);
                form.resetFields();
              }
            });
            notification.success({
              message: "Remove success",
            });
          }
        });
      },
    });
  };

  const showCreateModal = () => {
    setModalFormValues(undefined);
    setIsModalVisible(true);
  };

  const showUpdateModal = (value) => {
    setModalFormValues(capkList[value]);
    setIsModalVisible(true);
  };

  const addCapk = () => {
    modalForm.submit();
  };

  const selectGroup = (value: string) => {
    setGroupId(value);
  };

  const submitModalForm = (values) => {
    const submitData: IStoreCapkDefaultRequest = {
      exponent: values["exponent"],
      groupId: groupId,
      hash: values["hash"],
      isTest: !!values["isTest"],
      itemIndex: values["itemIndex"],
      modules: values["modules"],
    };
    saveTerminalPrmCapkDefault(submitData).then((res) => {
      if (res.status === 0) {
        setModalFormValues(values);
        notification.success({ message: "Success" });
        setIsModalVisible(false);
        terminalPrmCapkDefaultList({ groupId: groupId }).then((res) => {
          if (res.status === 0) {
            setCapkList(res.data);
            form.resetFields();
          }
        });
      }
    });
  };

  const resetCapk = () => {
    confirm({
      title: "Are you sure you need to reset the CAPK default data?",
      icon: <ExclamationCircleOutlined />,
      onOk() {
        resetTerminalPrmCapkDefault().then((res) => {
          if (res.status === 0) {
            setGroupId("");
            notification.success({
              message: "Reset success",
            });
          }
        });
      },
    });
  };
  return (
    <Row gutter={10} style={{ marginTop: "20px" }} hidden={terminalId === ""}>
      <div className="form-content-container-content">
        <div style={{ padding: "20px" }}>
          <Form layout="inline">
            <Row gutter={30}>
              <Form.Item label="Group Id" name="groupId">
                <Select
                  style={{ minWidth: "280px" }}
                  showSearch
                  placeholder={t("placeholder.select_group")}
                  options={groupList}
                  onChange={selectGroup}
                />
              </Form.Item>
              <Form.Item>
                <Button type="primary" onClick={showCreateModal}>
                  Add
                </Button>
                <Button type="primary" onClick={resetCapk} style={{ marginLeft: "10px" }}>
                  Reset
                </Button>
              </Form.Item>
            </Row>
          </Form>
          <Form layout="vertical" form={form} initialValues={capkList}>
            <Divider />
            <Row gutter={[30, 10]}>
              {capkList.map((item, index) => {
                return (
                  <Col flex="300px" key={item.itemIndex}>
                    <Card
                      bordered={false}
                      extra={
                        <>
                          <Button type="primary" style={{ marginRight: "40px" }} onClick={() => showUpdateModal(index)}>
                            Update
                          </Button>
                          <Button type="text" onClick={() => removeCapk(item.itemIndex)}>
                            X
                          </Button>
                        </>
                      }
                    >
                      <Form.Item label={item.isTest ? "ItemIndex(test)" : "ItemIndex"} name={[index, "itemIndex"]}>
                        <Input disabled />
                      </Form.Item>
                      <Form.Item label="Modules" name={[index, "modules"]}>
                        <Input disabled title={item.modules} />
                      </Form.Item>
                      <Form.Item label="Exponent" name={[index, "exponent"]}>
                        <Input disabled />
                      </Form.Item>
                      <Form.Item label="Hash" name={[index, "hash"]}>
                        <Input disabled title={item.hash} />
                      </Form.Item>
                    </Card>
                  </Col>
                );
              })}
            </Row>
          </Form>
        </div>
      </div>
      <Modal
        title=""
        visible={isModalVisible}
        onOk={addCapk}
        onCancel={() => setIsModalVisible(false)}
        closable={false}
        maskClosable={false}
        okText="Save"
      >
        <Form form={modalForm} initialValues={modalFormValues} onFinish={submitModalForm}>
          <Col flex="300px">
            <Form.Item label={"ItemIndex"} name="itemIndex">
              <Input />
            </Form.Item>
            <Form.Item label="IsTest" name="isTest">
              <Radio.Group defaultValue={false}>
                <Radio value={true}>True</Radio>
                <Radio value={false}>False</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item label="Modules" name="modules">
              <Input />
            </Form.Item>
            <Form.Item label="Exponent" name="exponent">
              <Input />
            </Form.Item>
            <Form.Item label="Hash" name="hash">
              <Input />
            </Form.Item>
          </Col>
        </Form>
      </Modal>
    </Row>
  );
};
