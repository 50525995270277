import {get, post} from "../../util/request";

export interface TerminalIdRequest {
  terminalId: string
}

export interface ISaveIPassRequest {
  terminalId: string;
  ipass: ET.IIPass;
}

export const getIPass = (params: TerminalIdRequest) => {
  return get<ISaveIPassRequest>({url: "/api/terminal-prm/info/et/ipass", params})
}

export const saveIPass = (data: ISaveIPassRequest) => {
  return post({url: "/api/terminal-prm/info/et/ipass", data})
}