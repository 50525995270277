import React, { FC, useEffect, useState } from "react";
import { Button, Col, Divider, Form, Input, notification, Radio, Row } from "antd";
import { useTranslation } from "react-i18next";
import {
  ITerminalPRMRInfoMohistCfg,
  saveTerminalPrmInfoMohistCfg,
  terminalPrmInfoMohistCfg,
} from "../../../services/mohist/mohist";

interface IProps {
  terminalId: string;
}

export const TerminalPrmMohist: FC<IProps> = ({ terminalId }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [formValues, setFormValues] = useState<ITerminalPRMRInfoMohistCfg>();
  const [btnLoading, setBtnLoading] = useState<boolean>(false);
  useEffect(
    function () {
      if (terminalId !== "") {
        terminalPrmInfoMohistCfg({ terminal_id: terminalId }).then((res) => {
          if (res.status === 0) {
            setFormValues(res.data);
            form.setFieldsValue(res.data);
          }
        });
      }
    },
    [terminalId]
  );
  const saveForm = (values) => {
    if (formValues === undefined) {
      return;
    }
    setBtnLoading(true);
    saveTerminalPrmInfoMohistCfg({
      id: formValues["id"],
      terminalId: terminalId,
      mohist: values["mohist"],
    })
      .then((res) => {
        if (res.status === 0) {
          notification.success({
            message: "save success",
          });
        }
      })
      .finally(() => {
        setBtnLoading(false);
      });
  };
  return (
    <Row gutter={10} style={{ marginTop: "20px" }} hidden={terminalId === ""}>
      <div className="form-content-container-content">
        <Form layout="vertical" form={form} initialValues={formValues} onFinish={saveForm}>
          <Row gutter={30}>
            <Col flex="500px">
              <Form.Item label={t("form.MohistQueryUrl")} name={["mohist", "QueryUrl"]}>
                <Input />
              </Form.Item>
            </Col>
            <Col flex="500px">
              <Form.Item label={t("form.PlatFormID")} name={["mohist", "PlatFormID"]}>
                <Input />
              </Form.Item>
            </Col>
            <Col flex="300px">
              <Form.Item label={t("form.Identity")} name={["mohist", "Identity"]}>
                <Input />
              </Form.Item>
            </Col>
            <Col flex="300px">
              <Form.Item label={t("form.TxnTimeout")} name={["mohist", "TxnTimeout"]}>
                <Input />
              </Form.Item>
            </Col>
            <Col flex="300px">
              <Form.Item label={t("form.SettlementEnable")} name={["mohist", "SettlementEnable"]}>
                <Radio.Group>
                  <Radio value={true}>True</Radio>
                  <Radio value={false}>False</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col flex="300px">
              <Form.Item label={t("form.ProdDesc")} name={["mohist", "ProdDesc"]}>
                <Input />
              </Form.Item>
            </Col>
            <Col flex="300px">
              <Form.Item label={t("form.TickCode")} name={["mohist", "TickCode"]}>
                <Input />
              </Form.Item>
            </Col>
            <Col flex="300px">
              <Form.Item label={t("form.RequestNetwork")} name={["mohist" ,"RequestNetwork"]}>
                <Radio.Group>
                  <Radio value={true}>True</Radio>
                  <Radio value={false}>False</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col flex="300px">
              <Form.Item label={t('form.RequestNetworkType')} name={['mohist', 'RequestNetworkType']}>
                <Input/>
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <Divider />
        <div>
          <Button
            type="primary"
            shape="round"
            style={{ width: "200px", float: "right" }}
            htmlType="submit"
            loading={btnLoading}
            onClick={() => form.submit()}
          >
            {t("button.save")}
          </Button>
        </div>
      </div>
    </Row>
  );
};
