import { get, patch } from "../../util/request";

export interface TerminalIdAidRequest {
  terminalId: string;
  aid: string;
}

export interface IUpdateCapkRequest {
  terminalId: string;
  aid: string;
  capk: Array<Emv.ICAPKItem>;
}

export const capk = (params: TerminalIdAidRequest) => {
  return get<Array<Emv.ICAPKItem>>({ url: "/api/terminal-prm/info/cp/contactless/capk", params });
};

export const updateCapk = (data: IUpdateCapkRequest) => {
  return patch({ url: "/api/terminal-prm/info/cp/contactless/capk", data });
};
