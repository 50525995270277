import React, {FC, useEffect, useState} from "react";
import {Button, Col, Divider, Row, Tabs} from "antd";
import {Route, Routes, useLocation, useNavigate} from "react-router-dom";
import {TerminalPrmTerminalConfig} from "./emv/terminalConfig";
import {TerminalPrmAppConfig} from "./emv/appConfig";
import {appList, IApplicationItem} from "../../../services/cardpool/appConfig";
import {CreateApp} from "./emv/createApp";

interface IProps {
  terminalId: string;
}

export const TerminalPrmEmvConfig: FC<IProps> = ({terminalId}) => {
  const navigator = useNavigate()
  const location = useLocation()
  const [list, setList] = useState<Array<IApplicationItem>>([])
  const clickModule = (v: string) => {
    navigator(v)
  }
  useEffect(function () {
    if (terminalId !== "") {
      appList({terminalId: terminalId}).then(res => {
        if (res.status === 0) {
          setList(res.data)
        }
      })
    }
  }, [terminalId, location.pathname])

  const changeTab = (index:string) => {
    if (index ==="0"){
      navigator('/terminal-prm/emvconfig')
    }
  }
  return (
    <Row gutter={10} style={{marginTop: "20px"}} hidden={terminalId === ""}>
      <div className='form-content-container-content'>
        <Tabs defaultActiveKey="0" onChange={changeTab}>
          <Tabs.TabPane tab="Terminal Config" key="0">
            {/* <Row gutter={[20, 10]} style={{marginBottom: "20px"}}>
              <Col>
                <Button type={location.pathname.endsWith("/terminalconfig") ? "primary" : "default"}
                        onClick={() => clickModule('terminalconfig')}>Terminal Config</Button>
              </Col>
            </Row> */}
            <TerminalPrmTerminalConfig terminalId={terminalId}/>
          </Tabs.TabPane>
          <Tabs.TabPane tab="App Config" key="1">
            <Row gutter={[20, 10]}>
              {
                list.map((item, index) => {
                  return (
                    <Col style={{minWidth: "330px"}} key={index}>
                      <Button type={location.pathname.endsWith("/" + item.aid) ? "primary" : "default"} block
                              onClick={() => clickModule(item.aid.toString())}>{item.name} - {item.aid}</Button>
                    </Col>
                  )
                })
              }
              <Col style={{minWidth: "330px"}}>
                <Button type={location.pathname.endsWith("/create-applications") ? "primary" : "default"} block
                        onClick={() => clickModule('create-applications')}>+</Button>
              </Col>
            </Row>
          </Tabs.TabPane>
        </Tabs>
        <Divider/>
        <Routes>
          <Route path='terminalconfig' element={<TerminalPrmTerminalConfig terminalId={terminalId}/>}/>
          {
            list.map((item, index) => {
              return <Route key={index} path={item.aid}
                            element={<TerminalPrmAppConfig terminalId={terminalId} aid={item.aid}/>}/>
            })
          }
          <Route path='create-applications' element={<CreateApp terminalId={terminalId}/>}/>
        </Routes>
      </div>
    </Row>
  )
}