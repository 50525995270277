import {get, post} from "../../util/request";
import {
  TerminalPrmInfoRequest
} from "../terminalPrm";

export interface ITerminalPRMRInfoMohistCfg {
  id: string;
  terminalId: string;
  mohist: Mohist.IMohist;
}

export interface ITerminalPRMRInfoMohistData {
  id: string;
  terminalId: string;
  data: string;
}

export interface ITerminalPRMRInfoDataResponse {
  url: string;
}

export const terminalPrmInfoMohistCfg = (params: TerminalPrmInfoRequest) => {
  return get<ITerminalPRMRInfoMohistCfg>({url: "/api/terminal-prm/info/mohist/cfg", params})
}

export const saveTerminalPrmInfoMohistCfg = (data: ITerminalPRMRInfoMohistCfg) => {
  return post({url: "/api/terminal-prm/info/mohist/cfg", data})
}

export const terminalPrmInfoMohistData = (params: TerminalPrmInfoRequest) => {
  return get<ITerminalPRMRInfoMohistData>({url: "/api/terminal-prm/info/mohist/data", params})
}

export const saveTerminalPrmInfoMohistData = (data: ITerminalPRMRInfoMohistData) => {
  return post<ITerminalPRMRInfoDataResponse>({url: "/api/terminal-prm/info/mohist/data", data})
}
