import React, {FC, useState} from "react";
import {Button, Col, Divider, Form, Input, notification, Radio, Row} from "antd";
import {useTranslation} from "react-i18next";
import {useLocation, useNavigate} from "react-router-dom";
import {createItem, IItemResponse} from "../../../../services/cardpool/gpapfq";

interface IProps {
  terminalId: string;
}

export const TerminalPrmCPGPAPfqCreateItem: FC<IProps> = ({terminalId}) => {
  const {t} = useTranslation()
  const navigator = useNavigate()
  const location = useLocation()
  const [form] = Form.useForm()
  let initialValues: IItemResponse = {
    item: {
      IssuerName: "",
      AcquirerName: "",
      CardNumMaskTypeMerchant: 0,
      CardNumMaskTypeCustomer: 0,
      StartBinRange: "",
      EndBinRange: "",
      MinPanLength: 0,
      MaxPanLength: 0,
      InstSaleFlag: true,
      InstVoidFlag: true,
      InstRefundFlag: true,
      InstSettlementFlag: true,
      InstOfflineSaleFlag:true,
      CallBankFlag: true,
      ManualKeyinFlag: true,
      CheckExpiredDateFlag: true,
      ManualExpiryDateFlag: true,
      ManualCheckSumFlag: true,
      CheckModule10Flag: true,
      RefundManualCheckSumFlag: true
    }, terminalId: ""
  }
  if (location.state){
    initialValues=location.state as IItemResponse
  }
  const [formValues] = useState<IItemResponse>(initialValues);
  const [btnLoading, setBtnLoading] = useState<boolean>(false)
  const saveForm = (values) => {
    setBtnLoading(true)
    createItem({
      terminalId: terminalId,
      item: values['item'],
    }).then(res => {
      if (res.status === 0) {
        notification.success({
          message: "save success"
        })
        navigator('/terminal-prm/acquirer/gpapfq')
      }
    }).finally(() => {
      setBtnLoading(false)
    })
  }
  return (
    <>
      <Form layout='vertical' form={form} initialValues={formValues} onFinish={saveForm}>
        <Row gutter={30}>
          <Col flex="300px">
            <Form.Item label={t('form.IssuerName')} name={['item', 'IssuerName']}>
              <Input/>
            </Form.Item>
          </Col>
          <Col flex="300px">
            <Form.Item label={t('form.AcquirerName')} name={['item', 'AcquirerName']}>
              <Input/>
            </Form.Item>
          </Col>
          <Col flex="300px">
            <Form.Item label={t('form.CardNumMaskTypeMerchant')} name={['item', 'CardNumMaskTypeMerchant']}
                       normalize={(value) => Number(value)}>
              <Input/>
            </Form.Item>
          </Col>
          <Col flex="300px">
            <Form.Item label={t('form.CardNumMaskTypeCustomer')} name={['item', 'CardNumMaskTypeCustomer']}
                       normalize={(value) => Number(value)}>
              <Input/>
            </Form.Item>
          </Col>
          <Col flex="300px">
            <Form.Item label={t('form.StartBinRange')} name={['item', 'StartBinRange']}>
              <Input/>
            </Form.Item>
          </Col>
          <Col flex="300px">
            <Form.Item label={t('form.EndBinRange')} name={['item', 'EndBinRange']}>
              <Input/>
            </Form.Item>
          </Col>
          <Col flex="300px">
            <Form.Item label={t('form.MinPanLength')} name={['item', 'MinPanLength']}
                       normalize={(value) => Number(value)}>
              <Input/>
            </Form.Item>
          </Col>
          <Col flex="300px">
            <Form.Item label={t('form.MaxPanLength')} name={['item', 'MaxPanLength']}
                       normalize={(value) => Number(value)}>
              <Input/>
            </Form.Item>
          </Col>
          <Col flex="300px">
            <Form.Item label={t('form.InstSaleFlag')} name={['item', 'InstSaleFlag']}>
              <Radio.Group>
                <Radio value={true}>True</Radio>
                <Radio value={false}>False</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col flex="300px">
            <Form.Item label={t('form.InstVoidFlag')} name={['item', 'InstVoidFlag']}>
              <Radio.Group>
                <Radio value={true}>True</Radio>
                <Radio value={false}>False</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col flex="300px">
            <Form.Item label={t('form.InstRefundFlag')} name={['item', 'InstRefundFlag']}>
              <Radio.Group>
                <Radio value={true}>True</Radio>
                <Radio value={false}>False</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col flex="300px">
            <Form.Item label={t('form.InstSettlementFlag')} name={['item', 'InstSettlementFlag']}>
              <Radio.Group>
                <Radio value={true}>True</Radio>
                <Radio value={false}>False</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col flex="300px">
            <Form.Item label={t('form.InstOfflineSaleFlag')} name={['item', 'InstOfflineSaleFlag']}>
              <Radio.Group>
                <Radio value={true}>True</Radio>
                <Radio value={false}>False</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col flex="300px">
            <Form.Item label={t('form.CallBankFlag')} name={['item', 'CallBankFlag']}>
              <Radio.Group>
                <Radio value={true}>True</Radio>
                <Radio value={false}>False</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col flex="300px">
            <Form.Item label={t('form.ManualKeyinFlag')} name={['item', 'ManualKeyinFlag']}>
              <Radio.Group>
                <Radio value={true}>True</Radio>
                <Radio value={false}>False</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col flex="300px">
            <Form.Item label={t('form.CheckExpiredDateFlag')} name={['item', 'CheckExpiredDateFlag']}>
              <Radio.Group>
                <Radio value={true}>True</Radio>
                <Radio value={false}>False</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col flex="300px">
            <Form.Item label={t('form.ManualExpiryDateFlag')} name={['item', 'ManualExpiryDateFlag']}>
              <Radio.Group>
                <Radio value={true}>True</Radio>
                <Radio value={false}>False</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col flex="300px">
            <Form.Item label={t('form.ManualCheckSumFlag')} name={['item', 'ManualCheckSumFlag']}>
              <Radio.Group>
                <Radio value={true}>True</Radio>
                <Radio value={false}>False</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col flex="300px">
            <Form.Item label={t('form.CheckModule10Flag')} name={['item', 'CheckModule10Flag']}>
              <Radio.Group>
                <Radio value={true}>True</Radio>
                <Radio value={false}>False</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col flex="300px">
            <Form.Item label={t('form.RefundManualCheckSumFlag')}
                       name={['item', 'RefundManualCheckSumFlag']}>
              <Radio.Group>
                <Radio value={true}>True</Radio>
                <Radio value={false}>False</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Divider/>
      <div>
        <Button type="primary" shape="round" style={{width: "200px", float: "right"}}
                htmlType="submit" loading={btnLoading} onClick={() => form.submit()}>
          {t('button.save')}
        </Button>
      </div>
    </>
  )
}