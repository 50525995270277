import { get, patch } from "../../util/request";

export interface TerminalIdAidRequest {
  terminalId: string;
  aid: string;
}

export interface IContactEnableStatusResponse {
  enable: boolean;
}

export interface IContactEnableRequest {
  terminalId: string;
  aid: string;
  enable: boolean;
}

export interface ITxnRequest {
  terminalId: string;
  aid:string
  txnType: string;
}

export interface IUpdateTxnRequest {
  terminalId: string;
  aid:string;
  contactLessValue: Emv.IContactLess;
  txnType: string;
}

export const contactEnableStatus = (params: TerminalIdAidRequest) => {
  return get<IContactEnableStatusResponse>({ url: "/api/terminal-prm/info/cp/contactless/status", params });
};

export const enableContact = (data: IContactEnableRequest) => {
  return patch({ url: "/api/terminal-prm/info/cp/contactless/status", data });
};

export const txn = (params: ITxnRequest) => {
  return get<Emv.IContactLess>({ url: "/api/terminal-prm/info/cp/contactless/txntype", params });
};

export const updateTxn = (data: IUpdateTxnRequest) => {
  return patch({ url: "/api/terminal-prm/info/cp/contactless/txntype", data });
};
