import React, {FC, useEffect, useState} from "react";
import {Button, Col, Divider, Form, Input, notification, Row} from "antd";
import {useTranslation} from "react-i18next";
import {getIPass, ISaveIPassRequest, saveIPass} from "../../../services/eticket/ipass";

interface IProps {
  terminalId: string;
}

export const TerminalPrmETIPass: FC<IProps> = ({terminalId}) => {
  const {t} = useTranslation()
  const [form] = Form.useForm()
  const [formValues, setFormValues] = useState<ISaveIPassRequest>();
  const [btnLoading, setBtnLoading] = useState<boolean>(false)
  useEffect(function () {
    if (terminalId !== "") {
      getIPass({terminalId: terminalId}).then(res => {
        if (res.status === 0) {
          setFormValues(res.data)
          form.setFieldsValue(res.data)
        }
      })
    }
  }, [terminalId])
  const saveForm = (values) => {
    if (formValues === undefined) {
      return
    }
    setBtnLoading(true)
    saveIPass({terminalId: terminalId, ipass: values["ipass"]}).then(res => {
      if (res.status === 0) {
        notification.success({
          message: "save success"
        })
      }
    }).finally(() => {
      setBtnLoading(false)
    })
  }
  return (
    <Row gutter={10} style={{marginTop: "20px"}} hidden={terminalId === ""}>
      <div className='form-content-container-content'>
        <Form layout='vertical' form={form} initialValues={formValues} onFinish={saveForm}>
          <Row gutter={30}>
            <Col flex="300px" hidden>
              <Form.Item label="IPASS_G1_config" name={["ipass", "IPASS_G1_config"]}>
                <Input/>
              </Form.Item>
            </Col>
            <Col flex="300px" hidden>
              <Form.Item label="ClientIp" name={["ipass", "ClientIp"]}>
                <Input/>
              </Form.Item>
            </Col>
            <Col flex="300px" hidden>
              <Form.Item label="ServProviderId" name={["ipass", "ServProviderId"]}>
                <Input/>
              </Form.Item>
            </Col>
            <Col flex="300px" hidden>
              <Form.Item label="SubCompanyId" name={["ipass", "SubCompanyId"]}>
                <Input/>
              </Form.Item>
            </Col>
            <Col flex="300px" hidden>
              <Form.Item label="EquipType" name={["ipass", "EquipType"]}>
                <Input/>
              </Form.Item>
            </Col>
            <Col flex="300px" hidden>
              <Form.Item label="ModeSelection" name={["ipass", "ModeSelection"]}>
                <Input/>
              </Form.Item>
            </Col>
            <Col flex="300px" hidden>
              <Form.Item label="UserId" name={["ipass", "UserId"]}>
                <Input/>
              </Form.Item>
            </Col>
            <Col flex="300px" hidden>
              <Form.Item label="UserPwd" name={["ipass", "UserPwd"]}>
                <Input/>
              </Form.Item>
            </Col>
            <Col flex="300px" hidden>
              <Form.Item label="SuperUserId" name={["ipass", "SuperUserId"]}>
                <Input/>
              </Form.Item>
            </Col>
            <Col flex="300px" hidden>
              <Form.Item label="SuperUserPwd" name={["ipass", "SuperUserPwd"]}>
                <Input/>
              </Form.Item>
            </Col>
            <Col flex="300px" hidden>
              <Form.Item label="TmsServerIp" name={["ipass", "TmsServerIp"]}>
                <Input/>
              </Form.Item>
            </Col>
            <Col flex="300px" hidden>
              <Form.Item label="TmsServerPort" name={["ipass", "TmsServerPort"]}>
                <Input/>
              </Form.Item>
            </Col>
            <Col flex="300px" hidden>
              <Form.Item label="BmsServerIp" name={["ipass", "BmsServerIp"]}>
                <Input/>
              </Form.Item>
            </Col>
            <Col flex="300px" hidden>
              <Form.Item label="BmsServerPort" name={["ipass", "BmsServerPort"]}>
                <Input/>
              </Form.Item>
            </Col>
            <Col flex="300px" hidden>
              <Form.Item label="SystemId" name={["ipass", "SystemId"]}>
                <Input/>
              </Form.Item>
            </Col>
            <Col flex="300px" hidden>
              <Form.Item label="ShopNo" name={["ipass", "ShopNo"]}>
                <Input/>
              </Form.Item>
            </Col>
            <Col flex="300px" hidden>
              <Form.Item label="PosId" name={["ipass", "PosId"]}>
                <Input/>
              </Form.Item>
            </Col>
            <Col flex="300px">
              <Form.Item label="TerminalId" name={["ipass", "TerminalId"]}>
                <Input/>
              </Form.Item>
            </Col>
            <Col flex="300px" hidden>
              <Form.Item label="IPASS_G2_config" name={["ipass", "IPASS_G2_config"]}>
                <Input/>
              </Form.Item>
            </Col>
            <Col flex="300px">
              <Form.Item label={t('form.CompanyId')} name={["ipass", "CompanyId"]}>
                <Input/>
              </Form.Item>
            </Col>
            <Col flex="300px">
              <Form.Item label={t('form.ShopId')} name={["ipass", "ShopId"]}>
                <Input/>
              </Form.Item>
            </Col>
            <Col flex="300px">
              <Form.Item label={t('form.JointBankId')} name={["ipass", "JointBankId"]}>
                <Input/>
              </Form.Item>
            </Col>
            <Col flex="300px">
              <Form.Item label={t('form.AICId')} name={["ipass", "AICId"]}>
                <Input/>
              </Form.Item>
            </Col>
            <Col flex="300px">
              <Form.Item label={t('form.AutoLogonFlag')} name={["ipass", "AutoLogonFlag"]}>
                <Input/>
              </Form.Item>
            </Col>
            <Col flex="300px">
              <Form.Item label={t('form.AutoLogonHours')} name={["ipass", "AutoLogonHours"]}>
                <Input/>
              </Form.Item>
            </Col>
            <Col flex="300px">
              <Form.Item label="BatchFtpFlag" name={["ipass", "BatchFtpFlag"]}>
                <Input/>
              </Form.Item>
            </Col>
            <Col flex="300px">
              <Form.Item label="BatchFtpIp" name={["ipass", "BatchFtpIp"]}>
                <Input/>
              </Form.Item>
            </Col>
            <Col flex="300px">
              <Form.Item label="BatchFtpPort" name={["ipass", "BatchFtpPort"]}>
                <Input/>
              </Form.Item>
            </Col>
            <Col flex="300px">
              <Form.Item label="BatchFtpId" name={["ipass", "BatchFtpId"]}>
                <Input/>
              </Form.Item>
            </Col>
            <Col flex="300px">
              <Form.Item label="BatchFtpPwd" name={["ipass", "BatchFtpPwd"]}>
                <Input/>
              </Form.Item>
            </Col>
            <Col flex="300px">
              <Form.Item label={t('form.SslFlag')} name={["ipass", "SslFlag"]}>
                <Input/>
              </Form.Item>
            </Col>
            <Col flex="300px">
              <Form.Item label={t('form.SslVersion')} name={["ipass", "SslVersion"]}>
                <Input/>
              </Form.Item>
            </Col>
            <Col flex="300px">
              <Form.Item label={t('form.SslCipher')} name={["ipass", "SslCipher"]}>
                <Input/>
              </Form.Item>
            </Col>
            <Col flex="300px">
              <Form.Item label={t('form.SslKeyFile')} name={["ipass", "SslKeyFile"]}>
                <Input/>
              </Form.Item>
            </Col>
            <Col flex="300px">
              <Form.Item label={t('form.SslCrtFile')} name={["ipass", "SslCrtFile"]}>
                <Input/>
              </Form.Item>
            </Col>
            <Col flex="300px">
              <Form.Item label={t('form.ServerIp')} name={["ipass", "ServerIp"]}>
                <Input/>
              </Form.Item>
            </Col>
            <Col flex="300px">
              <Form.Item label={t('form.ServerPort')} name={["ipass", "ServerPort"]}>
                <Input/>
              </Form.Item>
            </Col>
            <Col flex="300px">
              <Form.Item label={t('form.ServerTxTimeout')} name={["ipass", "ServerTxTimeout"]}>
                <Input/>
              </Form.Item>
            </Col>
            <Col flex="300px">
              <Form.Item label={t('form.ServerRxTimeout')} name={["ipass", "ServerRxTimeout"]}>
                <Input/>
              </Form.Item>
            </Col>
            <Col flex="300px">
              <Form.Item label={t('form.DebugLogFlag')} name={["ipass", "DebugLogFlag"]}>
                <Input/>
              </Form.Item>
            </Col>
            <Col flex="300px">
              <Form.Item label={t('form.DebugLogMaxSize')} name={["ipass", "DebugLogMaxSize"]}>
                <Input/>
              </Form.Item>
            </Col>
            <Col flex="300px">
              <Form.Item label={t('form.DebugLogMaxDays')} name={["ipass", "DebugLogMaxDays"]}>
                <Input/>
              </Form.Item>
            </Col>
            <Col flex="300px">
              <Form.Item label="ChkBalanceAutoLoadFlag" name={["ipass", "ChkBalanceAutoLoadFlag"]}>
                <Input/>
              </Form.Item>
            </Col>
            <Col flex="300px">
              <Form.Item label={t('form.TxnLogFlag')} name={["ipass", "TxnLogFlag"]}>
                <Input/>
              </Form.Item>
            </Col>
            <Col flex="300px">
              <Form.Item label={t('form.SamSlot')} name={["ipass", "SamSlot"]}>
                <Input/>
              </Form.Item>
            </Col>
            <Col flex="300px">
              <Form.Item label={t('form.CardNumMaskCnt')} name={["ipass", "CardNumMaskCnt"]}>
                <Input/>
              </Form.Item>
            </Col>
            <Col flex="300px">
              <Form.Item label={t('form.CardNumMaskOffset')} name={["ipass", "CardNumMaskOffset"]}>
                <Input/>
              </Form.Item>
            </Col>
            <Col flex="300px">
              <Form.Item label="AutoPinFlag" name={["ipass", "AutoPinFlag"]}>
                <Input/>
              </Form.Item>
            </Col>
            <Col flex="300px">
              <Form.Item label={t('form.AutoPinCode')} name={["ipass", "AutoPinCode"]}>
                <Input/>
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <Divider/>
        <div>
          <Button type="primary" shape="round" style={{width: "200px", float: "right"}}
                  htmlType="submit" loading={btnLoading} onClick={() => form.submit()}>
            {t('button.save')}
          </Button>
        </div>
      </div>
    </Row>
  )
}