import React, {FC, useEffect, useState} from "react";
import {Button, Col, Divider, Row} from "antd";
import {Route, Routes, useLocation, useNavigate} from "react-router-dom";
import {IItemsResponse, issuerItems} from "../../../../services/cardpool/smartyPay";
import {TerminalPrmCPSmartyPayItem} from "./item";
import {TerminalPrmCPSmartyPayCreateItem} from "./create";

interface IProps {
  terminalId: string;
}

export const TerminalPrmCPSmartyPayIndex: FC<IProps> = ({terminalId}) => {
  const navigator = useNavigate()
  const location = useLocation()
  const [items, setItems] = useState<Array<IItemsResponse>>([])
  const clickModule = (v: string) => {
    navigator(v)
  }

  useEffect(function () {
    getIssuerItems()
  }, [location.pathname])

  const getIssuerItems = () => {
    if (terminalId !== "") {
      issuerItems({terminalId: terminalId}).then(res => {
        if (res.status === 0) {
          setItems(res.data)
        }
      })
    }
  }
  return (
    <div>
      <Row gutter={[20, 10]}>
        {
          items.map((item, index) => {
            return <Col key={index} style={{minWidth: "330px"}}>
              <Button type={location.pathname.endsWith("/" + item.index) ? "primary" : "default"} block
                      onClick={() => clickModule(item.index)}>{item.issuerName} {item.startBinRange}-{item.endBinRange}({item.minPanLength}-{item.maxPanLength})</Button>
            </Col>
          })
        }
        <Col style={{width: "330px"}}>
          <Button type={location.pathname.indexOf("/create-issuer") !== -1 ? "primary" : "default"} block
                  onClick={() => clickModule('create-issuer')}>+</Button>
        </Col>
      </Row>
      <Divider/>
      <Routes>
        {
          items.map((item, index) => {
            return <Route path={item.index} key={index}
                          element={<TerminalPrmCPSmartyPayItem terminalId={terminalId} index={item.index}/>}/>
          })
        }
        <Route path="create-issuer" element={<TerminalPrmCPSmartyPayCreateItem terminalId={terminalId}/>}/>
      </Routes>
    </div>
  )
}