import React, {FC, useEffect, useState} from "react";
import {Alert, Button, Col, Divider, Form, Input, Modal, notification, Row} from "antd";
import {useTranslation} from "react-i18next";
import {MinusCircleOutlined, PlusCircleOutlined} from "@ant-design/icons";
import {appInfo, IAppConfigItem, IApplicationInfo, removeApp, storeApp} from "../../../../services/cardpool/appConfig";
import {useNavigate} from "react-router-dom";
import {dict} from "../../../../util/constants/tagLength"

interface IProps {
  terminalId: string;
  aid: string;
}

export const TerminalPrmAppConfig: FC<IProps> = ({terminalId, aid}) => {
  const {t} = useTranslation()
  const navigator = useNavigate()
  const [form] = Form.useForm()
  const initial: IApplicationInfo = {asi: "", name: "", aid: "", items: []}
  const [formValues, setFormValues] = useState<IApplicationInfo>(initial);
  const [btnLoading, setBtnLoading] = useState<boolean>(false)
  useEffect(function () {
    if (terminalId !== "" && aid !== "") {
      appInfo({terminalId: terminalId, aid: aid}).then(res => {
        if (res.status === 0) {
          setFormValues(res.data)
          form.setFieldsValue(res.data)
        }
      })
    }
  }, [terminalId, aid])
  const saveForm = (values) => {
    setBtnLoading(true)
    storeApp({
      ...values,
      terminalId: terminalId,
    }).then(res => {
      if (res.status === 0) {
        notification.success({
          message: "save success"
        })
      }
    }).finally(() => {
      setBtnLoading(false)
    })
  }
  const removeConfig = (index: number) => {
    const fieldsValue = form.getFieldsValue()
    let values: IApplicationInfo = {
      aid: fieldsValue.aid,
      asi: fieldsValue.asi,
      items: Array.from(fieldsValue.items),
      name: fieldsValue.name,
    }
    values.items.splice(index, 1)
    setFormValues(values)
    form.setFieldsValue(values)
  }

  const addConfig = () => {
    const fieldsValue = form.getFieldsValue()
    let values: IApplicationInfo = {
      aid: fieldsValue.aid,
      asi: fieldsValue.asi,
      items: Array.from(fieldsValue.items),
      name: fieldsValue.name,
    }
    const item: IAppConfigItem = {attribute: "hex", name: "", tag: "", text: ""}
    values.items.push(item)
    setFormValues(values)
    form.setFieldsValue(values)
  }

  const deleteApp = () => {
    Modal.confirm({
      title: "重要警告",
      content: "您的操作將會使數據完全删除，請謹慎確認！",
      onOk: () => {
        setBtnLoading(true)
        removeApp({terminalId: terminalId, aid: aid}).then(res => {
          if (res.status === 0) {
            notification.success({
              message: "success",
            })
            setTimeout(function () {
              navigator('/terminal-prm/emvconfig')
            }, 200)
          }
        }).finally(() => {
          setBtnLoading(false)
        })
      },
      okText: "Yes",
      maskClosable: false,
      closable: false
    })
  }

  const refreshForm = () => {
    const fieldsValue = form.getFieldsValue()
    let values: IApplicationInfo = {
      aid: fieldsValue.aid,
      asi: fieldsValue.asi,
      items: Array.from(fieldsValue.items),
      name: fieldsValue.name,
    }
    setFormValues(values)
    form.setFieldsValue(values)
  }

  const customAlert = (text: string) => {
    return (
      <>
        <Alert
          style={{marginBottom: "20px"}}
          message={<div style={{height: "40px", lineHeight: "40px", fontSize: "18px", fontWeight: "bold"}}>{text}</div>}
          type="info"/>
      </>
    )
  }
  return (
    <Row gutter={10} style={{marginTop: "20px"}} hidden={terminalId === ""}>
      <Form layout='vertical' style={{width: "100%"}} form={form} initialValues={formValues} onFinish={saveForm}>
        {customAlert("Application")}
        <Row gutter={20}>
          <Col flex="300px">
            <Form.Item label="Name" name="name">
              <Input/>
            </Form.Item>
          </Col>
          <Col flex="300px">
            <Form.Item label="AID" name="aid">
              <Input/>
            </Form.Item>
          </Col>
          <Col flex="300px">
            <Form.Item label="ASI" name="asi">
              <Input/>
            </Form.Item>
          </Col>
        </Row>
        {customAlert("Application Config")}
        {formValues.items.map((item, index) => {
          return (
            <Row key={index} gutter={20}>
              <Col flex="300px">
                <Form.Item label="Name" name={['items', index, 'name']}>
                  <Input/>
                </Form.Item>
              </Col>
              <Col flex="100px">
                <Form.Item label="Tag" name={['items', index, 'tag']}>
                  <Input onBlur={refreshForm}/>
                </Form.Item>
              </Col>
              <Col flex="100px">
                <Form.Item label="Attribute" name={['items', index, 'attribute']}>
                  <Input/>
                </Form.Item>
              </Col>
              <Col flex="200px">
                <Form.Item label="Value" name={['items', index, 'text']}>
                  <Input maxLength={dict[item.tag]}/>
                </Form.Item>
              </Col>
              <Col flex="20px">
                <Form.Item label=" ">
                  <Button icon={<MinusCircleOutlined/>} type="primary" onClick={() => removeConfig(index)}/>
                </Form.Item>
              </Col>
            </Row>
          )
        })}
        <Button icon={<PlusCircleOutlined/>} type="primary" onClick={addConfig}/>
      </Form>
      <Divider/>
      <div style={{width: "100%"}}>
        <Button type="primary" shape="round" style={{width: "200px", float: "right"}}
                htmlType="submit" loading={btnLoading} onClick={() => form.submit()}>
          {t('button.save')}
        </Button>
        <Button type="default" shape="round" style={{minWidth: "200px", float: "right", marginRight: "20px"}}
                htmlType="submit" loading={btnLoading} onClick={deleteApp}>
          {t('button.delete')}
        </Button>
      </div>
    </Row>
  )
}