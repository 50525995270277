import React, {FC, useEffect, useState} from "react";
import {Button, Col, Divider, Form, Input, notification, Radio, Row} from "antd";
import {useTranslation} from "react-i18next";
import {
  ITerminalPRMRInfoCPTerminal,
  saveTerminalPrmInfoCPTerminal,
  terminalPrmInfoCPTerminal
} from "../../../services/terminalPrm";

interface IProps {
  terminalId: string;
}

export const TerminalPrmCPTerminal: FC<IProps> = ({terminalId}) => {
  const {t} = useTranslation()
  const [form] = Form.useForm()
  const [formValues, setFormValues] = useState<ITerminalPRMRInfoCPTerminal>();
  const [btnLoading, setBtnLoading] = useState<boolean>(false)
  useEffect(function () {
    if (terminalId !== "") {
      terminalPrmInfoCPTerminal({terminal_id: terminalId}).then(res => {
        if (res.status === 0) {
          setFormValues(res.data)
          form.setFieldsValue(res.data)
        }
      })
    }
  }, [terminalId])
  const saveForm = (values) => {
    if (formValues === undefined) {
      return
    }
    setBtnLoading(true)
    saveTerminalPrmInfoCPTerminal({
      id: formValues['id'],
      terminalId: terminalId,
      terminalDef: values['terminalDef']
    }).then(res => {
      if (res.status === 0) {
        notification.success({
          message: "save success"
        })
      }
    }).finally(() => {
      setBtnLoading(false)
    })
  }
  return (
    <Row gutter={10} style={{marginTop: "20px"}} hidden={terminalId === ""}>
      <div className='form-content-container-content'>
        <Form layout='vertical' form={form} initialValues={formValues} onFinish={saveForm}>
          <Row gutter={30}>
            <Col flex="300px">
              <Form.Item label={t('form.MerchantName')} name={['terminalDef', 'Index0', 'MerchantName']}>
                <Input/>
              </Form.Item>
            </Col>
            <Col flex="300px">
              <Form.Item label={t('form.MerchantAddress')} name={['terminalDef', 'Index0', 'MerchantAddress']}>
                <Input/>
              </Form.Item>
            </Col>
            <Col flex="300px">
              <Form.Item label={t('form.TerminalPwd')} name={['terminalDef', 'Index0', 'TerminalPwd']}>
                <Input/>
              </Form.Item>
            </Col>
            <Col flex="300px">
              <Form.Item label={t('form.AcquirerPwd')} name={['terminalDef', 'Index0', 'AcquirerPwd']}>
                <Input/>
              </Form.Item>
            </Col>
            <Col flex="300px">
              <Form.Item label={t('form.RefundPwd')} name={['terminalDef', 'Index0', 'RefundPwd']}>
                <Input/>
              </Form.Item>
            </Col>
            <Col flex="300px">
              <Form.Item label={t('form.AdjustPwd')} name={['terminalDef', 'Index0', 'AdjustPwd']}>
                <Input/>
              </Form.Item>
            </Col>
            <Col flex="300px">
              <Form.Item label={t('form.VoidPwd')} name={['terminalDef', 'Index0', 'VoidPwd']}>
                <Input/>
              </Form.Item>
            </Col>
            <Col flex="300px">
              <Form.Item label={t('form.OfflineSalePwd')} name={['terminalDef', 'Index0', 'OfflineSalePwd']}>
                <Input/>
              </Form.Item>
            </Col>
            <Col flex="300px">
              <Form.Item label={t('form.SettlePwd')} name={['terminalDef', 'Index0', 'SettlePwd']}>
                <Input/>
              </Form.Item>
            </Col>
            <Col flex="300px">
              <Form.Item label={t('form.TipPwd')} name={['terminalDef', 'Index0', 'TipPwd']}>
                <Input/>
              </Form.Item>
            </Col>
            <Col flex="300px">
              <Form.Item label={t('form.PreAuthorizationPwd')} name={['terminalDef', 'Index0', 'PreAuthorizationPwd']}>
                <Input/>
              </Form.Item>
            </Col>
            <Col flex="300px">
              <Form.Item label={t('form.PreAuthorizationCompletePwd')}
                         name={['terminalDef', 'Index0', 'PreAuthorizationCompletePwd']}>
                <Input/>
              </Form.Item>
            </Col>
            <Col flex="300px">
              <Form.Item label={t('form.SalePwd')} name={['terminalDef', 'Index0', 'SalePwd']}>
                <Input/>
              </Form.Item>
            </Col>
            <Col flex="300px">
              <Form.Item label={t('form.AutoSettlementTime')} name={['terminalDef', 'Index0', 'AutoSettlementTime']}>
                <Input/>
              </Form.Item>
            </Col>
            <Col flex="300px">
              <Form.Item label={t('form.DccLocalCurrencyName')}
                         name={['terminalDef', 'Index0', 'DccLocalCurrencyName']}>
                <Input/>
              </Form.Item>
            </Col>
            <Col flex="300px">
              <Form.Item label={t('form.DccAutoPycDownloadDays')}
                         name={['terminalDef', 'Index0', 'DccAutoPycDownloadDays']}
                         normalize={(value) => Number(value)}>
                <Input/>
              </Form.Item>
            </Col>
            <Col flex="300px">
              <Form.Item label={t('form.CtlsRemoveCardFlag')} name={['terminalDef', 'Index0', 'CtlsRemoveCardFlag']}>
                <Radio.Group>
                  <Radio value={true}>True</Radio>
                  <Radio value={false}>False</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col flex="300px">
              <Form.Item label={t('form.CtlsSupportRefundFlag')}
                         name={['terminalDef', 'Index0', 'CtlsSupportRefundFlag']}>
                <Radio.Group>
                  <Radio value={true}>True</Radio>
                  <Radio value={false}>False</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col flex="300px">
              <Form.Item label={t('form.TxnDuplicateFlag')} name={['terminalDef', 'Index0', 'TxnDuplicateFlag']}>
                <Radio.Group>
                  <Radio value={true}>True</Radio>
                  <Radio value={false}>False</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col flex="300px">
              <Form.Item label={t('form.DemoModeFlag')} name={['terminalDef', 'Index0', 'DemoModeFlag']}>
                <Radio.Group>
                  <Radio value={true}>True</Radio>
                  <Radio value={false}>False</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col flex="300px">
              <Form.Item label={t('form.SupportAutoSettleFlag')}
                         name={['terminalDef', 'Index0', 'SupportAutoSettleFlag']}>
                <Radio.Group>
                  <Radio value={true}>True</Radio>
                  <Radio value={false}>False</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col flex="300px">
              <Form.Item label={t('form.SupportFallbackFlag')} name={['terminalDef', 'Index0', 'SupportFallbackFlag']}>
                <Radio.Group>
                  <Radio value={true}>True</Radio>
                  <Radio value={false}>False</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col flex="300px">
              <Form.Item label={t('form.SupportPrintDetailReportPwdFlag')}
                         name={['terminalDef', 'Index0', 'SupportPrintDetailReportPwdFlag']}>
                <Radio.Group>
                  <Radio value={true}>True</Radio>
                  <Radio value={false}>False</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col flex="300px">
              <Form.Item label={t('form.MaskTxnButtonFlag')} name={['terminalDef', 'Index0', 'MaskTxnButtonFlag']}>
                <Radio.Group>
                  <Radio value={true}>True</Radio>
                  <Radio value={false}>False</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col flex="300px">
              <Form.Item label={t('form.DccSaleEnterTipsFlag')}
                         name={['terminalDef', 'Index0', 'DccSaleEnterTipsFlag']}>
                <Radio.Group>
                  <Radio value={true}>True</Radio>
                  <Radio value={false}>False</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col flex="300px">
              <Form.Item label={t('form.SettleNotFinishWrongMsgFlag')}
                         name={['terminalDef', 'Index0', 'SettleNotFinishWrongMsgFlag']}>
                <Radio.Group>
                  <Radio value={true}>True</Radio>
                  <Radio value={false}>False</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col flex="300px">
              <Form.Item label={t('form.SupportStoreIdFlag')}
                         name={['terminalDef', 'Index0', 'SupportStoreIdFlag']}>
                <Radio.Group>
                  <Radio value={true}>True</Radio>
                  <Radio value={false}>False</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col flex="300px">
              <Form.Item label={t('form.ManualKeyinNotPrintCustomerFlag')}
                         name={['terminalDef', 'Index0', 'ManualKeyinNotPrintCustomerFlag']}>
                <Radio.Group>
                  <Radio value={true}>True</Radio>
                  <Radio value={false}>False</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col flex="300px">
              <Form.Item label={t('form.TmsParameterDownload')}
                         name={['terminalDef', 'Index0', 'TmsParameterDownload']}>
                <Radio.Group>
                  <Radio value={true}>True</Radio>
                  <Radio value={false}>False</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col flex="300px">
              <Form.Item label={t('form.TmsApDownload')} name={['terminalDef', 'Index0', 'TmsApDownload']}>
                <Radio.Group>
                  <Radio value={true}>True</Radio>
                  <Radio value={false}>False</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col flex="300px">
              <Form.Item label={t('form.TmsInquire')} name={['terminalDef', 'Index0', 'TmsInquire']}>
                <Radio.Group>
                  <Radio value={true}>True</Radio>
                  <Radio value={false}>False</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col flex="300px">
              <Form.Item label={t('form.DetailCardNumMaskFlag')} name={['terminalDef', 'Index0', 'DetailCardNumMaskFlag']}>
                <Radio.Group>
                  <Radio value={true}>True</Radio>
                  <Radio value={false}>False</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col flex="300px">
              <Form.Item label={t('form.EcrCardNumMaskFlag')} name={['terminalDef', 'Index0', 'EcrCardNumMaskFlag']}>
                <Radio.Group>
                  <Radio value={true}>True</Radio>
                  <Radio value={false}>False</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col flex="300px">
              <Form.Item label={t('form.EcrSupportFlag')} name={['terminalDef', 'Index0', 'EcrSupportFlag']}>
                <Radio.Group>
                  <Radio value={true}>True</Radio>
                  <Radio value={false}>False</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col flex="300px">
              <Form.Item label={t('form.PrinterReceiptType')} name={['terminalDef', 'Index0', 'PrinterReceiptType']}
              normalize={(value) => Number(value)}>
                <Input/>
              </Form.Item>
            </Col>
            <Col flex="300px">
              <Form.Item label={t('form.PrinterLogoRequest')} name={['terminalDef', 'Index0', 'PrinterLogoRequest']}>
                <Radio.Group>
                  <Radio value={true}>True</Radio>
                  <Radio value={false}>False</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col flex="300px">
              <Form.Item label={t('form.PrinterReceiptHeader1')} name={['terminalDef', 'Index0', 'PrinterReceiptHeader1']}>
                <Input/>
              </Form.Item>
            </Col>
            <Col flex="300px">
              <Form.Item label={t('form.PrinterReceiptHeader2')} name={['terminalDef', 'Index0', 'PrinterReceiptHeader2']}>
                <Input/>
              </Form.Item>
            </Col>
            <Col flex="300px">
              <Form.Item label={t('form.PrinterReceiptHeader3')} name={['terminalDef', 'Index0', 'PrinterReceiptHeader3']}>
                <Input/>
              </Form.Item>
            </Col>
            <Col flex="300px">
              <Form.Item label={t('form.Iso8583Version')} name={['terminalDef', 'Index0', 'Iso8583Version']}>
                <Input/>
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <Divider/>
        <div>
          <Button type="primary" shape="round" style={{width: "200px", float: "right"}}
                  htmlType="submit" loading={btnLoading} onClick={() => form.submit()}>
            {t('button.save')}
          </Button>
        </div>
      </div>
    </Row>
  )
}