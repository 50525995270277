import {get, post} from "../../util/request";
import {IMerchantPrmId} from "./index";

export interface IMerchantPrmInfoBasic {
  merchantId: string;
  name: string;
  packageName: string;
  downloadTime: string;
  forceDownload: number;
}

export const merchantPrmInfoBasic = (params: IMerchantPrmId) => {
  return get<IMerchantPrmInfoBasic>({url: "/api/merchant-prm/info/basic", params})
}

export const saveMerchantPrmInfoBasic = (data: IMerchantPrmInfoBasic) => {
  return post({url: "/api/merchant-prm/info/basic", data})
}
