import React, { FC, useEffect, useState } from "react";
import { Alert, Button, Col, Divider, Form, Input, notification, Radio, Row, Tabs } from "antd";
import { useTranslation } from "react-i18next";
import {
  ITerminalPRMRInfoCPAcquirer,
  saveTerminalPrmInfoCPAcquirer,
  terminalPrmInfoCPAcquirer,
} from "../../../../services/terminalPrm";

interface IProps {
  terminalId: string;
  refresh: number;
}

export const TerminalPrmCPAcquirerDef: FC<IProps> = ({ terminalId, refresh }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [formValues, setFormValues] = useState<ITerminalPRMRInfoCPAcquirer>();
  const [btnLoading, setBtnLoading] = useState<boolean>(false);
  useEffect(
    function () {
      if (terminalId !== "") {
        terminalPrmInfoCPAcquirer({ terminal_id: terminalId }).then((res) => {
          if (res.status === 0) {
            setFormValues(res.data);
            form.setFieldsValue(res.data);
          }
        });
      }
    },
    [terminalId, refresh]
  );
  const saveForm = (values) => {
    if (formValues === undefined) {
      return;
    }
    let postData = formValues.acquirer;
    for (const valueKey in values["acquirer"]) {
      postData[valueKey] = values["acquirer"][valueKey];
    }
    setBtnLoading(true);
    saveTerminalPrmInfoCPAcquirer({
      id: formValues["id"],
      terminalId: terminalId,
      acquirer: postData,
    })
      .then((res) => {
        if (res.status === 0) {
          notification.success({
            message: "save success",
          });
        }
      })
      .finally(() => {
        setBtnLoading(false);
      });
  };
  const customAlert = (text: string) => {
    return (
      <>
        <Alert
          style={{ marginBottom: "20px" }}
          message={
            <div style={{ height: "40px", lineHeight: "40px", fontSize: "18px", fontWeight: "bold" }}>{text}</div>
          }
          type="info"
        />
      </>
    );
  };
  return (
    <Row gutter={10} hidden={terminalId === ""}>
      <Form layout="vertical" form={form} initialValues={formValues} onFinish={saveForm}>
        <Tabs defaultActiveKey="0" tabPosition="top" centered>
          <Tabs.TabPane tab="GPAP一般" key="0">
            {customAlert(t("title.basic_information"))}
            <Row gutter={30}>
              <Col flex="300px">
                <Form.Item
                  label={t("form.AcquirerPaymentId")}
                  name={["acquirer", "Index0", "AcquirerPaymentId"]}
                  normalize={(value) => Number(value)}
                >
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item label={t("form.AcquirerName")} name={["acquirer", "Index0", "AcquirerName"]}>
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item label={t("form.TerminalId")} name={["acquirer", "Index0", "TerminalId"]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item label={t("form.MerchantId")} name={["acquirer", "Index0", "MerchantId"]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item label={t("form.MerchantIdSecond")} name={["acquirer", "Index0", "MerchantIdSecond"]}>
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            {customAlert(t("title.co_pa_trans"))}
            <Row gutter={30}>
              <Col flex="300px">
                <Form.Item
                  label={t("form.TxnConnect")}
                  name={["acquirer", "Index0", "TxnConnect"]}
                  normalize={(value) => Number(value)}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item label={t("form.TxnIp")} name={["acquirer", "Index0", "TxnIp"]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item label={t("form.TxnPort")} name={["acquirer", "Index0", "TxnPort"]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item label={t("form.TxnUrl")} name={["acquirer", "Index0", "TxnUrl"]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item
                  label={t("form.TxnCarrierTimeout")}
                  name={["acquirer", "Index0", "TxnCarrierTimeout"]}
                  normalize={(value) => Number(value)}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item
                  label={t("form.TxnResponseTimeout")}
                  name={["acquirer", "Index0", "TxnResponseTimeout"]}
                  normalize={(value) => Number(value)}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item label={t("form.TxnNii")} name={["acquirer", "Index0", "TxnNii"]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item label={t("form.TxnTpdu")} name={["acquirer", "Index0", "TxnTpdu"]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item label={t("form.TxnSupportTlsFlag")} name={["acquirer", "Index0", "TxnSupportTlsFlag"]}>
                  <Radio.Group>
                    <Radio value={true}>True</Radio>
                    <Radio value={false}>False</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item label={t("form.TxnSupportTlsFlagWiFi")} name={["acquirer", "Index0", "TxnSupportTlsFlagWiFi"]}>
                  <Radio.Group>
                    <Radio value={true}>True</Radio>
                    <Radio value={false}>False</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item label={t("form.TxnSupportFlag")} name={["acquirer", "Index0", "TxnSupportFlag"]}>
                  <Radio.Group disabled>
                    <Radio value={true}>True</Radio>
                    <Radio value={false}>False</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item label={t("form.TxnSettleFlag")} name={["acquirer", "Index0", "TxnSettleFlag"]}>
                  <Radio.Group>
                    <Radio value={true}>True</Radio>
                    <Radio value={false}>False</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item
                  label={t("form.TxnStan")}
                  name={["acquirer", "Index0", "TxnStan"]}
                  normalize={(value) => Number(value)}
                >
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item
                  label={t("form.TxnBatchNumber")}
                  name={["acquirer", "Index0", "TxnBatchNumber"]}
                  normalize={(value) => Number(value)}
                >
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item
                  label={t("form.TxnInvoiceNum")}
                  name={["acquirer", "Index0", "TxnInvoiceNum"]}
                  normalize={(value) => Number(value)}
                >
                  <Input disabled />
                </Form.Item>
              </Col>
            </Row>
            {customAlert(t("title.co_pa_tis"))}
            <Row gutter={30}>
              <Col flex="300px">
                <Form.Item
                  label={t("form.TisConnect")}
                  name={["acquirer", "Index0", "TisConnect"]}
                  normalize={(value) => Number(value)}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item label={t("form.TisIp")} name={["acquirer", "Index0", "TisIp"]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item label={t("form.TisPort")} name={["acquirer", "Index0", "TisPort"]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item label={t("form.TisUrl")} name={["acquirer", "Index0", "TisUrl"]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item label={t("form.TisNii")} name={["acquirer", "Index0", "TisNii"]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item label={t("form.TisTpdu")} name={["acquirer", "Index0", "TisTpdu"]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item
                  label={t("form.TisCarrierTimeout")}
                  name={["acquirer", "Index0", "TisCarrierTimeout"]}
                  normalize={(value) => Number(value)}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item
                  label={t("form.TisResponseTimeout")}
                  name={["acquirer", "Index0", "TisResponseTimeout"]}
                  normalize={(value) => Number(value)}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item
                  label={t("form.TisStan")}
                  name={["acquirer", "Index0", "TisStan"]}
                  normalize={(value) => Number(value)}
                >
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item
                  label={t("form.TisBatchNumber")}
                  name={["acquirer", "Index0", "TisBatchNumber"]}
                  normalize={(value) => Number(value)}
                >
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item
                  label={t("form.TisInvoiceNum")}
                  name={["acquirer", "Index0", "TisInvoiceNum"]}
                  normalize={(value) => Number(value)}
                >
                  <Input disabled />
                </Form.Item>
              </Col>

              <Col flex="300px">
                <Form.Item label={t("form.TisSupportTlsFlag")} name={["acquirer", "Index0", "TisSupportTlsFlag"]}>
                  <Radio.Group>
                    <Radio value={true}>True</Radio>
                    <Radio value={false}>False</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item label={t("form.TisSupportFlag")} name={["acquirer", "Index0", "TisSupportFlag"]}>
                  <Radio.Group>
                    <Radio value={true}>True</Radio>
                    <Radio value={false}>False</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item label={t("form.TisSettleFlag")} name={["acquirer", "Index0", "TisSettleFlag"]}>
                  <Radio.Group>
                    <Radio value={true}>True</Radio>
                    <Radio value={false}>False</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item
                  label={t("form.TisFailCount")}
                  name={["acquirer", "Index0", "TisFailCount"]}
                  normalize={(value) => Number(value)}
                >
                  <Input disabled />
                </Form.Item>
              </Col>
            </Row>
          </Tabs.TabPane>
          <Tabs.TabPane tab="GPAP分期" key="1">
            {customAlert(t("title.basic_information"))}
            <Row gutter={30}>
              <Col flex="300px">
                <Form.Item
                  label={t("form.AcquirerPaymentId")}
                  name={["acquirer", "Index1", "AcquirerPaymentId"]}
                  normalize={(value) => Number(value)}
                >
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item label={t("form.AcquirerName")} name={["acquirer", "Index1", "AcquirerName"]}>
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item label={t("form.TerminalId")} name={["acquirer", "Index1", "TerminalId"]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item label={t("form.MerchantId")} name={["acquirer", "Index1", "MerchantId"]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item label={t("form.MerchantIdSecond")} name={["acquirer", "Index1", "MerchantIdSecond"]}>
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            {customAlert(t("title.co_pa_trans"))}
            <Row gutter={30}>
              <Col flex="300px">
                <Form.Item
                  label={t("form.TxnConnect")}
                  name={["acquirer", "Index1", "TxnConnect"]}
                  normalize={(value) => Number(value)}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item label={t("form.TxnIp")} name={["acquirer", "Index1", "TxnIp"]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item label={t("form.TxnPort")} name={["acquirer", "Index1", "TxnPort"]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item label={t("form.TxnUrl")} name={["acquirer", "Index1", "TxnUrl"]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item
                  label={t("form.TxnCarrierTimeout")}
                  name={["acquirer", "Index1", "TxnCarrierTimeout"]}
                  normalize={(value) => Number(value)}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item
                  label={t("form.TxnResponseTimeout")}
                  name={["acquirer", "Index1", "TxnResponseTimeout"]}
                  normalize={(value) => Number(value)}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item label={t("form.TxnNii")} name={["acquirer", "Index1", "TxnNii"]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item label={t("form.TxnTpdu")} name={["acquirer", "Index1", "TxnTpdu"]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item label={t("form.TxnSupportTlsFlag")} name={["acquirer", "Index1", "TxnSupportTlsFlag"]}>
                  <Radio.Group>
                    <Radio value={true}>True</Radio>
                    <Radio value={false}>False</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item label={t("form.TxnSupportTlsFlagWiFi")} name={["acquirer", "Index1", "TxnSupportTlsFlagWiFi"]}>
                  <Radio.Group>
                    <Radio value={true}>True</Radio>
                    <Radio value={false}>False</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item label={t("form.TxnSupportFlag")} name={["acquirer", "Index1", "TxnSupportFlag"]}>
                  <Radio.Group disabled>
                    <Radio value={true}>True</Radio>
                    <Radio value={false}>False</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item label={t("form.TxnSettleFlag")} name={["acquirer", "Index1", "TxnSettleFlag"]}>
                  <Radio.Group>
                    <Radio value={true}>True</Radio>
                    <Radio value={false}>False</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item
                  label={t("form.TxnStan")}
                  name={["acquirer", "Index1", "TxnStan"]}
                  normalize={(value) => Number(value)}
                >
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item
                  label={t("form.TxnBatchNumber")}
                  name={["acquirer", "Index1", "TxnBatchNumber"]}
                  normalize={(value) => Number(value)}
                >
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item
                  label={t("form.TxnInvoiceNum")}
                  name={["acquirer", "Index1", "TxnInvoiceNum"]}
                  normalize={(value) => Number(value)}
                >
                  <Input disabled />
                </Form.Item>
              </Col>
            </Row>
            {customAlert(t("title.co_pa_tis"))}
            <Row gutter={30}>
              <Col flex="300px">
                <Form.Item
                  label={t("form.TisConnect")}
                  name={["acquirer", "Index1", "TisConnect"]}
                  normalize={(value) => Number(value)}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item label={t("form.TisIp")} name={["acquirer", "Index1", "TisIp"]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item label={t("form.TisPort")} name={["acquirer", "Index1", "TisPort"]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item label={t("form.TisUrl")} name={["acquirer", "Index1", "TisUrl"]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item label={t("form.TisNii")} name={["acquirer", "Index1", "TisNii"]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item label={t("form.TisTpdu")} name={["acquirer", "Index1", "TisTpdu"]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item
                  label={t("form.TisCarrierTimeout")}
                  name={["acquirer", "Index1", "TisCarrierTimeout"]}
                  normalize={(value) => Number(value)}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item
                  label={t("form.TisResponseTimeout")}
                  name={["acquirer", "Index1", "TisResponseTimeout"]}
                  normalize={(value) => Number(value)}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item
                  label={t("form.TisStan")}
                  name={["acquirer", "Index1", "TisStan"]}
                  normalize={(value) => Number(value)}
                >
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item
                  label={t("form.TisBatchNumber")}
                  name={["acquirer", "Index1", "TisBatchNumber"]}
                  normalize={(value) => Number(value)}
                >
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item
                  label={t("form.TisInvoiceNum")}
                  name={["acquirer", "Index1", "TisInvoiceNum"]}
                  normalize={(value) => Number(value)}
                >
                  <Input disabled />
                </Form.Item>
              </Col>

              <Col flex="300px">
                <Form.Item label={t("form.TisSupportTlsFlag")} name={["acquirer", "Index1", "TisSupportTlsFlag"]}>
                  <Radio.Group>
                    <Radio value={true}>True</Radio>
                    <Radio value={false}>False</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item label={t("form.TisSupportFlag")} name={["acquirer", "Index1", "TisSupportFlag"]}>
                  <Radio.Group>
                    <Radio value={true}>True</Radio>
                    <Radio value={false}>False</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item label={t("form.TisSettleFlag")} name={["acquirer", "Index1", "TisSettleFlag"]}>
                  <Radio.Group>
                    <Radio value={true}>True</Radio>
                    <Radio value={false}>False</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item
                  label={t("form.TisFailCount")}
                  name={["acquirer", "Index1", "TisFailCount"]}
                  normalize={(value) => Number(value)}
                >
                  <Input disabled />
                </Form.Item>
              </Col>
            </Row>
          </Tabs.TabPane>
          <Tabs.TabPane tab="GPAP銀聯" key="2">
            {customAlert(t("title.basic_information"))}
            <Row gutter={30}>
              <Col flex="300px">
                <Form.Item
                  label={t("form.AcquirerPaymentId")}
                  name={["acquirer", "Index2", "AcquirerPaymentId"]}
                  normalize={(value) => Number(value)}
                >
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item label={t("form.AcquirerName")} name={["acquirer", "Index2", "AcquirerName"]}>
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item label={t("form.TerminalId")} name={["acquirer", "Index2", "TerminalId"]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item label={t("form.MerchantId")} name={["acquirer", "Index2", "MerchantId"]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item label={t("form.MerchantIdSecond")} name={["acquirer", "Index2", "MerchantIdSecond"]}>
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            {customAlert(t("title.co_pa_trans"))}
            <Row gutter={30}>
              <Col flex="300px">
                <Form.Item
                  label={t("form.TxnConnect")}
                  name={["acquirer", "Index2", "TxnConnect"]}
                  normalize={(value) => Number(value)}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item label={t("form.TxnIp")} name={["acquirer", "Index2", "TxnIp"]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item label={t("form.TxnPort")} name={["acquirer", "Index2", "TxnPort"]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item label={t("form.TxnUrl")} name={["acquirer", "Index2", "TxnUrl"]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item
                  label={t("form.TxnCarrierTimeout")}
                  name={["acquirer", "Index2", "TxnCarrierTimeout"]}
                  normalize={(value) => Number(value)}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item
                  label={t("form.TxnResponseTimeout")}
                  name={["acquirer", "Index2", "TxnResponseTimeout"]}
                  normalize={(value) => Number(value)}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item label={t("form.TxnNii")} name={["acquirer", "Index2", "TxnNii"]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item label={t("form.TxnTpdu")} name={["acquirer", "Index2", "TxnTpdu"]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item label={t("form.TxnSupportTlsFlag")} name={["acquirer", "Index2", "TxnSupportTlsFlag"]}>
                  <Radio.Group>
                    <Radio value={true}>True</Radio>
                    <Radio value={false}>False</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item label={t("form.TxnSupportTlsFlagWiFi")} name={["acquirer", "Index2", "TxnSupportTlsFlagWiFi"]}>
                  <Radio.Group>
                    <Radio value={true}>True</Radio>
                    <Radio value={false}>False</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item label={t("form.TxnSupportFlag")} name={["acquirer", "Index2", "TxnSupportFlag"]}>
                  <Radio.Group disabled>
                    <Radio value={true}>True</Radio>
                    <Radio value={false}>False</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item label={t("form.TxnSettleFlag")} name={["acquirer", "Index2", "TxnSettleFlag"]}>
                  <Radio.Group>
                    <Radio value={true}>True</Radio>
                    <Radio value={false}>False</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item
                  label={t("form.TxnStan")}
                  name={["acquirer", "Index2", "TxnStan"]}
                  normalize={(value) => Number(value)}
                >
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item
                  label={t("form.TxnBatchNumber")}
                  name={["acquirer", "Index2", "TxnBatchNumber"]}
                  normalize={(value) => Number(value)}
                >
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item
                  label={t("form.TxnInvoiceNum")}
                  name={["acquirer", "Index2", "TxnInvoiceNum"]}
                  normalize={(value) => Number(value)}
                >
                  <Input disabled />
                </Form.Item>
              </Col>
            </Row>
            {customAlert(t("title.co_pa_tis"))}
            <Row gutter={30}>
              <Col flex="300px">
                <Form.Item
                  label={t("form.TisConnect")}
                  name={["acquirer", "Index2", "TisConnect"]}
                  normalize={(value) => Number(value)}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item label={t("form.TisIp")} name={["acquirer", "Index2", "TisIp"]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item label={t("form.TisPort")} name={["acquirer", "Index2", "TisPort"]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item label={t("form.TisUrl")} name={["acquirer", "Index2", "TisUrl"]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item label={t("form.TisNii")} name={["acquirer", "Index2", "TisNii"]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item label={t("form.TisTpdu")} name={["acquirer", "Index2", "TisTpdu"]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item
                  label={t("form.TisCarrierTimeout")}
                  name={["acquirer", "Index2", "TisCarrierTimeout"]}
                  normalize={(value) => Number(value)}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item
                  label={t("form.TisResponseTimeout")}
                  name={["acquirer", "Index2", "TisResponseTimeout"]}
                  normalize={(value) => Number(value)}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item
                  label={t("form.TisStan")}
                  name={["acquirer", "Index2", "TisStan"]}
                  normalize={(value) => Number(value)}
                >
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item
                  label={t("form.TisBatchNumber")}
                  name={["acquirer", "Index2", "TisBatchNumber"]}
                  normalize={(value) => Number(value)}
                >
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item
                  label={t("form.TisInvoiceNum")}
                  name={["acquirer", "Index2", "TisInvoiceNum"]}
                  normalize={(value) => Number(value)}
                >
                  <Input disabled />
                </Form.Item>
              </Col>

              <Col flex="300px">
                <Form.Item label={t("form.TisSupportTlsFlag")} name={["acquirer", "Index2", "TisSupportTlsFlag"]}>
                  <Radio.Group>
                    <Radio value={true}>True</Radio>
                    <Radio value={false}>False</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item label={t("form.TisSupportFlag")} name={["acquirer", "Index2", "TisSupportFlag"]}>
                  <Radio.Group>
                    <Radio value={true}>True</Radio>
                    <Radio value={false}>False</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item label={t("form.TisSettleFlag")} name={["acquirer", "Index2", "TisSettleFlag"]}>
                  <Radio.Group>
                    <Radio value={true}>True</Radio>
                    <Radio value={false}>False</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item
                  label={t("form.TisFailCount")}
                  name={["acquirer", "Index2", "TisFailCount"]}
                  normalize={(value) => Number(value)}
                >
                  <Input disabled />
                </Form.Item>
              </Col>
            </Row>
          </Tabs.TabPane>
          <Tabs.TabPane tab="SMARTPAY交易" key="3">
            {customAlert(t("title.basic_information"))}
            <Row gutter={30}>
              <Col flex="300px">
                <Form.Item
                  label={t("form.AcquirerPaymentId")}
                  name={["acquirer", "Index3", "AcquirerPaymentId"]}
                  normalize={(value) => Number(value)}
                >
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item label={t("form.AcquirerName")} name={["acquirer", "Index3", "AcquirerName"]}>
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item label={t("form.TerminalId")} name={["acquirer", "Index3", "TerminalId"]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item label={t("form.MerchantId")} name={["acquirer", "Index3", "MerchantId"]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item label={t("form.MerchantIdSecond")} name={["acquirer", "Index3", "MerchantIdSecond"]}>
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            {customAlert(t("title.co_pa_trans"))}
            <Row gutter={30}>
              <Col flex="300px">
                <Form.Item
                  label={t("form.TxnConnect")}
                  name={["acquirer", "Index3", "TxnConnect"]}
                  normalize={(value) => Number(value)}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item label={t("form.TxnIp")} name={["acquirer", "Index3", "TxnIp"]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item label={t("form.TxnPort")} name={["acquirer", "Index3", "TxnPort"]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item label={t("form.TxnUrl")} name={["acquirer", "Index3", "TxnUrl"]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item
                  label={t("form.TxnCarrierTimeout")}
                  name={["acquirer", "Index3", "TxnCarrierTimeout"]}
                  normalize={(value) => Number(value)}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item
                  label={t("form.TxnResponseTimeout")}
                  name={["acquirer", "Index3", "TxnResponseTimeout"]}
                  normalize={(value) => Number(value)}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item label={t("form.TxnNii")} name={["acquirer", "Index3", "TxnNii"]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item label={t("form.TxnTpdu")} name={["acquirer", "Index3", "TxnTpdu"]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item label={t("form.TxnSupportTlsFlag")} name={["acquirer", "Index3", "TxnSupportTlsFlag"]}>
                  <Radio.Group>
                    <Radio value={true}>True</Radio>
                    <Radio value={false}>False</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item label={t("form.TxnSupportTlsFlagWiFi")} name={["acquirer", "Index3", "TxnSupportTlsFlagWiFi"]}>
                  <Radio.Group>
                    <Radio value={true}>True</Radio>
                    <Radio value={false}>False</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item label={t("form.TxnSupportFlag")} name={["acquirer", "Index3", "TxnSupportFlag"]}>
                  <Radio.Group disabled>
                    <Radio value={true}>True</Radio>
                    <Radio value={false}>False</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item label={t("form.TxnSettleFlag")} name={["acquirer", "Index3", "TxnSettleFlag"]}>
                  <Radio.Group>
                    <Radio value={true}>True</Radio>
                    <Radio value={false}>False</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item
                  label={t("form.TxnStan")}
                  name={["acquirer", "Index3", "TxnStan"]}
                  normalize={(value) => Number(value)}
                >
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item
                  label={t("form.TxnBatchNumber")}
                  name={["acquirer", "Index3", "TxnBatchNumber"]}
                  normalize={(value) => Number(value)}
                >
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item
                  label={t("form.TxnInvoiceNum")}
                  name={["acquirer", "Index3", "TxnInvoiceNum"]}
                  normalize={(value) => Number(value)}
                >
                  <Input disabled />
                </Form.Item>
              </Col>
            </Row>
            {customAlert(t("title.co_pa_tis"))}
            <Row gutter={30}>
              <Col flex="300px">
                <Form.Item
                  label={t("form.TisConnect")}
                  name={["acquirer", "Index3", "TisConnect"]}
                  normalize={(value) => Number(value)}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item label={t("form.TisIp")} name={["acquirer", "Index3", "TisIp"]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item label={t("form.TisPort")} name={["acquirer", "Index3", "TisPort"]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item label={t("form.TisUrl")} name={["acquirer", "Index3", "TisUrl"]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item label={t("form.TisNii")} name={["acquirer", "Index3", "TisNii"]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item label={t("form.TisTpdu")} name={["acquirer", "Index3", "TisTpdu"]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item
                  label={t("form.TisCarrierTimeout")}
                  name={["acquirer", "Index3", "TisCarrierTimeout"]}
                  normalize={(value) => Number(value)}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item
                  label={t("form.TisResponseTimeout")}
                  name={["acquirer", "Index3", "TisResponseTimeout"]}
                  normalize={(value) => Number(value)}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item
                  label={t("form.TisStan")}
                  name={["acquirer", "Index3", "TisStan"]}
                  normalize={(value) => Number(value)}
                >
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item
                  label={t("form.TisBatchNumber")}
                  name={["acquirer", "Index3", "TisBatchNumber"]}
                  normalize={(value) => Number(value)}
                >
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item
                  label={t("form.TisInvoiceNum")}
                  name={["acquirer", "Index3", "TisInvoiceNum"]}
                  normalize={(value) => Number(value)}
                >
                  <Input disabled />
                </Form.Item>
              </Col>

              <Col flex="300px">
                <Form.Item label={t("form.TisSupportTlsFlag")} name={["acquirer", "Index3", "TisSupportTlsFlag"]}>
                  <Radio.Group>
                    <Radio value={true}>True</Radio>
                    <Radio value={false}>False</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item label={t("form.TisSupportFlag")} name={["acquirer", "Index3", "TisSupportFlag"]}>
                  <Radio.Group>
                    <Radio value={true}>True</Radio>
                    <Radio value={false}>False</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item label={t("form.TisSettleFlag")} name={["acquirer", "Index3", "TisSettleFlag"]}>
                  <Radio.Group>
                    <Radio value={true}>True</Radio>
                    <Radio value={false}>False</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item
                  label={t("form.TisFailCount")}
                  name={["acquirer", "Index3", "TisFailCount"]}
                  normalize={(value) => Number(value)}
                >
                  <Input disabled />
                </Form.Item>
              </Col>
            </Row>
          </Tabs.TabPane>
          <Tabs.TabPane tab="GPAP DCC" key="4">
            {customAlert(t("title.basic_information"))}
            <Row gutter={30}>
              <Col flex="300px">
                <Form.Item
                  label={t("form.AcquirerPaymentId")}
                  name={["acquirer", "Index4", "AcquirerPaymentId"]}
                  normalize={(value) => Number(value)}
                >
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item label={t("form.AcquirerName")} name={["acquirer", "Index4", "AcquirerName"]}>
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item label={t("form.TerminalId")} name={["acquirer", "Index4", "TerminalId"]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item label={t("form.MerchantId")} name={["acquirer", "Index4", "MerchantId"]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item label={t("form.MerchantIdSecond")} name={["acquirer", "Index4", "MerchantIdSecond"]}>
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            {customAlert(t("title.co_pa_trans"))}
            <Row gutter={30}>
              <Col flex="300px">
                <Form.Item
                  label={t("form.TxnConnect")}
                  name={["acquirer", "Index4", "TxnConnect"]}
                  normalize={(value) => Number(value)}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item label={t("form.TxnIp")} name={["acquirer", "Index4", "TxnIp"]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item label={t("form.TxnPort")} name={["acquirer", "Index4", "TxnPort"]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item label={t("form.TxnUrl")} name={["acquirer", "Index4", "TxnUrl"]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item
                  label={t("form.TxnCarrierTimeout")}
                  name={["acquirer", "Index4", "TxnCarrierTimeout"]}
                  normalize={(value) => Number(value)}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item
                  label={t("form.TxnResponseTimeout")}
                  name={["acquirer", "Index4", "TxnResponseTimeout"]}
                  normalize={(value) => Number(value)}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item label={t("form.TxnNii")} name={["acquirer", "Index4", "TxnNii"]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item label={t("form.TxnTpdu")} name={["acquirer", "Index4", "TxnTpdu"]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item label={t("form.TxnSupportTlsFlag")} name={["acquirer", "Index4", "TxnSupportTlsFlag"]}>
                  <Radio.Group>
                    <Radio value={true}>True</Radio>
                    <Radio value={false}>False</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item label={t("form.TxnSupportTlsFlagWiFi")} name={["acquirer", "Index4", "TxnSupportTlsFlagWiFi"]}>
                  <Radio.Group>
                    <Radio value={true}>True</Radio>
                    <Radio value={false}>False</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item label={t("form.TxnSupportFlag")} name={["acquirer", "Index4", "TxnSupportFlag"]}>
                  <Radio.Group disabled>
                    <Radio value={true}>True</Radio>
                    <Radio value={false}>False</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item label={t("form.TxnSettleFlag")} name={["acquirer", "Index4", "TxnSettleFlag"]}>
                  <Radio.Group>
                    <Radio value={true}>True</Radio>
                    <Radio value={false}>False</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item
                  label={t("form.TxnStan")}
                  name={["acquirer", "Index4", "TxnStan"]}
                  normalize={(value) => Number(value)}
                >
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item
                  label={t("form.TxnBatchNumber")}
                  name={["acquirer", "Index4", "TxnBatchNumber"]}
                  normalize={(value) => Number(value)}
                >
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item
                  label={t("form.TxnInvoiceNum")}
                  name={["acquirer", "Index4", "TxnInvoiceNum"]}
                  normalize={(value) => Number(value)}
                >
                  <Input disabled />
                </Form.Item>
              </Col>
            </Row>
            {customAlert(t("title.co_pa_tis"))}
            <Row gutter={30}>
              <Col flex="300px">
                <Form.Item
                  label={t("form.TisConnect")}
                  name={["acquirer", "Index4", "TisConnect"]}
                  normalize={(value) => Number(value)}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item label={t("form.TisIp")} name={["acquirer", "Index4", "TisIp"]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item label={t("form.TisPort")} name={["acquirer", "Index4", "TisPort"]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item label={t("form.TisUrl")} name={["acquirer", "Index4", "TisUrl"]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item label={t("form.TisNii")} name={["acquirer", "Index4", "TisNii"]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item label={t("form.TisTpdu")} name={["acquirer", "Index4", "TisTpdu"]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item
                  label={t("form.TisCarrierTimeout")}
                  name={["acquirer", "Index4", "TisCarrierTimeout"]}
                  normalize={(value) => Number(value)}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item
                  label={t("form.TisResponseTimeout")}
                  name={["acquirer", "Index4", "TisResponseTimeout"]}
                  normalize={(value) => Number(value)}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item
                  label={t("form.TisStan")}
                  name={["acquirer", "Index4", "TisStan"]}
                  normalize={(value) => Number(value)}
                >
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item
                  label={t("form.TisBatchNumber")}
                  name={["acquirer", "Index4", "TisBatchNumber"]}
                  normalize={(value) => Number(value)}
                >
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item
                  label={t("form.TisInvoiceNum")}
                  name={["acquirer", "Index4", "TisInvoiceNum"]}
                  normalize={(value) => Number(value)}
                >
                  <Input disabled />
                </Form.Item>
              </Col>

              <Col flex="300px">
                <Form.Item label={t("form.TisSupportTlsFlag")} name={["acquirer", "Index4", "TisSupportTlsFlag"]}>
                  <Radio.Group>
                    <Radio value={true}>True</Radio>
                    <Radio value={false}>False</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item label={t("form.TisSupportFlag")} name={["acquirer", "Index4", "TisSupportFlag"]}>
                  <Radio.Group>
                    <Radio value={true}>True</Radio>
                    <Radio value={false}>False</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item label={t("form.TisSettleFlag")} name={["acquirer", "Index4", "TisSettleFlag"]}>
                  <Radio.Group>
                    <Radio value={true}>True</Radio>
                    <Radio value={false}>False</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item
                  label={t("form.TisFailCount")}
                  name={["acquirer", "Index4", "TisFailCount"]}
                  normalize={(value) => Number(value)}
                >
                  <Input disabled />
                </Form.Item>
              </Col>
            </Row>
          </Tabs.TabPane>
          <Tabs.TabPane tab="GPAP郵購" key="5">
            {customAlert(t("title.basic_information"))}
            <Row gutter={30}>
              <Col flex="300px">
                <Form.Item
                  label={t("form.AcquirerPaymentId")}
                  name={["acquirer", "Index5", "AcquirerPaymentId"]}
                  normalize={(value) => Number(value)}
                >
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item label={t("form.AcquirerName")} name={["acquirer", "Index5", "AcquirerName"]}>
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item label={t("form.TerminalId")} name={["acquirer", "Index5", "TerminalId"]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item label={t("form.MerchantId")} name={["acquirer", "Index5", "MerchantId"]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item label={t("form.MerchantIdSecond")} name={["acquirer", "Index5", "MerchantIdSecond"]}>
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            {customAlert(t("title.co_pa_trans"))}
            <Row gutter={30}>
              <Col flex="300px">
                <Form.Item
                  label={t("form.TxnConnect")}
                  name={["acquirer", "Index5", "TxnConnect"]}
                  normalize={(value) => Number(value)}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item label={t("form.TxnIp")} name={["acquirer", "Index5", "TxnIp"]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item label={t("form.TxnPort")} name={["acquirer", "Index5", "TxnPort"]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item label={t("form.TxnUrl")} name={["acquirer", "Index5", "TxnUrl"]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item
                  label={t("form.TxnCarrierTimeout")}
                  name={["acquirer", "Index5", "TxnCarrierTimeout"]}
                  normalize={(value) => Number(value)}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item
                  label={t("form.TxnResponseTimeout")}
                  name={["acquirer", "Index5", "TxnResponseTimeout"]}
                  normalize={(value) => Number(value)}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item label={t("form.TxnNii")} name={["acquirer", "Index5", "TxnNii"]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item label={t("form.TxnTpdu")} name={["acquirer", "Index5", "TxnTpdu"]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item label={t("form.TxnSupportTlsFlag")} name={["acquirer", "Index5", "TxnSupportTlsFlag"]}>
                  <Radio.Group>
                    <Radio value={true}>True</Radio>
                    <Radio value={false}>False</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item label={t("form.TxnSupportTlsFlagWiFi")} name={["acquirer", "Index5", "TxnSupportTlsFlagWiFi"]}>
                  <Radio.Group>
                    <Radio value={true}>True</Radio>
                    <Radio value={false}>False</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item label={t("form.TxnSupportFlag")} name={["acquirer", "Index5", "TxnSupportFlag"]}>
                  <Radio.Group disabled>
                    <Radio value={true}>True</Radio>
                    <Radio value={false}>False</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item label={t("form.TxnSettleFlag")} name={["acquirer", "Index5", "TxnSettleFlag"]}>
                  <Radio.Group>
                    <Radio value={true}>True</Radio>
                    <Radio value={false}>False</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item
                  label={t("form.TxnStan")}
                  name={["acquirer", "Index5", "TxnStan"]}
                  normalize={(value) => Number(value)}
                >
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item
                  label={t("form.TxnBatchNumber")}
                  name={["acquirer", "Index5", "TxnBatchNumber"]}
                  normalize={(value) => Number(value)}
                >
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item
                  label={t("form.TxnInvoiceNum")}
                  name={["acquirer", "Index5", "TxnInvoiceNum"]}
                  normalize={(value) => Number(value)}
                >
                  <Input disabled />
                </Form.Item>
              </Col>
            </Row>
            {customAlert(t("title.co_pa_tis"))}
            <Row gutter={30}>
              <Col flex="300px">
                <Form.Item
                  label={t("form.TisConnect")}
                  name={["acquirer", "Index5", "TisConnect"]}
                  normalize={(value) => Number(value)}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item label={t("form.TisIp")} name={["acquirer", "Index5", "TisIp"]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item label={t("form.TisPort")} name={["acquirer", "Index5", "TisPort"]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item label={t("form.TisUrl")} name={["acquirer", "Index5", "TisUrl"]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item label={t("form.TisNii")} name={["acquirer", "Index5", "TisNii"]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item label={t("form.TisTpdu")} name={["acquirer", "Index5", "TisTpdu"]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item
                  label={t("form.TisCarrierTimeout")}
                  name={["acquirer", "Index5", "TisCarrierTimeout"]}
                  normalize={(value) => Number(value)}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item
                  label={t("form.TisResponseTimeout")}
                  name={["acquirer", "Index5", "TisResponseTimeout"]}
                  normalize={(value) => Number(value)}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item
                  label={t("form.TisStan")}
                  name={["acquirer", "Index5", "TisStan"]}
                  normalize={(value) => Number(value)}
                >
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item
                  label={t("form.TisBatchNumber")}
                  name={["acquirer", "Index5", "TisBatchNumber"]}
                  normalize={(value) => Number(value)}
                >
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item
                  label={t("form.TisInvoiceNum")}
                  name={["acquirer", "Index5", "TisInvoiceNum"]}
                  normalize={(value) => Number(value)}
                >
                  <Input disabled />
                </Form.Item>
              </Col>

              <Col flex="300px">
                <Form.Item label={t("form.TisSupportTlsFlag")} name={["acquirer", "Index5", "TisSupportTlsFlag"]}>
                  <Radio.Group>
                    <Radio value={true}>True</Radio>
                    <Radio value={false}>False</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item label={t("form.TisSupportFlag")} name={["acquirer", "Index5", "TisSupportFlag"]}>
                  <Radio.Group>
                    <Radio value={true}>True</Radio>
                    <Radio value={false}>False</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item label={t("form.TisSettleFlag")} name={["acquirer", "Index5", "TisSettleFlag"]}>
                  <Radio.Group>
                    <Radio value={true}>True</Radio>
                    <Radio value={false}>False</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item
                  label={t("form.TisFailCount")}
                  name={["acquirer", "Index5", "TisFailCount"]}
                  normalize={(value) => Number(value)}
                >
                  <Input disabled />
                </Form.Item>
              </Col>
            </Row>
          </Tabs.TabPane>
          <Tabs.TabPane tab="AMEX美國運通收單" key="6">
            {customAlert(t("title.basic_information"))}
            <Row gutter={30}>
              <Col flex="300px">
                <Form.Item
                  label={t("form.AcquirerPaymentId")}
                  name={["acquirer", "Index6", "AcquirerPaymentId"]}
                  normalize={(value) => Number(value)}
                >
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item label={t("form.AcquirerName")} name={["acquirer", "Index6", "AcquirerName"]}>
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item label={t("form.TerminalId")} name={["acquirer", "Index6", "TerminalId"]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item label={t("form.MerchantId")} name={["acquirer", "Index6", "MerchantId"]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item label={t("form.MerchantIdSecond")} name={["acquirer", "Index6", "MerchantIdSecond"]}>
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            {customAlert(t("title.co_pa_trans"))}
            <Row gutter={30}>
              <Col flex="300px">
                <Form.Item
                  label={t("form.TxnConnect")}
                  name={["acquirer", "Index6", "TxnConnect"]}
                  normalize={(value) => Number(value)}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item label={t("form.TxnIp")} name={["acquirer", "Index6", "TxnIp"]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item label={t("form.TxnPort")} name={["acquirer", "Index6", "TxnPort"]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item label={t("form.TxnUrl")} name={["acquirer", "Index6", "TxnUrl"]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item
                  label={t("form.TxnCarrierTimeout")}
                  name={["acquirer", "Index6", "TxnCarrierTimeout"]}
                  normalize={(value) => Number(value)}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item
                  label={t("form.TxnResponseTimeout")}
                  name={["acquirer", "Index6", "TxnResponseTimeout"]}
                  normalize={(value) => Number(value)}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item label={t("form.TxnNii")} name={["acquirer", "Index6", "TxnNii"]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item label={t("form.TxnTpdu")} name={["acquirer", "Index6", "TxnTpdu"]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item label={t("form.TxnSupportTlsFlag")} name={["acquirer", "Index6", "TxnSupportTlsFlag"]}>
                  <Radio.Group>
                    <Radio value={true}>True</Radio>
                    <Radio value={false}>False</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item label={t("form.TxnSupportTlsFlagWiFi")} name={["acquirer", "Index6", "TxnSupportTlsFlagWiFi"]}>
                  <Radio.Group>
                    <Radio value={true}>True</Radio>
                    <Radio value={false}>False</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item label={t("form.TxnSupportFlag")} name={["acquirer", "Index6", "TxnSupportFlag"]}>
                  <Radio.Group disabled>
                    <Radio value={true}>True</Radio>
                    <Radio value={false}>False</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item label={t("form.TxnSettleFlag")} name={["acquirer", "Index6", "TxnSettleFlag"]}>
                  <Radio.Group>
                    <Radio value={true}>True</Radio>
                    <Radio value={false}>False</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item
                  label={t("form.TxnStan")}
                  name={["acquirer", "Index6", "TxnStan"]}
                  normalize={(value) => Number(value)}
                >
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item
                  label={t("form.TxnBatchNumber")}
                  name={["acquirer", "Index6", "TxnBatchNumber"]}
                  normalize={(value) => Number(value)}
                >
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item
                  label={t("form.TxnInvoiceNum")}
                  name={["acquirer", "Index6", "TxnInvoiceNum"]}
                  normalize={(value) => Number(value)}
                >
                  <Input disabled />
                </Form.Item>
              </Col>
            </Row>
            {customAlert(t("title.co_pa_tis"))}
            <Row gutter={30}>
              <Col flex="300px">
                <Form.Item
                  label={t("form.TisConnect")}
                  name={["acquirer", "Index6", "TisConnect"]}
                  normalize={(value) => Number(value)}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item label={t("form.TisIp")} name={["acquirer", "Index6", "TisIp"]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item label={t("form.TisPort")} name={["acquirer", "Index6", "TisPort"]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item label={t("form.TisUrl")} name={["acquirer", "Index6", "TisUrl"]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item label={t("form.TisNii")} name={["acquirer", "Index6", "TisNii"]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item label={t("form.TisTpdu")} name={["acquirer", "Index6", "TisTpdu"]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item
                  label={t("form.TisCarrierTimeout")}
                  name={["acquirer", "Index6", "TisCarrierTimeout"]}
                  normalize={(value) => Number(value)}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item
                  label={t("form.TisResponseTimeout")}
                  name={["acquirer", "Index6", "TisResponseTimeout"]}
                  normalize={(value) => Number(value)}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item
                  label={t("form.TisStan")}
                  name={["acquirer", "Index6", "TisStan"]}
                  normalize={(value) => Number(value)}
                >
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item
                  label={t("form.TisBatchNumber")}
                  name={["acquirer", "Index6", "TisBatchNumber"]}
                  normalize={(value) => Number(value)}
                >
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item
                  label={t("form.TisInvoiceNum")}
                  name={["acquirer", "Index6", "TisInvoiceNum"]}
                  normalize={(value) => Number(value)}
                >
                  <Input disabled />
                </Form.Item>
              </Col>

              <Col flex="300px">
                <Form.Item label={t("form.TisSupportTlsFlag")} name={["acquirer", "Index6", "TisSupportTlsFlag"]}>
                  <Radio.Group>
                    <Radio value={true}>True</Radio>
                    <Radio value={false}>False</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item label={t("form.TisSupportFlag")} name={["acquirer", "Index6", "TisSupportFlag"]}>
                  <Radio.Group>
                    <Radio value={true}>True</Radio>
                    <Radio value={false}>False</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item label={t("form.TisSettleFlag")} name={["acquirer", "Index6", "TisSettleFlag"]}>
                  <Radio.Group>
                    <Radio value={true}>True</Radio>
                    <Radio value={false}>False</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col flex="300px">
                <Form.Item
                  label={t("form.TisFailCount")}
                  name={["acquirer", "Index6", "TisFailCount"]}
                  normalize={(value) => Number(value)}
                >
                  <Input disabled />
                </Form.Item>
              </Col>
            </Row>
          </Tabs.TabPane>
        </Tabs>
      </Form>
      <Divider />
      <div style={{ width: "100%" }}>
        <Button
          type="primary"
          shape="round"
          style={{ width: "200px", float: "right", marginRight: "20px" }}
          htmlType="submit"
          loading={btnLoading}
          onClick={() => form.submit()}
        >
          {t("button.save")}
        </Button>
      </div>
    </Row>
  );
};
