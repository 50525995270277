import {Alert, Button, Col, Divider, Form, Input, notification, Row} from "antd";
import {MinusCircleOutlined, PlusCircleOutlined} from "@ant-design/icons"
import React, {FC, useState} from "react";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {IAppConfigItem, IApplicationInfo, storeApp} from "../../../../services/cardpool/appConfig";

interface IProps {
  terminalId: string
}

export const CreateApp: FC<IProps> = ({terminalId}) => {
  const {t} = useTranslation()
  const navigator = useNavigate()
  const [form] = Form.useForm()
  const initialItems: Array<IAppConfigItem> = [
    {attribute: "hex", name: "Version", tag: "9F09", text: "0200"},
    {attribute: "hex", name: "Terminal Capabilities", tag: "9F33", text: "E020C8"},
    {attribute: "hex", name: "Terminal Type", tag: "9F35", text: "22"},
    {attribute: "hex", name: "Additional Terminal Capabilities", tag: "9F40", text: "6000F0E001"},
    {attribute: "hex", name: "Terminal Country Code", tag: "9F1A", text: "0158"},
    {attribute: "hex", name: "Transaction Currency Code", tag: "5F2A", text: "0901"},
    {attribute: "hex", name: "TAC Default", tag: "DFC8", text: "F86024A000"},
    {attribute: "hex", name: "TAC Online", tag: "DFC7", text: "F860ACF800"},
    {attribute: "hex", name: "TAC Denial", tag: "DFC6", text: "0010000000"},
    {attribute: "hex", name: "Default Tdol", tag: "DFC0", text: "9F02065F2A029A039C0195059F37049F3501"},
    {attribute: "hex", name: "Default Ddol", tag: "DFC1", text: "9F3704"},
    {attribute: "hex", name: "Terminal Floor Limit", tag: "9F1B", text: "00000000"},
    {attribute: "hex", name: "Threshold Value", tag: "DFC4", text: "00000005"},
    {attribute: "hex", name: "Target Percent", tag: "DFC2", text: "00"},
    {attribute: "hex", name: "Max Target Percent", tag: "DFC3", text: "00"},
    {attribute: "hex", name: "Merchant Category Code (MCC)", tag: "9F15", text: "7011"},
    {attribute: "hex", name: "Transaction Category Code (TCC)", tag: "9F53", text: "52"},
    {attribute: "asc", name: "InterfaceDeviceSerialNumber", tag: "9F1E", text: "12345678"},
  ]
  const initial: IApplicationInfo = {aid: "", asi: "", items: initialItems, name: ""}
  const [formValues, setFormValues] = useState<IApplicationInfo>(initial);
  const [btnLoading, setBtnLoading] = useState<boolean>(false)
  const saveForm = (values) => {
    setBtnLoading(true)
    storeApp({...values, terminalId: terminalId}).then(res => {
      if (res.status === 0) {
        notification.success({
          message: "success"
        })
        setTimeout(function () {
          navigator('/terminal-prm/emvconfig')
        }, 200)
      }
    }).finally(() => {
      setBtnLoading(false)
    })
  }
  const addConfig = () => {
    const fieldsValue = form.getFieldsValue()
    let values: IApplicationInfo = {
      aid: fieldsValue.aid,
      asi: fieldsValue.asi,
      items: Array.from(fieldsValue.items),
      name: fieldsValue.name
    }
    const item: IAppConfigItem = {attribute: "hex", name: "", tag: "", text: ""}
    values.items.push(item)
    setFormValues(values)
    form.setFieldsValue(values)
  }

  const removeConfig = (index: number) => {
    const fieldsValue = form.getFieldsValue()
    let values: IApplicationInfo = {
      aid: fieldsValue.aid,
      asi: fieldsValue.asi,
      items: Array.from(fieldsValue.items),
      name: fieldsValue.name
    }
    values.items.splice(index, 1)
    setFormValues(values)
    form.setFieldsValue(values)
  }

  const customAlert = (text: string) => {
    return (
      <>
        <Alert
          style={{marginBottom: "20px"}}
          message={<div style={{height: "40px", lineHeight: "40px", fontSize: "18px", fontWeight: "bold"}}>{text}</div>}
          type="info"/>
      </>
    )
  }
  return (
    <Row gutter={10} style={{marginTop: "20px"}}>
      <Form layout='vertical' style={{width: "100%"}} form={form} initialValues={formValues} onFinish={saveForm}>
        {customAlert("Application")}
        <Row gutter={20}>
          <Col flex="300px">
            <Form.Item label="Name" name="name">
              <Input/>
            </Form.Item>
          </Col>
          <Col flex="300px">
            <Form.Item label="AID" name="aid">
              <Input/>
            </Form.Item>
          </Col>
          <Col flex="300px">
            <Form.Item label="ASI" name="asi">
              <Input/>
            </Form.Item>
          </Col>
        </Row>
        {customAlert("Application Config")}
        {formValues.items.map((item, index) => {
          return (
            <Row key={index} gutter={20}>
              <Col flex="200px">
                <Form.Item label="Name" name={['items', index, 'name']}>
                  <Input/>
                </Form.Item>
              </Col>
              <Col flex="100px">
                <Form.Item label="Tag" name={['items', index, 'tag']}>
                  <Input/>
                </Form.Item>
              </Col>
              <Col flex="100px">
                <Form.Item label="Attribute" name={['items', index, 'attribute']}>
                  <Input/>
                </Form.Item>
              </Col>
              <Col flex="200px">
                <Form.Item label="Value" name={['items', index, 'text']}>
                  <Input/>
                </Form.Item>
              </Col>
              <Col flex="20px">
                <Form.Item label=" ">
                  <Button icon={<MinusCircleOutlined/>} type="primary" onClick={() => removeConfig(index)}/>
                </Form.Item>
              </Col>
            </Row>
          )
        })}
        <Button icon={<PlusCircleOutlined/>} type="primary" onClick={addConfig}/>
      </Form>
      <Divider/>
      <div style={{width: "100%"}}>
        <Button type="primary" shape="round" style={{minWidth: "200px", float: "right"}}
                htmlType="submit" loading={btnLoading} onClick={() => form.submit()}>
          {t('button.save')}
        </Button>
      </div>
    </Row>
  )
}