import {get, post} from "../../util/request";
import {TerminalPrmInfoRequest} from "../terminalPrm";

export interface ITerminalPRMRInfoGPCustomization {
  terminalId: string;
  GPCustomization: AP.IGPCustomization;
}

export const terminalPrmInfoGPCustomization = (params: TerminalPrmInfoRequest) => {
  return get<ITerminalPRMRInfoGPCustomization>({url: "/api/terminal-prm/info/ap/gpcustomization", params});
};

export const saveTerminalPrmInfoGPCustomization = (data: ITerminalPRMRInfoGPCustomization) => {
  return post({url: "/api/terminal-prm/info/ap/gpcustomization", data});
};