import * as React from "react";
import {useTranslation} from "react-i18next";
import {Button, Col, Form, Modal, notification, Radio, RadioChangeEvent, Row, Select, Spin} from "antd";
import {FC, useEffect, useState} from "react";
import {merchantSimpleList} from "../../services/merchantPrm";
import {
    cloneTerminalPrm,
    ICloneTerminalPRMRequest,
    resetTerminalPrm,
    terminalPrmInfoBasic,
    terminalSimpleList
} from "../../services/terminalPrm";
import {Route, Routes, useLocation, useNavigate} from "react-router-dom";
import {TerminalPrmBasic} from "./basic";
import "./style.css";
import {TerminalPrmAPAppcfg} from "./ap/apAppcfg";
import {TerminalPrmAPData} from "./ap/apData";
import {TerminalPrmCPAcquirer} from "./cardpool/acquirer";
import {TerminalPrmCPTerminal} from "./cardpool/terminalDef";
import {TerminalPrmCPData} from "./cardpool/cpData";
import {TerminalPrmCyberSource} from "./cybersource/cybersource";
import {TerminalPrmCSData} from "./cybersource/csData";
import {TerminalPrmEmvcl} from "./cardpool/emvcl";
import {TerminalPrmEmvConfig} from "./cardpool/emv";
import {TerminalPrmCPGPAPybIndex} from "./cardpool/gpapyb/gpapyb";
import {TerminalPrmCPGPAPfqIndex} from "./cardpool/gpapfq/gpapfq";
import {TerminalPrmCPGPAPylIndex} from "./cardpool/gpapyl/gpapyl";
import {TerminalPrmCPGPAPdccIndex} from "./cardpool/gpapdcc/gpapdcc";
import {TerminalPrmCPSmartyPayIndex} from "./cardpool/smartyPay/smartyPay";
import {Capk} from "./cardpool/emvcl/capk";
import {TerminalPrmETEcc} from "./eticket/ecc";
import {TerminalPrmETEticket} from "./eticket/eticket";
import {TerminalPrmETIcerini} from "./eticket/icerini";
import {TerminalPrmETIPass} from "./eticket/ipass";
import {TerminalPrmQRCode} from "./qrcode/qrcode";
import {TerminalPrmQRCodeData} from "./qrcode/qrcodeData";
import {TerminalPrmETicketData} from "./eticket/eticketData";
import {TerminalPrmMohist} from "./mohist/mohist";
import {TerminalPrmMohistData} from "./mohist/mohistData";
import {getTenantId} from "../../util/cookie";
import {TerminalPrmAPGPCustomization} from "./ap/gpCustomization";
import {exportParameter, exportParameterResult} from "../../services/parameter";
import {TerminalPrmOnus} from "./onus/onus";
import {TerminalPrmOnusData} from "./onus/onusData";

let timeout: ReturnType<typeof setTimeout> | null;

interface IMerchantOption {
    label: string
    value: string
}

export const TerminalPrm: FC = () => {
    const {t} = useTranslation();
    const [modalForm] = Form.useForm();
    const navigator = useNavigate();
    const location = useLocation();
    const merchantInit: Array<IMerchantOption> = new Array<IMerchantOption>()
    const [merchants, setMerchants] = useState<Array<IMerchantOption>>(merchantInit);
    const [terminalList, setTerminalList] = useState<Array<object>>([]);
    const [merchantId, setMerchantId] = useState("");
    const [terminalId, setTerminalId] = useState("");
    const [loading, setLoading] = useState(true);
    const [buttonKey, setButtonKey] = useState<String>("");
    const [btnResetLoading, setBtnResetLoading] = useState<boolean>(false);
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [hiddenSelectTerminal, setHiddenSelectTerminal] = useState<boolean>(true);
    const [searchType, setSearchType] = useState<string>("name");
    const modalFormInitValues = {"selectType": 1, "terminalIds": []}
    const [exportModal, setExportModal] = useState<boolean>(false)
    const [exportParameterKey, setExportParameterKey] = useState<string>("")
    const getMerchantSimpleList = (tenantId: string) => {
        setLoading(true);
        merchantSimpleList({tenantId: tenantId})
            .then((res) => {
                if (res.status === 0) {
                    let arr: Array<IMerchantOption> = new Array<IMerchantOption>();
                    res.data.map((item) => {
                        const obj = {
                            label: item.name,
                            value: item.merchant_id,
                        };
                        arr.push(obj);
                        return item;
                    });
                    setMerchants(arr);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };
    document.title = t("title.terminal_prm") + " - CardPool App Manager";
    useEffect(function () {
        getMerchantSimpleList(getTenantId());
        selectMerchant('')
    }, []);
    useEffect(
        function () {
            setButtonKey(location.pathname);
        },
        [location.pathname]
    );

    const selectMerchant = (value: string) => {
        terminalSimpleList({merchant_id: value}).then((res) => {
            if (res.status === 0) {
                let arr: Array<object> = [];
                res.data.map((item) => {
                    const obj = {
                        label: item.terminal_name + `(${item.hardware_sn})`,
                        value: item.terminal_id,
                    };
                    arr.push(obj);
                    return item;
                });
                setTerminalList(arr);
                setMerchantId(value);
            }
        });
    };
    const selectTerminal = (value: string) => {
        if (!value) {
            return
        }
        terminalPrmInfoBasic({terminal_id: value}).then((res) => {
            if (res.status === 0) {
                setTerminalId(value);
            }
        });
    };

    const clickModule = (v: string) => {
        setButtonKey(v);
        navigator(v);
    };

    const clickReset = () => {
        Modal.confirm({
            title: "重要警告",
            content: "您的操作將會使修改的數據完全删除，数据將還原為默認數據，請謹慎確認！",
            onOk: () => postReset(),
            okText: "Yes",
            maskClosable: false,
            closable: false,
        });
    };

    const postReset = () => {
        if (terminalId !== "") {
            setBtnResetLoading(true);
            resetTerminalPrm({terminalId: terminalId})
                .then((res) => {
                    if (res.status === 0) {
                        notification.success({
                            message: "reset success",
                        });
                        navigator("/terminal-prm");
                    }
                })
                .finally(() => {
                    setBtnResetLoading(false);
                });
        }
    };

    const changeSearchType = (e: RadioChangeEvent) => {
        setSearchType(e.target.value);
    };

    const searchTerminal = (search: string) => {
        // if (search.length < 3) {
        //   return;
        // }
        if (timeout) {
            clearTimeout(timeout);
            timeout = null;
        }
        let name = "";
        let sn = "";
        if (searchType === "name") {
            name = search;
        }
        if (searchType === "sn") {
            sn = search;
        }

        const fake = () => {
            terminalSimpleList({merchant_id: merchantId, name: name, sn: sn}).then((res) => {
                if (res.status === 0) {
                    let arr: Array<object> = [];
                    res.data.map((item) => {
                        const obj = {
                            label: item.terminal_name + `(${item.hardware_sn})`,
                            value: item.terminal_id,
                        };
                        arr.push(obj);
                        return item;
                    });
                    setTerminalList(arr);
                }
            });
        };

        timeout = setTimeout(fake, 500);
    };

    const clickCloneTo = () => {
        modalForm.setFieldsValue(modalFormInitValues)
        setHiddenSelectTerminal(true)
        setOpenModal(true)
    }

    const clickExportParameter = () => {
        exportParameter({}).then((res) => {
            if (res.status === 0) {
                setExportParameterKey(res.data.key)
                setExportModal(true)
            } else {
                notification.error({message: res.msg})
            }
        })
    }

    const getExportParameterResult = () => {
        exportParameterResult({key: exportParameterKey}).then((res) => {
            if (res.status === 0) {
                if (res.data.url !== "") {
                    const a = document.createElement('a');
                    a.target = "_blank"

                    a.href = res.data.url;
                    a.click();
                }
            }
        })
    }

    const onModalOk = () => {
        const values = modalForm.getFieldsValue()
        let postData: ICloneTerminalPRMRequest;
        postData = {
            terminalId: terminalId,
            merchantId: "",
            terminalIds: values["terminalIds"]
        };
        if (values["selectType"] === 1) {
            postData.merchantId = merchantId
        }
        cloneTerminalPrm(postData).then(res => {
            if (res.status === 0) {
                notification.success({message: "success"})
            }
        })
    }
    const changeSelectType = (e: any) => {
        setHiddenSelectTerminal(e.target.value === 1)
    }

    const filterMerchant = (inputValue: string, option?: { label: string; value: string }): boolean => {
        return (option?.label ?? '').toLowerCase().includes(inputValue.toLowerCase());
    }

    const clearTerminalSelect = () => {
        setTerminalId('')
        navigator('/terminal-prm')
        terminalSimpleList({merchant_id: merchantId, name: '', sn: ''}).then((res) => {
            if (res.status === 0) {
                let arr: Array<object> = [];
                res.data.map((item) => {
                    const obj = {
                        label: item.terminal_name + `(${item.hardware_sn})`,
                        value: item.terminal_id,
                    };
                    arr.push(obj);
                    return item;
                });
                setTerminalList(arr);
            }
        });
    }

    const clearMerchantSelect = () => {
        setMerchantId('')
    }

    return (
        <div style={{height: "100%"}}>
            <Row gutter={10}>
                <div className="form-content-container-content">
                    <Form layout="inline" style={{width: "100%"}}>
                        <Form.Item label={t("form.merchant")}>
                            <Select
                                style={{minWidth: "280px"}}
                                showSearch
                                allowClear={true}
                                placeholder={t("placeholder.select_merchant")}
                                options={merchants}
                                onChange={selectMerchant}
                                loading={loading}
                                filterOption={filterMerchant}
                                onClear={clearMerchantSelect}
                            />
                        </Form.Item>
                        <Form.Item label={t("form.terminal")}>
                            <Select
                                style={{minWidth: "280px"}}
                                showSearch
                                allowClear={true}
                                placeholder={t("placeholder.select_terminal")}
                                filterOption={false}
                                options={terminalList}
                                onChange={selectTerminal}
                                onSearch={searchTerminal}
                                onClear={clearTerminalSelect}
                            />
                        </Form.Item>
                        <Form.Item>
                            <Radio.Group defaultValue="name" buttonStyle="solid" onChange={changeSearchType}
                                         value={searchType}>
                                <Radio.Button value="name">Search Name</Radio.Button>
                                <Radio.Button value="sn">Search SN</Radio.Button>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item>
                            <Button
                                type="default"
                                shape="round"
                                onClick={clickExportParameter}
                            >
                                Export Parameter
                            </Button>
                        </Form.Item>
                        <Form.Item style={{flex: 1}} hidden={terminalId === ""}>
                            <div style={{float: "right"}}>
                                <Button
                                    type="primary"
                                    shape="round"
                                    onClick={clickCloneTo}
                                    style={{width: "200px", marginRight: "10px"}}
                                >
                                    Clone to
                                </Button>
                                <Button
                                    type="primary"
                                    shape="round"
                                    onClick={clickReset}
                                    loading={btnResetLoading}
                                    style={{width: "200px"}}
                                >
                                    Reset
                                </Button>
                            </div>
                        </Form.Item>
                    </Form>
                    <Form
                        layout="inline"
                        hidden={terminalId === ""}
                        style={{marginTop: "20px"}}
                        labelAlign="right"
                        labelCol={{flex: "130px"}}
                    >
                        <Form.Item label="Basic Information" style={{width: "100%"}}>
                            <Button
                                type={buttonKey.indexOf("/basic") !== -1 ? "primary" : "default"}
                                onClick={() => clickModule("basic")}
                            >
                                Basic
                            </Button>
                        </Form.Item>
                    </Form>
                    <Form
                        layout="inline"
                        hidden={terminalId === ""}
                        style={{marginTop: "20px"}}
                        labelAlign="right"
                        labelCol={{flex: "130px"}}
                    >
                        <Form.Item label="AP" style={{width: "100%"}}>
                            <Row gutter={20}>
                                <Col>
                                    <Button
                                        type={buttonKey.indexOf("/appcfg") !== -1 ? "primary" : "default"}
                                        onClick={() => clickModule("appcfg")}
                                    >
                                        appcfg.json
                                    </Button>
                                </Col>
                                <Col>
                                    <Button
                                        type={buttonKey.indexOf("/gpcustomization") !== -1 ? "primary" : "default"}
                                        onClick={() => clickModule("gpcustomization")}
                                    >
                                        gpCustomization.json
                                    </Button>
                                </Col>
                                <Col>
                                    <Button
                                        type={buttonKey.indexOf("/apdata") !== -1 ? "primary" : "default"}
                                        onClick={() => clickModule("apdata")}
                                    >
                                        DATA.zip
                                    </Button>
                                </Col>
                            </Row>
                        </Form.Item>
                    </Form>
                    <Form
                        layout="inline"
                        hidden={terminalId === ""}
                        style={{marginTop: "20px"}}
                        labelAlign="right"
                        labelCol={{flex: "130px"}}
                    >
                        <Form.Item label="Card Pool" style={{width: "100%"}}>
                            <Row gutter={[20, 10]} style={{width: "100%"}}>
                                <Col>
                                    <Button
                                        type={buttonKey.indexOf("/acquirer") !== -1 ? "primary" : "default"}
                                        onClick={() => clickModule("acquirer/acquirerdef")}
                                    >
                                        {t("title.acquirer_def")}
                                    </Button>
                                </Col>
                                <Col>
                                    <Button
                                        type={buttonKey.indexOf("/terminaldef") !== -1 ? "primary" : "default"}
                                        onClick={() => clickModule("terminaldef")}
                                    >
                                        {t("title.terminal_def")}
                                    </Button>
                                </Col>
                                <Col>
                                    <Button
                                        type={buttonKey.indexOf("/emvconfig") !== -1 ? "primary" : "default"}
                                        onClick={() => clickModule("emvconfig")}
                                    >
                                        {t("title.emv_config")}
                                    </Button>
                                </Col>
                                <Col>
                                    <Button
                                        type={buttonKey.indexOf("/emvclconfig") !== -1 ? "primary" : "default"}
                                        onClick={() => clickModule("emvclconfig")}
                                    >
                                        {t("title.emvcl_config")}
                                    </Button>
                                </Col>
                                <Col>
                                    <Button
                                        type={buttonKey.indexOf("/capk") !== -1 ? "primary" : "default"}
                                        onClick={() => clickModule("capk")}
                                    >
                                        CAPK
                                    </Button>
                                </Col>
                                <Col>
                                    <Button
                                        type={buttonKey.indexOf("/cpdata") !== -1 ? "primary" : "default"}
                                        onClick={() => clickModule("cpdata")}
                                    >
                                        DATA.zip
                                    </Button>
                                </Col>
                            </Row>
                        </Form.Item>
                    </Form>
                    <Form layout="inline" hidden={terminalId === ""} style={{marginTop: "20px"}} labelAlign="right">
                        <Form.Item label="Cyber Source" labelCol={{flex: "130px"}} style={{width: "100%"}}>
                            <Row gutter={20}>
                                <Col>
                                    <Button
                                        type={buttonKey.indexOf("/cybersourcecfg") !== -1 ? "primary" : "default"}
                                        onClick={() => clickModule("cybersourcecfg")}
                                    >
                                        cybersourcecfg.json
                                    </Button>
                                </Col>
                                <Col>
                                    <Button
                                        type={buttonKey.indexOf("/csdata") !== -1 ? "primary" : "default"}
                                        onClick={() => clickModule("csdata")}
                                    >
                                        DATA.zip
                                    </Button>
                                </Col>
                            </Row>
                        </Form.Item>
                    </Form>
                    <Form layout="inline" hidden={terminalId === ""} style={{marginTop: "20px"}} labelAlign="right">
                        <Form.Item label="ETicket" labelCol={{flex: "130px"}} style={{width: "100%"}}>
                            <Row gutter={20}>
                                <Col>
                                    <Button type={buttonKey.endsWith("/ecc") ? "primary" : "default"}
                                            onClick={() => clickModule("ecc")}>
                                        ecccfg.json
                                    </Button>
                                </Col>
                                <Col>
                                    <Button
                                        type={buttonKey.endsWith("/eticket") ? "primary" : "default"}
                                        onClick={() => clickModule("eticket")}
                                    >
                                        eticketcfg.json
                                    </Button>
                                </Col>
                                <Col>
                                    <Button
                                        type={buttonKey.endsWith("/icerini") ? "primary" : "default"}
                                        onClick={() => clickModule("icerini")}
                                    >
                                        ICERINI.xml
                                    </Button>
                                </Col>
                                <Col>
                                    <Button
                                        type={buttonKey.endsWith("/ipass") ? "primary" : "default"}
                                        onClick={() => clickModule("ipass")}
                                    >
                                        ipasscfg.json
                                    </Button>
                                </Col>
                                <Col>
                                    <Button
                                        type={buttonKey.indexOf("/etdata") !== -1 ? "primary" : "default"}
                                        onClick={() => clickModule("etdata")}
                                    >
                                        DATA.zip
                                    </Button>
                                </Col>
                            </Row>
                        </Form.Item>
                    </Form>
                    <Form layout="inline" hidden={terminalId === ""} style={{marginTop: "20px"}} labelAlign="right">
                        <Form.Item label="QRCodeGateway" labelCol={{flex: "130px"}} style={{width: "100%"}}>
                            <Row gutter={20}>
                                <Col>
                                    <Button
                                        type={buttonKey.endsWith("/qrcodecfg") ? "primary" : "default"}
                                        onClick={() => clickModule("qrcodecfg")}
                                    >
                                        QRCodeCfg.json
                                    </Button>
                                </Col>
                                <Col>
                                    <Button
                                        type={buttonKey.indexOf("/qrcodedata") !== -1 ? "primary" : "default"}
                                        onClick={() => clickModule("qrcodedata")}
                                    >
                                        DATA.zip
                                    </Button>
                                </Col>
                            </Row>
                        </Form.Item>
                    </Form>
                    <Form layout="inline" hidden={terminalId === ""} style={{marginTop: "20px"}} labelAlign="right">
                        <Form.Item label="Mohist" labelCol={{flex: "130px"}} style={{width: "100%"}}>
                            <Row gutter={20}>
                                <Col>
                                    <Button
                                        type={buttonKey.endsWith("/mohistcfg") ? "primary" : "default"}
                                        onClick={() => clickModule("mohistcfg")}
                                    >
                                        MohistCfg.json
                                    </Button>
                                </Col>
                                <Col>
                                    <Button
                                        type={buttonKey.indexOf("/mohistdata") !== -1 ? "primary" : "default"}
                                        onClick={() => clickModule("mohistdata")}
                                    >
                                        DATA.zip
                                    </Button>
                                </Col>
                            </Row>
                        </Form.Item>
                    </Form>
                    <Form layout="inline" hidden={terminalId === ""} style={{marginTop: "20px"}} labelAlign="right">
                        <Form.Item label="Onus" labelCol={{flex: "130px"}} style={{width: "100%"}}>
                            <Row gutter={20}>
                                <Col>
                                    <Button
                                        type={buttonKey.endsWith("/onuscfg") ? "primary" : "default"}
                                        onClick={() => clickModule("onuscfg")}
                                    >
                                        OnusCfg.json
                                    </Button>
                                </Col>
                                <Col>
                                    <Button
                                        type={buttonKey.indexOf("/onusdata") !== -1 ? "primary" : "default"}
                                        onClick={() => clickModule("onusdata")}
                                    >
                                        DATA.zip
                                    </Button>
                                </Col>
                            </Row>
                        </Form.Item>
                    </Form>
                </div>
            </Row>
            <Routes>
                <Route path="basic" element={<TerminalPrmBasic terminalId={terminalId} />} />
                <Route path="appcfg" element={<TerminalPrmAPAppcfg terminalId={terminalId} />} />
                <Route path="gpcustomization" element={<TerminalPrmAPGPCustomization terminalId={terminalId} />} />
                <Route path="apdata" element={<TerminalPrmAPData terminalId={terminalId} />} />
                <Route path="acquirer/*" element={<TerminalPrmCPAcquirer terminalId={terminalId} />} />
                <Route path="terminaldef" element={<TerminalPrmCPTerminal terminalId={terminalId} />} />
                <Route path="emvconfig/*" element={<TerminalPrmEmvConfig terminalId={terminalId} />} />
                <Route path="emvclconfig/*" element={<TerminalPrmEmvcl terminalId={terminalId} />} />
                <Route path="capk" element={<Capk terminalId={terminalId} />} />
                <Route path="gpapyb/*" element={<TerminalPrmCPGPAPybIndex terminalId={terminalId} />} />
                <Route path="gpapfq/*" element={<TerminalPrmCPGPAPfqIndex terminalId={terminalId} />} />
                <Route path="gpapyl/*" element={<TerminalPrmCPGPAPylIndex terminalId={terminalId} />} />
                <Route path="gpapdcc/*" element={<TerminalPrmCPGPAPdccIndex terminalId={terminalId} />} />
                <Route path="smartypay/*" element={<TerminalPrmCPSmartyPayIndex terminalId={terminalId} />} />
                <Route path="cpdata" element={<TerminalPrmCPData terminalId={terminalId} />} />
                <Route path="cybersourcecfg" element={<TerminalPrmCyberSource terminalId={terminalId} />} />
                <Route path="csdata" element={<TerminalPrmCSData terminalId={terminalId} />} />
                <Route path="ecc" element={<TerminalPrmETEcc terminalId={terminalId} />} />
                <Route path="eticket" element={<TerminalPrmETEticket terminalId={terminalId} />} />
                <Route path="icerini" element={<TerminalPrmETIcerini terminalId={terminalId} />} />
                <Route path="ipass" element={<TerminalPrmETIPass terminalId={terminalId} />} />
                <Route path="etdata" element={<TerminalPrmETicketData terminalId={terminalId} />} />
                <Route path="qrcodecfg" element={<TerminalPrmQRCode terminalId={terminalId} />} />
                <Route path="qrcodedata" element={<TerminalPrmQRCodeData terminalId={terminalId} />} />
                <Route path="mohistcfg" element={<TerminalPrmMohist terminalId={terminalId} />} />
                <Route path="mohistdata" element={<TerminalPrmMohistData terminalId={terminalId} />} />
                <Route path="onuscfg" element={<TerminalPrmOnus terminalId={terminalId} />} />
                <Route path="onusdata" element={<TerminalPrmOnusData terminalId={terminalId} />} />
            </Routes>
            <Modal title="Clone to" visible={openModal} width={"60%"} maskClosable={false}
                   onCancel={() => setOpenModal(false)} onOk={onModalOk}>
                <Form style={{maxWidth: "720px"}} form={modalForm} initialValues={modalFormInitValues}>
                    <Form.Item label={t("form.select_type")} name={"selectType"}>
                        <Radio.Group onChange={changeSelectType}>
                            <Radio value={1}>All terminals</Radio>
                            <Radio value={2}>Selected terminals</Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item label={t("form.terminal")} name={"terminalIds"}
                               hidden={hiddenSelectTerminal}>
                        <Select
                            mode={"multiple"}
                            style={{minWidth: "280px"}}
                            showSearch
                            placeholder={t("placeholder.select_terminal")}
                            filterOption={false}
                            options={terminalList}
                            onSearch={searchTerminal}
                            maxTagCount="responsive"
                        />
                    </Form.Item>
                </Form>
            </Modal>
            <Modal title="Export parameter" visible={exportModal} width={"400px"} maskClosable={false} closable={false}
                   cancelButtonProps={{hidden: true}}
                   onOk={() => setExportModal(false)}>
                <div style={{textAlign: "center"}}><Spin /></div>
                <div
                    style={{marginBottom: '8px', marginTop: '8px'}}>正在导出请点击按钮刷新查看结果，在获得结果前请不要关闭弹框或者离开此页面
                </div>
                <div><Button onClick={getExportParameterResult}>刷新结果 </Button></div>
            </Modal>
        </div>
    );
};
