import {get, post} from "../../util/request";
import {IMerchantPrmId} from "./index";

export interface IMerchantPRMRInfoAPData {
  id: string;
  merchantId: string;
  data: string;
}

export interface IMerchantPRMRInfoAPDataResponse {
  url: string;
}

export const merchantPrmInfoAPData = (params: IMerchantPrmId) => {
  return get<IMerchantPRMRInfoAPData>({url: "/api/merchant-prm/info/ap/data", params})
}

export const saveMerchantPrmInfoAPData = (data: IMerchantPRMRInfoAPData) => {
  return post<IMerchantPRMRInfoAPDataResponse>({url: "/api/merchant-prm/info/ap/data", data})
}