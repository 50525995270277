import {del, get} from "../../util/request";

export interface ILastLoadDateTime {
  date: string;
}

export const lastLoadDateTime = () => {
  return get<ILastLoadDateTime>({ url: "/api/upload/default/datetime" });
};

export const deleteDefaultFile = () => {
  return del({ url: "/api/upload/default" });
};