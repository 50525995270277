import {del, get, post} from "../util/request";

interface TerminalSimpleListRequest {
  merchant_id: string;
  name?: string;
  sn?: string;
}

interface TerminalSimpleListResponse {
  terminal_id: string;
  terminal_name: string;
  hardware_sn: string;
}

export interface TerminalPrmInfoRequest {
  terminal_id: string;
}

export interface TerminalPrmInfoAidRequest {
  terminal_id: string;
  aid: string;
}

export interface ITerminalPRMRInfoBasic {
  terminalId: string;
  name: string;
  packageName: string;
  downloadTime: string;
  forceDownload: number;
}

export interface ITerminalPRMRInfoAPAppcfg {
  id: string;
  terminalId: string;
  ap: AP.IAP;
}

export interface ITerminalPRMRInfoAPData {
  id: string;
  terminalId: string;
  data: string;
}

export interface ITerminalPRMRInfoAPDataResponse {
  url: string;
}

export interface ITerminalPRMRInfoCPAcquirer {
  id: string;
  terminalId: string;
  acquirer: Acquirer.IAcquirer;
}

export interface ITerminalPRMRInfoCPTerminal {
  id: string;
  terminalId: string;
  terminalDef: TerminalDef.ITerminalDef;
}

export interface ITerminalPRMRInfoTerminalConfig {
  id: string;
  terminalId: string;
  terminalConfig: Array<Emv.ITerminalConfigItem>;
}

export interface ITerminalPRMRInfoCyberSource {
  id: string;
  terminalId: string;
  cybersource: CyberSource.ICyberSource;
}

export interface ICapkDefaultListRequest {
  groupId: string;
}

export interface ICapkDefaultListResponse {
  groupId: string;
  itemIndex: string;
  isTest: string;
  modules: string;
  exponent: string;
  hash: string;
}

export interface IStoreCapkDefaultRequest {
  groupId: string;
  itemIndex: string;
  isTest: boolean;
  modules: string;
  exponent: string;
  hash: string;
}

export interface IDeleteCapkDefaultRequest {
  groupId: string;
  itemIndex: string;
}

export interface ITerminalIdRequest {
  terminalId: string;
}

export const terminalSimpleList = (params: TerminalSimpleListRequest) => {
  return get<[TerminalSimpleListResponse]>({url: "/api/terminals/simple-list", params});
};

export const terminalPrmInfoBasic = (params: TerminalPrmInfoRequest) => {
  return get<ITerminalPRMRInfoBasic>({url: "/api/terminal-prm/info/basic", params});
};

export const saveTerminalPrmInfoBasic = (data: ITerminalPRMRInfoBasic) => {
  return post<ITerminalPRMRInfoBasic>({url: "/api/terminal-prm/info/basic", data});
};

export const terminalPrmInfoAPAppcfg = (params: TerminalPrmInfoRequest) => {
  return get<ITerminalPRMRInfoAPAppcfg>({url: "/api/terminal-prm/info/ap/appcfg", params});
};

export const saveTerminalPrmInfoAPAppcfg = (data: ITerminalPRMRInfoAPAppcfg) => {
  return post({url: "/api/terminal-prm/info/ap/appcfg", data});
};

export const terminalPrmInfoAPData = (params: TerminalPrmInfoRequest) => {
  return get<ITerminalPRMRInfoAPData>({url: "/api/terminal-prm/info/ap/data", params});
};

export const saveTerminalPrmInfoAPData = (data: ITerminalPRMRInfoAPData) => {
  return post<ITerminalPRMRInfoAPDataResponse>({url: "/api/terminal-prm/info/ap/data", data});
};

export const terminalPrmInfoCPAcquirer = (params: TerminalPrmInfoRequest) => {
  return get<ITerminalPRMRInfoCPAcquirer>({url: "/api/terminal-prm/info/cp/acquirer", params});
};

export const saveTerminalPrmInfoCPAcquirer = (data: ITerminalPRMRInfoCPAcquirer) => {
  return post({url: "/api/terminal-prm/info/cp/acquirer", data});
};

export const terminalPrmInfoCPTerminal = (params: TerminalPrmInfoRequest) => {
  return get<ITerminalPRMRInfoCPTerminal>({url: "/api/terminal-prm/info/cp/terminal", params});
};

export const saveTerminalPrmInfoCPTerminal = (data: ITerminalPRMRInfoCPTerminal) => {
  return post({url: "/api/terminal-prm/info/cp/terminal", data});
};

export const terminalPrmInfoTerminalConfig = (params: TerminalPrmInfoRequest) => {
  return get<ITerminalPRMRInfoTerminalConfig>({url: "/api/terminal-prm/info/cp/emv-terminal-config", params});
};

export const saveTerminalPrmInfoTerminalConfig = (data: ITerminalPRMRInfoTerminalConfig) => {
  return post({url: "/api/terminal-prm/info/cp/emv-terminal-config", data});
};

export const terminalPrmCapkDefaultList = (params: ICapkDefaultListRequest) => {
  return get<[ICapkDefaultListResponse]>({url: "/api/terminal-prm/info/cp/capk-default", params});
};

export const saveTerminalPrmCapkDefault = (data: IStoreCapkDefaultRequest) => {
  return post({url: "/api/terminal-prm/info/cp/capk-default", data});
};

export const resetTerminalPrmCapkDefault = () => {
  return post({url: "/api/terminal-prm/info/cp/capk-default/reset"});
};

export const deleteTerminalPrmCapkDefault = (params: IDeleteCapkDefaultRequest) => {
  return del({url: "/api/terminal-prm/info/cp/capk-default", params});
};

export const terminalPrmInfoCPData = (params: TerminalPrmInfoRequest) => {
  return get<ITerminalPRMRInfoAPData>({url: "/api/terminal-prm/info/cp/data", params});
};

export const saveTerminalPrmInfoCPData = (data: ITerminalPRMRInfoAPData) => {
  return post<ITerminalPRMRInfoAPDataResponse>({url: "/api/terminal-prm/info/cp/data", data});
};

export const terminalPrmInfoCyberSource = (params: TerminalPrmInfoRequest) => {
  return get<ITerminalPRMRInfoCyberSource>({url: "/api/terminal-prm/info/cs/cybersource", params});
};

export const saveTerminalPrmInfoCyberSource = (data: ITerminalPRMRInfoCyberSource) => {
  return post({url: "/api/terminal-prm/info/cs/cybersource", data});
};

export const terminalPrmInfoCSData = (params: TerminalPrmInfoRequest) => {
  return get<ITerminalPRMRInfoAPData>({url: "/api/terminal-prm/info/cs/data", params});
};

export const saveTerminalPrmInfoCSData = (data: ITerminalPRMRInfoAPData) => {
  return post<ITerminalPRMRInfoAPDataResponse>({url: "/api/terminal-prm/info/cs/data", data});
};

export const resetTerminalPrm = (data: ITerminalIdRequest) => {
  return post({url: "/api/terminal-prm/reset", data});
};

export interface ICloneTerminalPRMRequest {
  terminalId: string
  merchantId: string
  terminalIds: string[]
}

export const cloneTerminalPrm = (data: ICloneTerminalPRMRequest) => {
  return post({url: "/api/terminal-prm/clone", data});
};